import React from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import DonutChart from "./DonutChart";
import Sidebar from "../Sidebar";
import Header from "../Header";
// import PatientChart from "./PaitentChart";
import { useNavigate } from "react-router-dom";

// import Select from "react-select";
import {
  payment,
  receipt,
  purchase,
  sale,
  contra,
  journal,
  doctorpayment,
  supplier,
  bank,
  trail,
  profitloss,
  balance,
} from "../imagepath";
import { Link } from "react-router-dom";

const accounts_Dashboard = () => {
  const navigate = useNavigate();
  // const [selectedOption, setSelectedOption] = useState(null);

  // eslint-disable-next-line no-unused-vars
  // const [year, setyear] = useState([
  //   { value: 1, label: "2022" },
  //   { value: 2, label: "2021" },
  //   { value: 3, label: "2020" },
  //   { value: 4, label: "2019" },
  // ]);

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item"
        id1="menu-items"
        activeClassName="admin-dashboard"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Admin Dashboard</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* voucher column */}
            <div className="row">
              <div className="col-md-4">
                <div className="container">
                  <div className="row">
                    {/* Column 1 */}
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column gap-2">
                      <div
                        className="dash-widget d-flex flex-column justify-content-center align-items-center"
                        onClick={() => navigate("/paymentvoucher")}
                      >
                        <div className="dash-boxs comman-flex-center">
                          <img src={payment} alt="#" className="icon-size" />
                        </div>
                        <div className="dash-content dash-count">
                          <h4>Payment Voucher</h4>
                        </div>
                      </div>

                      <div
                        className="dash-widget d-flex flex-column justify-content-center align-items-center"
                        onClick={() => navigate("/receiptvoucher")}
                      >
                        <div className="dash-boxs comman-flex-center">
                          <img src={receipt} alt="#" />
                        </div>
                        <div className="dash-content dash-count">
                          <h4>Receipt Voucher</h4>
                        </div>
                      </div>

                      <div
                        className="dash-widget d-flex flex-column justify-content-center align-items-center"
                        onClick={() => navigate("")}
                      >
                        <div className="dash-boxs comman-flex-center">
                          <img src={purchase} alt="#" />
                        </div>
                        <div className="dash-content dash-count">
                          <h4>Purchase Voucher</h4>
                        </div>
                      </div>

                      {/* Additional elements for Column 1 */}
                      <div
                        className="dash-widget d-flex flex-column justify-content-center align-items-center"
                        onClick={() => navigate("/salevoucher")}
                      >
                        <div className="dash-boxs comman-flex-center">
                          <img src={sale} alt="#" />
                        </div>
                        <div className="dash-content dash-count">
                          <h4>Sale Voucher</h4>
                        </div>
                      </div>

                      <div
                        className="dash-widget d-flex flex-column justify-content-center align-items-center"
                        onClick={() => navigate("/contravoucher")}
                      >
                        <div className="dash-boxs comman-flex-center">
                          <img src={contra} alt="#" />
                        </div>
                        <div className="dash-content dash-count">
                          <h4>Contra Voucher</h4>
                        </div>
                      </div>

                      <div
                        className="dash-widget d-flex flex-column justify-content-center align-items-center"
                        onClick={() => navigate("/journalvoucher")}
                      >
                        <div className="dash-boxs comman-flex-center">
                          <img src={journal} alt="#" />
                        </div>
                        <div className="dash-content dash-count">
                          <h4>Journal Voucher</h4>
                        </div>
                      </div>
                    </div>

                    {/* Column 2 */}
                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex flex-column gap-4">
                      {/* Repeat similar structure as Column 1 for 6 more elements */}
                      {/* Add other elements here */}
                    </div>

                    {/* Column 3 */}
                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex flex-column gap-4">
                      {/* Repeat similar structure as Column 1 for 6 more elements */}
                      {/* Add other elements here */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row">
                    {/* Column 1 */}
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column gap-2">
                      <div
                        className="dash-widget d-flex flex-column justify-content-center align-items-center"
                        onClick={() => navigate("/doctorpayment")}
                      >
                        <div className="dash-boxs comman-flex-center">
                          <img src={doctorpayment} alt="#" className="icon-size" />
                        </div>
                        <div className="dash-content dash-count">
                          <h4>Doctor Payment</h4>
                        </div>
                      </div>

                      <div
                        className="dash-widget d-flex flex-column justify-content-center align-items-center"
                        onClick={() => navigate("/supplierpayment")}
                      >
                        <div className="dash-boxs comman-flex-center">
                          <img src={supplier} alt="#" />
                        </div>
                        <div className="dash-content dash-count">
                          <h4>Supplier Payment</h4>
                        </div>
                      </div>

                      <div
                        className="dash-widget d-flex flex-column justify-content-center align-items-center"
                        onClick={() => navigate("/bankreconcile")}
                      >
                        <div className="dash-boxs comman-flex-center">
                          <img src={bank} alt="#" />
                        </div>
                        <div className="dash-content dash-count">
                          <h4>Bank Reconcile</h4>
                        </div>
                      </div>
                    </div>

                    {/* Column 2 */}
                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex flex-column gap-4">
                      {/* Repeat similar structure as Column 1 for 6 more elements */}
                      {/* Add other elements here */}
                    </div>

                    {/* Column 3 */}
                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex flex-column gap-4">
                      {/* Repeat similar structure as Column 1 for 6 more elements */}
                      {/* Add other elements here */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="container">
                  <div className="row">
                    {/* Column 1 */}
                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column gap-2">
                      <div
                        className="dash-widget d-flex flex-column justify-content-center align-items-center"
                        onClick={() => navigate("/trailbalance")}
                      >
                        <div className="dash-boxs comman-flex-center">
                          <img src={trail} alt="#" className="icon-size" />
                        </div>
                        <div className="dash-content dash-count">
                          <h4>Trail Balance</h4>
                        </div>
                      </div>

                      <div
                        className="dash-widget d-flex flex-column justify-content-center align-items-center"
                        onClick={() => navigate("/profitloss")}
                      >
                        <div className="dash-boxs comman-flex-center">
                          <img src={profitloss} alt="#" />
                        </div>
                        <div className="dash-content dash-count">
                          <h4>Profit & Loss AC</h4>
                        </div>
                      </div>

                      <div
                        className="dash-widget d-flex flex-column justify-content-center align-items-center"
                        onClick={() => navigate("/balancesheet")}
                      >
                        <div className="dash-boxs comman-flex-center">
                          <img src={balance} alt="#" />
                        </div>
                        <div className="dash-content dash-count">
                          <h4>Balance Sheet</h4>
                        </div>
                      </div>
                    </div>

                    {/* Column 2 */}
                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex flex-column gap-4">
                      {/* Repeat similar structure as Column 1 for 6 more elements */}
                      {/* Add other elements here */}
                    </div>

                    {/* Column 3 */}
                    <div className="col-lg-4 col-md-4 col-sm-12 d-flex flex-column gap-4">
                      {/* Repeat similar structure as Column 1 for 6 more elements */}
                      {/* Add other elements here */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default accounts_Dashboard;
