import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";

const doctor_payment = () => {
  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Voucher</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Doctor Payment</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            {/* Procedure Details Form */}
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-10">
                        <div className="form-heading">
                          <h4>Doctor Payment</h4>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Record No. <span className="login-danger">*</span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Date <span className="login-danger"></span>
                          </label>
                          <DatePicker
                            className="form-control datetimepicker"
                            format="DD-MMM-YYYY"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                            <label>
                                Doctor Name <span className="login-danger">*</span>
                            </label>
                            <select className="form-control select">
                                <option>Select Doctor</option>
                                <option>Dr Rahul</option>
                                <option>Dr Shubham</option>
                            </select>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                            <label>Bill Settled : <br /> Bill Balance Pending : <br />Cashless Bill : </label>
                            </div>
                        </div>
                        </div>



                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                                <label>
                                    Date Range
                                </label>
                                <div className="input-group">
                                    <DatePicker
                                    className="form-control datetimepicker" // Keeping it like input
                                    placeholderText="From"
                                    dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                    />
                                    <span className="input-group-text">to</span>
                                    <DatePicker
                                    className="form-control datetimepicker" // Keeping it like input
                                    placeholderText="To"
                                    dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                    />
                                </div>
                                <div className="mt-2 d-flex justify-content-end">
                                    <button className="btn btn-primary" id="filter-btn">
                                    Search
                                    </button>
                                </div>
                            </div>
                        </div>

                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group">
                          <input type="checkbox" name="" id="" /><label className="custom_check">&nbsp;&nbsp;Show Only Selected Bill</label><br />
                          <input type="checkbox" name="" id="" /><label className="custom_check">&nbsp;&nbsp;Add Statement On Print</label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group">
                          <label className="custom_check">
                            Filter Search
                          </label>&nbsp;&nbsp;&nbsp;&nbsp;
                          <input type="checkbox" name="" id="" /><label className="custom_check">OPD Bill</label>&nbsp;&nbsp;
                          <input type="checkbox" name="" id="" /><label className="custom_check">Procedure Bill</label>&nbsp;&nbsp;
                          <input type="checkbox" name="" id="" /><label className="custom_check">IPD Bill</label>&nbsp;&nbsp;
                        </div>
                      </div>
                      
                    </div>
                  </form>
                </div>
              </div>
            </div>
        </div>

          <div className="row">
            {/* First Form */}
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-10">
                        <div className="form-heading">
                          <h4>Total Paid Previously</h4>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between">
                        <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                            <label>Remarks</label>
                            <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                            <label>Total</label>
                            <input className="form-control" type="text" />
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-between">
                        <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                            <label>
                                Payment Mode
                            </label>
                            <select className="form-control select">
                                <option>Cash</option>
                                <option>Dr Rahul</option>
                                <option>Dr Shubham</option>
                            </select>
                            <select className="form-control select">
                                <option>Cash Account</option>
                            </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                            <label>
                                Tax (TDS)
                            </label>
                                <div className="col-md-12">
                                    <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="800"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                    />
                                    <span className="input-group-text" id="basic-addon2">
                                        INR
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-end">
                       
                        <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                            <label>
                            Payable Amount
                            </label>
                                <div className="col-md-12">
                                    <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="800"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                    />
                                    <span className="input-group-text" id="basic-addon2">
                                        INR
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Form submission buttons */}
                    <div className="col-12">
                      <div className="doctor-submit text-end">
                        <button
                          type="submit"
                          className="btn btn-primary submit-form me-2"
                        >
                          Save Payment Now
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary cancel-form"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>

           
          </div>
        </div>
      </div>
    </div>
  );
};

export default doctor_payment;
