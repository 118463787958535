/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  ipd,
  opd,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
// import Modal from "react-bootstrap/Modal";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
const RediologyList = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [ipdPatientModel, setIpdPatientModel] = useState(false);
  const [opdPatientModel, setOpdPatientModel] = useState(false);

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    setUserData(data.data);
  }, []);

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Rediology </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Rediology List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Rediology Dashboard</h4>
                      <ul className="nav nav-tabs nav-justified">
                        <li className="nav-item">
                          <Link
                            className="nav-link "
                            to="#basic-justified-tab1"
                            data-bs-toggle="tab"
                          >
                            <i className="fas fa-user"></i> PATIENTS
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="#basic-justified-tab2"
                            data-bs-toggle="tab"
                          >
                            <i className="fas fa-credit-card"></i>RED PAYMENT
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <div className="card-body">
                      {/* <h4 className="card-title">SMS Dashboard</h4> */}
                      <div className="tab-content">
                        <div
                          className="tab-pane show active "
                          id="basic-justified-tab1"
                        >
                          <div className="row">
                            <div className=" col-6 col-md-2 col-sm-2 col-lg-2 col-xl-2 text-center text-center">
                              <div
                                className="dash-widget d-flex flex-column justify-content-center align-items-center"
                                onClick={() => setIpdPatientModel(true)}
                              >
                                <div className="dash-boxs comman-flex-center">
                                  <img
                                    src={ipd}
                                    alt="#"
                                    className="icon-size"
                                  />
                                </div>
                                <div className="dash-content dash-count text-center">
                                  <h4>
                                    <i
                                      style={{ color: "red" }}
                                      className="fas fa-plus"
                                    />{" "}
                                    Ipd Patient
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div className=" col-6 col-md-2 col-sm-2 col-lg-2 col-xl-2 text-center text-center">
                              <div
                                className="dash-widget d-flex flex-column justify-content-center align-items-center"
                                onClick={() => setOpdPatientModel(true)}
                              >
                                <div className="dash-boxs comman-flex-center">
                                  <img
                                    src={opd}
                                    alt="#"
                                    className="icon-size"
                                  />
                                </div>
                                <div className="dash-content dash-count text-center">
                                  <h4>
                                    <i
                                      style={{ color: "red" }}
                                      className="fas fa-plus"
                                    />{" "}
                                    Opd Patient
                                  </h4>
                                </div>
                              </div>
                              <div className="col-md-4 text-center">
                                {/* You can insert your code here */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="basic-justified-tab2">
                          <div className="card card-table show-entire">
                            <div className="card-body">
                              {/* Table Header */}
                              <div className="page-table-header mb-2">
                                <div className="row align-items-center">
                                  <div className="col">
                                    <div className="doctor-table-blk">
                                      <h3>Payment List</h3>
                                      <div className="doctor-search-blk">
                                        <div className="top-nav-search table-search-blk">
                                          <form>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search here"
                                            />
                                            <Link className="btn">
                                              <img src={searchnormal} alt="#" />
                                            </Link>
                                          </form>
                                        </div>
                                        <div className="add-group">
                                          <Link
                                            to="/addappoinments"
                                            className="btn btn-primary add-pluss ms-2"
                                          >
                                            <img src={plusicon} alt="#" />
                                          </Link>
                                          <Link
                                            to="#"
                                            className="btn btn-primary doctor-refresh ms-2"
                                          >
                                            <img src={refreshicon} alt="#" />
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto text-end float-end ms-auto download-grp">
                                    <Link to="#" className=" me-2">
                                      <img src={pdficon} alt="#" />
                                    </Link>
                                    <Link to="#" className=" me-2"></Link>
                                    <Link to="#" className=" me-2">
                                      <img src={pdficon3} alt="#" />
                                    </Link>
                                    <Link to="#">
                                      <img src={pdficon4} alt="#" />
                                    </Link>
                                  </div>
                                </div>
                                {/* <!-- Filters Row --> */}
                                <div className="row align-items-center my-3">
                                  <div className="col-md-3">
                                    <label>Filter by Date</label>
                                    <div className="input-group align-items-baseline">
                                      <DatePicker
                                        className="form-control datetimepicker" // Keeping it like input
                                        selected={fromdate}
                                        onChange={(date) => setFromdate(date)}
                                        placeholderText="From"
                                        dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                      />
                                      <span className="input-group-text">
                                        to
                                      </span>
                                      <DatePicker
                                        className="form-control datetimepicker" // Keeping it like input
                                        selected={todate}
                                        onChange={(date) => setTodate(date)}
                                        placeholderText="To"
                                        dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                      />
                                    </div>
                                  </div>
                                  {/* <!-- Filter and Reset Buttons --> */}
                                  <div className="col-md-3 d-flex justify-content-end pt-3 doctor-submit">
                                    <button
                                      className="btn btn-primary me-2"
                                      id="filter-btn"
                                    >
                                      Filter
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary cancel-form"
                                    >
                                      Reset
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="basic-justified-tab3">
                          <div className="card card-table show-entire">
                            <div className="card-body">
                              {/* Table Header */}
                              <div className="page-table-header mb-2">
                                <div className="row align-items-center">
                                  <div className="col">
                                    <div className="doctor-table-blk">
                                      <h3>Whatsapp Message List</h3>
                                      <div className="doctor-search-blk">
                                        <div className="top-nav-search table-search-blk">
                                          <form>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search here"
                                            />
                                            <Link className="btn">
                                              <img src={searchnormal} alt="#" />
                                            </Link>
                                          </form>
                                        </div>
                                        <div className="add-group">
                                          <Link
                                            to="/addappoinments"
                                            className="btn btn-primary add-pluss ms-2"
                                          >
                                            <img src={plusicon} alt="#" />
                                          </Link>
                                          <Link
                                            to="#"
                                            className="btn btn-primary doctor-refresh ms-2"
                                          >
                                            <img src={refreshicon} alt="#" />
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto text-end float-end ms-auto download-grp">
                                    <Link to="#" className=" me-2">
                                      <img src={pdficon} alt="#" />
                                    </Link>
                                    <Link to="#" className=" me-2"></Link>
                                    <Link to="#" className=" me-2">
                                      <img src={pdficon3} alt="#" />
                                    </Link>
                                    <Link to="#">
                                      <img src={pdficon4} alt="#" />
                                    </Link>
                                  </div>
                                </div>
                                {/* <!-- Filters Row --> */}
                                <div className="row align-items-center my-3">
                                  <div className="col-md-3">
                                    <label>Filter by Date</label>
                                    <div className="input-group align-items-baseline">
                                      <DatePicker
                                        className="form-control datetimepicker" // Keeping it like input
                                        selected={fromdate}
                                        onChange={(date) => setFromdate(date)}
                                        placeholderText="From"
                                        dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                      />
                                      <span className="input-group-text">
                                        to
                                      </span>
                                      <DatePicker
                                        className="form-control datetimepicker" // Keeping it like input
                                        selected={todate}
                                        onChange={(date) => setTodate(date)}
                                        placeholderText="To"
                                        dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                      />
                                    </div>
                                  </div>
                                  {/* <!-- Filter and Reset Buttons --> */}
                                  <div className="col-md-3 d-flex justify-content-end pt-3 doctor-submit">
                                    <button
                                      className="btn btn-primary me-2"
                                      id="filter-btn"
                                    >
                                      Filter
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary cancel-form"
                                    >
                                      Reset
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="basic-justified-tab4">
                          <div className="tab-pane" id="basic-justified-tab2">
                            <div className="card card-table show-entire">
                              <div className="card-body">
                                {/* Table Header */}
                                <div className="page-table-header mb-2">
                                  <div className="row align-items-center">
                                    <div className="col">
                                      <div className="doctor-table-blk">
                                        <h3>Email List</h3>
                                        <div className="doctor-search-blk">
                                          <div className="top-nav-search table-search-blk">
                                            <form>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search here"
                                              />
                                              <Link className="btn">
                                                <img
                                                  src={searchnormal}
                                                  alt="#"
                                                />
                                              </Link>
                                            </form>
                                          </div>
                                          <div className="add-group">
                                            <Link
                                              to="/addappoinments"
                                              className="btn btn-primary add-pluss ms-2"
                                            >
                                              <img src={plusicon} alt="#" />
                                            </Link>
                                            <Link
                                              to="#"
                                              className="btn btn-primary doctor-refresh ms-2"
                                            >
                                              <img src={refreshicon} alt="#" />
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-auto text-end float-end ms-auto download-grp">
                                      <Link to="#" className=" me-2">
                                        <img src={pdficon} alt="#" />
                                      </Link>
                                      <Link to="#" className=" me-2"></Link>
                                      <Link to="#" className=" me-2">
                                        <img src={pdficon3} alt="#" />
                                      </Link>
                                      <Link to="#">
                                        <img src={pdficon4} alt="#" />
                                      </Link>
                                    </div>
                                  </div>
                                  {/* <!-- Filters Row --> */}
                                  <div className="row align-items-center my-3">
                                    <div className="col-md-3">
                                      <label>Filter by Date</label>
                                      <div className="input-group align-items-baseline">
                                        <DatePicker
                                          className="form-control datetimepicker" // Keeping it like input
                                          selected={fromdate}
                                          onChange={(date) => setFromdate(date)}
                                          placeholderText="From"
                                          dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                        />
                                        <span className="input-group-text">
                                          to
                                        </span>
                                        <DatePicker
                                          className="form-control datetimepicker" // Keeping it like input
                                          selected={todate}
                                          onChange={(date) => setTodate(date)}
                                          placeholderText="To"
                                          dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                        />
                                      </div>
                                    </div>
                                    {/* <!-- Filter and Reset Buttons --> */}
                                    <div className="col-md-3 d-flex justify-content-end pt-3 doctor-submit">
                                      <button
                                        className="btn btn-primary me-2"
                                        id="filter-btn"
                                      >
                                        Filter
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary cancel-form"
                                      >
                                        Reset
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={ipdPatientModel} onHide={() => setIpdPatientModel(false)}>
        <ModalHeader closeButton>
          <h5>Ipd Patient</h5>
        </ModalHeader>
        <ModalBody>
          <Table
            columns={[
              {
                title: "Patient Code",
                dataIndex: "patientCode",
                key: "patientCode",
              },
              {
                title: "Patient Name",
                dataIndex: "patientName",
                key: "patientName",
              },
              {
                title: "IPD Number",
                dataIndex: "ipdNumber",
                key: "ipdNumber",
              },
            ]}
            dataSource={[
              {
                patientCode: "PC001",
                patientName: "John Doe",
                ipdNumber: "IPD123",
              },
              {
                patientCode: "PC002",
                patientName: "Jane Doe",
                ipdNumber: "IPD456",
              },
              {
                patientCode: "PC003",
                patientName: "Bob Smith",
                ipdNumber: "IPD789",
              },
            ]}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIpdPatientModel(false)}
          >
            Close
          </Button>
          <Button type="button" className="btn btn-success">
            Save
          </Button>
        </ModalFooter>
      </Modal>
      <Modal show={opdPatientModel} onHide={() => setOpdPatientModel(false)}>
        <ModalHeader closeButton>
          <h5>Opd Patient</h5>
        </ModalHeader>
        <ModalBody>
          <Table
            columns={[
              {
                title: "Patient Code",
                dataIndex: "patientCode",
                key: "patientCode",
              },
              {
                title: "Patient Name",
                dataIndex: "patientName",
                key: "patientName",
              },
              {
                title: "OPD Number",
                dataIndex: "opdNumber",
                key: "opdNumber",
              },
            ]}
            dataSource={[
              {
                patientCode: "PC001",
                patientName: "John Doe",
                opdNumber: "OPD123",
              },
              {
                patientCode: "PC002",
                patientName: "Jane Doe",
                opdNumber: "OPD456",
              },
              {
                patientCode: "PC003",
                patientName: "Bob Smith",
                opdNumber: "OPD789",
              },
            ]}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            className="btn btn-secondary"
            onClick={() => setOpdPatientModel(false)}
          >
            Close
          </Button>
          <Button type="button" className="btn btn-success">
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RediologyList;
