/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
  dummysignature,
  imguser,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import toast from "react-hot-toast";
import Select from "react-select";
import { loadingService } from "../../helper/observer";
import html2pdf from "html2pdf.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddOpdSlave = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bp, setBp] = useState("");
  const [sugar, setSugar] = useState("");
  const [weight, setWeight] = useState("");
  const [oxygen, setOxygen] = useState("");
  const [PatientData, setPatientData] = useState({});
  const [medicineList, setMedicineList] = useState([]);
  const [testList, setTestList] = useState([]);
  const [PrescriptionTemplateList, setPrescriptionTemplateList] = useState([]);
  const [userData, setUserData] = useState(null);
  const [opdSlaveId, setOpdSlaveId] = useState("");
  const [pad, setPad] = useState("");
  const [template, setTemplate] = useState("1");
  const [selectedMedicineValue, setSelectedMedicineValue] = useState("");
  const [selectedNotes, setSelectedNotes] = useState("");
  const [PrescriptionTemplate, setPrescriptionTemplate] = useState("");

  const [rows, setRows] = useState([
    {
      id: 1,
      name: "",
      frequency: "1-0-1",
      duration: "",
      notes: "After Meal",
      selectedMedicine: null,
    },
  ]);

  const options = medicineList.map((m) => ({
    value: m.id,
    label: m.name,
  }));

  const addRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        name: "",
        frequency: "1-0-1",
        duration: "",
        notes: "After Meal",
      },
    ]);
  };

  const handleMedicineChange = (index, selectedOption) => {
    const updatedRows = [...rows];
    updatedRows[index].selectedMedicine = selectedOption;
    updatedRows[index].name = selectedOption ? selectedOption.label : ""; // Update the name conditionally
    setRows(updatedRows);

    // Update the selectedMedicineValue for the corresponding row
    const updatedSelectedMedicineValues = [...selectedMedicineValue];
    updatedSelectedMedicineValues[index] = selectedOption
      ? selectedOption.label
      : "";
    setSelectedMedicineValue(updatedSelectedMedicineValues);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setRows(updatedRows);

    if (name == "notes") {
      const updatedSelectedNotes = [...selectedNotes];
      updatedSelectedNotes[index] = value;
      setSelectedNotes(updatedSelectedNotes);
    }

    // Update the corresponding value in the input state
  };

  const removeRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);

    setUserData(data.data);
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getMedicineList(userData);
      getTestList(userData);
      getPrescriptionTemplateList(userData);
    }
  }, [userData]);

  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!bp || !sugar || !weight || !oxygen) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      appointment_id: id,
      branch_id: userData.hospitalid ?? "",
      bp: bp,
      sugar: sugar,
      weight: weight,
      oxygen: oxygen,
      pId: PatientData.pId,
    };

    try {
      if (!opdSlaveId) {
        loadingService.next(true);

        const { data } = await AuthApi.post(Api.addopdvisitslave, payload);
        loadingService.next(false);

        if (data) {
          toast.success("Success");
          navigate("/opdlist");
        }
      } else {
        loadingService.next(true);

        const { status } = await AuthApi.post(
          `${Api.updateopdvisitslave}/${opdSlaveId}`,
          payload
        );
        loadingService.next(false);

        if (status) {
          toast.success("Success");
          navigate("/opdlist");
        }
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  const getOpdList = async () => {
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(
      `${Api.assignmentInfo}/${id}`
    );
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }

    setPatientData(data);
  };

  const getMedicineList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.medicineList;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchmedicineList}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchmedicineList}/${user.branch_id}`;
    } else {
      url = `${Api.branchmedicineList}/${user.branch_id}`;
    }

    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setMedicineList(data);
  };
  const getTestList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.testList;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchtestList}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchtestList}/${user.branch_id}`;
    } else {
      url = `${Api.branchtestList}/${user.branch_id}`;
    }
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setTestList(data);
  };

  const getopdslavedata = async () => {
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(
      `${Api.opdslaveInfo}/${id}`
    );
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setBp(data.bp);
    setSugar(data.sugar);
    setWeight(data.weight);
    setOxygen(data.oxygen);
    setOpdSlaveId(data.id);
  };

  useEffect(() => {
    getOpdList();
    getopdslavedata();
  }, []);

  const handlePrint = async () => {
    const checkedTestValues = Object.entries(
      testList.reduce((acc, m) => {
        if (!acc[m.category]) {
          acc[m.category] = [];
        }
        acc[m.category].push(m);
        return acc;
      }, {})
    ).flatMap(([category, testsInCategory]) =>
      testsInCategory.reduce((accumulator, test, index) => {
        const checkbox = document.getElementById(
          `inlineCheckbox_${category}_${index}`
        );
        if (checkbox && checkbox.checked) {
          accumulator.push(test.name);
        }
        return accumulator;
      }, [])
    );

    const rowData = rows.map((row) => ({
      id: row.id,
      name: row.name,
      frequency: row.frequency,
      duration: row.duration,
      notes: row.notes,
    }));

    // Convert data to JSON format
    const jsonData = JSON.stringify(rowData);
    const jsonData2 = JSON.stringify(checkedTestValues);

    let payload = {
      appointment_id: id,
      branch_id: userData.hospitalid ?? "",
      rowData: jsonData,
      checkedTestValues: jsonData2,
      pId: PatientData.pId,
      template: template,
      pad: pad,
    };

    try {
      let responseId;
      if (!opdSlaveId) {
        // loadingService.next(true);
        const { data } = await AuthApi.post(Api.addopdvisitslave, payload);
        // loadingService.next(false);
        responseId = data.id; // Capture the generated ID from response
      } else {
        // loadingService.next(true);
        const { status } = await AuthApi.post(
          `${Api.updateopdprescriptionlave}/${opdSlaveId}`,
          payload
        );
        responseId = opdSlaveId; // Use existing ID if updating
        // loadingService.next(false);
      }
      if (responseId) {
        await generatepdf(responseId, template); // Pass the ID to generatepdf
        toast.success("Success");
        navigate("/opdlist");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
    // Include checked test values in the printable content
    const printableAreaId =
      template == 1 ? "printable-area" : "printable-area2";
    const printableArea = document.getElementById(printableAreaId);
    let printContents = printableArea.innerHTML;
    printContents += "<br>";
    printContents += "<h5>Recommended Tests:</h5>";
    printContents += "<ul>";
    checkedTestValues.forEach((test) => {
      printContents += `<li>${test}</li>`;
    });
    printContents += "</ul>";
    printContents += "<hr>";
    printContents +=
      "<div style='text-align: center;'>Mobile: 9011053954/9881255159 Email:sabalehospital2020@gmail.com</div>";

    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;

    window.location.href = "/opdlist";
  };

  const handleSave = async () => {
    const checkedTestValues = Object.entries(
      testList.reduce((acc, m) => {
        if (!acc[m.category]) {
          acc[m.category] = [];
        }
        acc[m.category].push(m);
        return acc;
      }, {})
    ).flatMap(([category, testsInCategory]) =>
      testsInCategory.reduce((accumulator, test, index) => {
        const checkbox = document.getElementById(
          `inlineCheckbox_${category}_${index}`
        );
        if (checkbox && checkbox.checked) {
          accumulator.push(test.name);
        }
        return accumulator;
      }, [])
    );

    const rowData = rows.map((row) => ({
      id: row.id,
      name: row.name,
      frequency: row.frequency,
      duration: row.duration,
      notes: row.notes,
    }));

    // Convert data to JSON format
    const jsonData = JSON.stringify(rowData);
    const jsonData2 = JSON.stringify(checkedTestValues);

    let payload = {
      appointment_id: id,
      branch_id: userData.hospitalid ?? "",
      rowData: jsonData,
      checkedTestValues: jsonData2,
      pId: PatientData.pId,
      template: template,
      pad: pad,
    };

    try {
      let responseId;
      if (!opdSlaveId) {
        loadingService.next(true);
        const { data } = await AuthApi.post(Api.addopdvisitslave, payload);
        loadingService.next(false);
        responseId = data.id; // Capture the generated ID from response
      } else {
        loadingService.next(true);
        const { status } = await AuthApi.post(
          `${Api.updateopdprescriptionlave}/${opdSlaveId}`,
          payload
        );
        responseId = opdSlaveId; // Use existing ID if updating
        loadingService.next(false);
      }
      if (responseId) {
        await generatepdf(responseId, template); // Pass the ID to generatepdf
        toast.success("Success");
        navigate("/opdlist");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  const generatepdf = async (responseId, template) => {
    try {
      const printButtons = document.querySelectorAll(".print-button");
      const meditablehide = document.querySelectorAll(".meditablehide");
      const meditableunhide = document.querySelectorAll(".meditableunhide");
      const recomendedhide = document.querySelectorAll(".recomendedhide");
      const recomendedunhide = document.querySelectorAll(".recomendedunhide");
      const ckeditor = document.querySelectorAll(".ck-sticky-panel__content");

      // Hide certain buttons
      printButtons.forEach((button) => {
        button.style.display = "none";
      });
      ckeditor.forEach((button) => {
        button.style.display = "none";
      });
      meditablehide.forEach((button) => {
        button.style.display = "block";
      });
      meditableunhide.forEach((button) => {
        button.style.display = "none";
      });
      recomendedhide.forEach((button) => {
        button.style.display = "block";
      });
      recomendedunhide.forEach((button) => {
        button.style.display = "none";
      });

      let content; // Declare content variable outside the if-else block

      if (template == 1) {
        content = document.getElementById("printable-area"); // Assign element based on template
      } else {
        content = document.getElementById("printable-area2"); // Assign element for template != 1
      }

      // PDF generation options
      const pdfOptions = {
        margin: [5, 5, 5, 5], // Reduced margins
        filename: "Prescription.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1.5 }, // Increase scale for better quality
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { mode: ["avoid-all", "css"] }, // Avoid page breaks
      };

      // loadingService.next(true);

      // Generate the PDF and get the Blob object
      const pdfBlob = await html2pdf()
        .set(pdfOptions)
        .from(content)
        .output("blob"); // Generates a Blob for downloading

      // loadingService.next(false);
      await saveToDatabase(pdfBlob, responseId);

      // Trigger the download
      // const blobURL = URL.createObjectURL(pdfBlob);
      // const a = document.createElement('a');
      // a.href = blobURL;
      // a.download = "Prescription.pdf"; // Set the desired file name
      // document.body.appendChild(a);
      // a.click(); // Simulate a click to trigger the download
      // document.body.removeChild(a); // Clean up

      // Restore button visibility
      printButtons.forEach((button) => {
        button.style.display = "block";
      });
      meditablehide.forEach((button) => {
        button.style.display = "none";
      });
      meditableunhide.forEach((button) => {
        button.style.display = "block";
      });
      recomendedhide.forEach((button) => {
        button.style.display = "none";
      });
      recomendedunhide.forEach((button) => {
        button.style.display = "block";
      });
      ckeditor.forEach((button) => {
        button.style.display = "block";
      });
    } catch (error) {
      console.error(error);
    }
  };

  const saveToDatabase = async (pdfData, responseId) => {
    // Create a Blob from the PDF data
    const pdfBlob = new Blob([pdfData], { type: "application/pdf" });

    const formData = new FormData();
    formData.append("file", pdfBlob, "PharmDReport.pdf");

    try {
      loadingService.next(true);
      const response = await AuthApi.post(
        `${Api.savepdfprescription}/${responseId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      loadingService.next(false);

      if (response) {
        toast.success("Success", { position: "top-right" });
      } else {
        toast.error("Failed", { position: "top-right" });
      }

      // Handle the response as needed
      console.log(response.data);
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  const getPrescriptionTemplateList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.precriptiontemplatelist;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchprecriptiontemplatelist}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchprecriptiontemplatelist}/${user.branch_id}`;
    } else {
      url = `${Api.branchprecriptiontemplatelist}/${user.branch_id}`;
    }
    loadingService.next(true);
    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);
    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setPrescriptionTemplateList(data);
  };

  const templateoptions = PrescriptionTemplateList.map((v) => ({
    value: v.template,
    label: v.template_name,
  }));

  const handleChange = (selectedOption) => {
    setPrescriptionTemplate(selectedOption);
    if (selectedOption && selectedOption.value) {
      setPad(selectedOption.value); // Set the value if selectedOption exists and has a value
    } else {
      setPad(""); // Set to empty string if selectedOption is null, undefined, or has no value
    }
  };

  const [selectedTests, setSelectedTests] = useState({});

  const handleCheckboxChange = (category, testId) => {
    setSelectedTests((prev) => {
      const newSelected = { ...prev };
      if (!newSelected[category]) {
        newSelected[category] = [];
      }
      if (newSelected[category].includes(testId)) {
        newSelected[category] = newSelected[category].filter(
          (id) => id !== testId
        ); // Remove if already selected
      } else {
        newSelected[category].push(testId); // Add if not selected
      }
      return newSelected;
    });
  };

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="add-appoinment"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">OPD </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add OPD</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="card-box profile-header">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img className="avatar" src={imguser} alt="#" />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {PatientData.patient_name}
                            </h3>
                            {/* <small className="text-muted">Gynecologist</small> */}
                            <div className="staff-id">
                              Opd ID : {PatientData.opd_id}
                            </div>
                            <div className="staff-id">
                              Patient ID : {PatientData.patientId}
                            </div>
                            <div className="staff-msg">
                              <Link to="/chat" className="btn"></Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <span className="title">Age:</span>
                              <span className="text">{PatientData.age}</span>
                            </li>
                            <li>
                              <span className="title">Gender:</span>
                              <span className="text">{PatientData.gender}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>OPD Details</h4>
                          </div>
                        </div>

                        {/* name */}
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              BP <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={bp}
                              onChange={(e) => setBp(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Sugar <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={sugar}
                              onChange={(e) => setSugar(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Weight <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={weight}
                              onChange={(e) => setWeight(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Oxygen <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={oxygen}
                              onChange={(e) => setOxygen(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                navigate("/opdlist");
                              }}
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms col-12 col-md-6 col-xl-6">
                              <label>
                                Prescription Template{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <select
                                value={template}
                                onChange={(e) => setTemplate(e.target.value)}
                                className="form-select"
                              >
                                <option value="1">Dr Sabale</option>
                                <option value="2">Dr Rahul Patil</option>
                              </select>
                            </div>
                          </div>
                          {template == 2 && (
                            <>
                              <div className="col-12 col-md-6 col-xl-6">
                                <div className="form-group local-forms col-12 col-md-6 col-xl-6">
                                  <label>
                                    Import Template
                                    <span className="login-danger">*</span>
                                  </label>
                                  <Select
                                    className="form-control-alternative"
                                    options={templateoptions}
                                    value={PrescriptionTemplate}
                                    onChange={handleChange}
                                    placeholder="Search And Select"
                                    isSearchable
                                    isClearable
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused
                                          ? "none"
                                          : "2px solid rgba(46, 55, 164, 0.1);",
                                        boxShadow: state.isFocused
                                          ? "0 0 0 1px #2e37a4"
                                          : "none",
                                        "&:hover": {
                                          borderColor: state.isFocused
                                            ? "none"
                                            : "2px solid rgba(46, 55, 164, 0.1)",
                                        },
                                        borderRadius: "10px",
                                        fontSize: "14px",
                                        minHeight: "45px",
                                      }),
                                      dropdownIndicator: (base, state) => ({
                                        ...base,
                                        transform: state.selectProps.menuIsOpen
                                          ? "rotate(-180deg)"
                                          : "rotate(0)",
                                        transition: "250ms",
                                        width: "35px",
                                        height: "35px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {template == 1 && (
                          <>
                            <div
                              className="col-12 col-md-6 col-xl-12 shadow p-3 mb-5 bg-white rounded"
                              id="printable-area"
                            >
                              <div className="d-flex justify-content-center">
                                <div className="">
                                  <h2 className="text-align-center mb-0">
                                    SABALE&apos;S SUPER SPECIALITY HOSPITAL
                                  </h2>
                                  <p className="text-align-center mb-0">
                                    Sai Venkata Tread Center, Pune-Nashik
                                    Highway, Bhosari, Pune 411039
                                  </p>
                                  <p className="text-align-center mb-0">
                                    Mobile: 9011053954/9881255159 Email:
                                    sabalehospital2020@gmail.com
                                  </p>
                                </div>
                              </div>
                              <hr></hr>
                              <div className="d-flex justify-content-center">
                                <h4>
                                  <b>Prescription</b>
                                </h4>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <p>
                                    <b>Patient Id:</b> {PatientData.patientId}
                                  </p>
                                  <p>
                                    <b>Patient Name:</b>{" "}
                                    {PatientData.patient_name}
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <b>Age:</b> {PatientData.age}
                                  </p>
                                  <p>
                                    <b>Gender:</b> {PatientData.gender}
                                  </p>
                                </div>
                              </div>
                              <hr></hr>
                              <div className="col-12 col-md-6 col-xl-12">
                                <div className="table-responsive meditableunhide">
                                  <table
                                    className="table mb-0"
                                    style={{
                                      height: "50vh",
                                      resize: "none",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Sr No</th>
                                        <th>Medicine Name</th>
                                        <th>Frequency</th>
                                        <th>Duration</th>
                                        <th>Notes</th>
                                        <th className="print-hide print-button">
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {rows.map((row, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>
                                            <Select
                                              className="form-control-alternative print-hide"
                                              options={options}
                                              placeholder="Search And Select"
                                              isSearchable
                                              isClearable
                                              value={row.selectedMedicine} // Change here to set the value of the Select
                                              onChange={(selectedOption) =>
                                                handleMedicineChange(
                                                  index,
                                                  selectedOption
                                                )
                                              }
                                              styles={{
                                                menuPortal: (base) => ({
                                                  ...base,
                                                  zIndex: 9999,
                                                }),
                                                control: (
                                                  baseStyles,
                                                  state
                                                ) => ({
                                                  ...baseStyles,
                                                  borderColor: state.isFocused
                                                    ? "none"
                                                    : "2px solid rgba(46, 55, 164, 0.1);",
                                                  boxShadow: state.isFocused
                                                    ? "0 0 0 1px #2e37a4"
                                                    : "none",
                                                  "&:hover": {
                                                    borderColor: state.isFocused
                                                      ? "none"
                                                      : "2px solid rgba(46, 55, 164, 0.1)",
                                                  },
                                                  borderRadius: "10px",
                                                  fontSize: "14px",
                                                  minHeight: "45px",
                                                }),
                                                dropdownIndicator: (
                                                  base,
                                                  state
                                                ) => ({
                                                  ...base,
                                                  transform: state.selectProps
                                                    .menuIsOpen
                                                    ? "rotate(-180deg)"
                                                    : "rotate(0)",
                                                  transition: "250ms",
                                                  width: "35px",
                                                  height: "35px",
                                                }),
                                              }}
                                            />
                                            <input
                                              type="text"
                                              name="name"
                                              className="form-control mediname"
                                              value={
                                                selectedMedicineValue[index] ||
                                                ""
                                              }
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="frequency"
                                              value={row.frequency}
                                              onChange={(e) =>
                                                handleInputChange(index, e)
                                              }
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="duration"
                                              value={row.duration}
                                              onChange={(e) =>
                                                handleInputChange(index, e)
                                              }
                                            />
                                          </td>
                                          <td>
                                            <select
                                              className="form-control print-hide"
                                              name="notes"
                                              value={row.notes}
                                              onChange={(e) =>
                                                handleInputChange(index, e)
                                              }
                                            >
                                              <option value="Before Meal">
                                                Before Meal
                                              </option>
                                              <option value="After Meal">
                                                After Meal
                                              </option>
                                              <option value="जेवणानंतर">
                                                जेवणानंतर
                                              </option>
                                              <option value="जेवणापूर्वी">
                                                जेवणापूर्वी
                                              </option>
                                            </select>
                                            <input
                                              type="text"
                                              name="name"
                                              className="form-control mediname"
                                              value={
                                                selectedNotes[index] ||
                                                "After Meal"
                                              }
                                            />
                                          </td>
                                          <td className="print-hide print-button">
                                            {index === rows.length - 1 ? (
                                              <>
                                                <button
                                                  type="button"
                                                  className="btn btn-light print-hide"
                                                  onClick={addRow}
                                                >
                                                  +
                                                </button>
                                                {rows.length > 1 && (
                                                  <button
                                                    type="button"
                                                    className="btn btn-light print-hide"
                                                    style={{
                                                      marginLeft: "5px",
                                                    }}
                                                    onClick={() =>
                                                      removeRow(index)
                                                    }
                                                  >
                                                    x
                                                  </button>
                                                )}
                                              </>
                                            ) : (
                                              <button
                                                type="button"
                                                className="btn btn-light print-hide"
                                                style={{ marginLeft: "5px" }}
                                                onClick={() => removeRow(index)}
                                              >
                                                x
                                              </button>
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="table-responsive meditablehide">
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th>Sr No</th>
                                        <th>Medicine Name</th>
                                        <th>Frequency</th>
                                        <th>Duration</th>
                                        <th>Notes</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {rows.map((row, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{row.name}</td>
                                          <td>{row.frequency}</td>
                                          <td>{row.duration}</td>
                                          <td>{row.notes}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                                <hr className="print-hide"></hr>
                                <div className="col-md-12 print-hide recomendedunhide">
                                  <div className="card">
                                    <div className="card-body">
                                      <h6 className="card-title">
                                        Recommended Tests
                                      </h6>
                                      <ul className="nav nav-tabs nav-tabs-bottom">
                                        {Object.keys(
                                          testList.reduce((acc, m) => {
                                            if (!acc[m.category]) {
                                              acc[m.category] = [];
                                            }
                                            acc[m.category].push(m);
                                            return acc;
                                          }, {})
                                        ).map((category, index) => (
                                          <li
                                            className="nav-item"
                                            key={category}
                                          >
                                            <Link
                                              className={`nav-link ${
                                                index === 0 ? "active" : ""
                                              }`}
                                              to={`#bottom-tab${index + 1}`}
                                              data-bs-toggle="tab"
                                            >
                                              {category}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                      <div className="tab-content">
                                        {Object.entries(
                                          testList.reduce((acc, m) => {
                                            if (!acc[m.category]) {
                                              acc[m.category] = [];
                                            }
                                            acc[m.category].push(m);
                                            return acc;
                                          }, {})
                                        ).map(
                                          (
                                            [category, testsInCategory],
                                            index
                                          ) => (
                                            <div
                                              className={`tab-pane ${
                                                index === 0 ? "show active" : ""
                                              }`}
                                              id={`bottom-tab${index + 1}`}
                                              key={category}
                                            >
                                              <div
                                                className="checkbox-list"
                                                style={{
                                                  maxHeight: "200px",
                                                  overflowY: "auto",
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                }}
                                              >
                                                {testsInCategory.map(
                                                  (test, testIndex) => (
                                                    <div
                                                      className="form-check"
                                                      key={test.id}
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginRight: "15px",
                                                      }}
                                                    >
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`inlineCheckbox_${category}_${testIndex}`}
                                                        value={test.id}
                                                        onChange={() =>
                                                          handleCheckboxChange(
                                                            category,
                                                            test.id
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor={`inlineCheckbox_${category}_${testIndex}`}
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        {test.name}
                                                      </label>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* Selected Tests Table */}
                                <div className="mt-4 recomendedhide">
                                  <h6 className="card-title">
                                    Recommended Tests
                                  </h6>
                                  <ul>
                                    {Object.entries(selectedTests).flatMap(
                                      ([category, testIds]) =>
                                        testIds.map((testId) => {
                                          const test = testList.find(
                                            (t) => t.id === testId
                                          );
                                          return (
                                            <li key={testId}>
                                              <strong>{category}:</strong>{" "}
                                              {test
                                                ? test.name
                                                : "Unknown Test"}
                                            </li>
                                          );
                                        })
                                    )}
                                  </ul>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <div>
                                    <img
                                      src={dummysignature}
                                      width={150}
                                      height={100}
                                      alt=""
                                    />
                                    <h6>Stamp & Signature</h6>
                                  </div>
                                </div>
                                <hr className="print-hide"></hr>
                                <div className="d-flex justify-content-center print-hide">
                                  <div className="">
                                    <p className="text-align-center mb-0">
                                      Mobile: 9011053954/9881255159 Email:
                                      sabalehospital2020@gmail.com
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {template == 2 && (
                          <>
                            <div
                              className="col-12 col-md-6 col-xl-12 shadow p-3 mb-5 bg-white rounded"
                              id="printable-area2"
                            >
                              <div className="d-flex justify-content-center">
                                <div className="">
                                  <h2 className="text-align-center mb-0">
                                    SABALE&apos;S SUPER SPECIALITY HOSPITAL
                                  </h2>
                                  <p className="text-align-center mb-0">
                                    Sai Venkata Tread Center, Pune-Nashik
                                    Highway, Bhosari, Pune 411039
                                  </p>
                                  <p className="text-align-center mb-0">
                                    Mobile: 9011053954/9881255159 Email:
                                    sabalehospital2020@gmail.com
                                  </p>
                                </div>
                              </div>
                              <hr></hr>
                              <div className="d-flex justify-content-center">
                                <h4>
                                  <b>Prescription</b>
                                </h4>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <p>
                                    <b>Patient Id:</b> {PatientData.patientId}
                                  </p>
                                  <p>
                                    <b>Patient Name:</b>{" "}
                                    {PatientData.patient_name}
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <b>Age:</b> {PatientData.age}
                                  </p>
                                  <p>
                                    <b>Gender:</b> {PatientData.gender}
                                  </p>
                                </div>
                              </div>
                              <hr></hr>
                              <div className="col-12 col-md-6 col-xl-12">
                                {/* <textarea
                                  className="w-100 form-control"
                                  style={{
                                    height: "50vh",
                                    resize: "none",
                                    overflow: "hidden",
                                  }}
                                  value={pad}
                                  onChange={(e) => setPad(e.target.value)}
                                >
                                  {pad}
                                </textarea> */}

                                <div className="">
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={pad}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      setPad(data);
                                    }}
                                  />
                                </div>
                                <hr className="print-hide"></hr>
                                <div className="col-md-12 print-hide recomendedunhide">
                                  <div className="card">
                                    <div className="card-body">
                                      <h6 className="card-title">
                                        Recommended Tests
                                      </h6>
                                      <ul className="nav nav-tabs nav-tabs-bottom">
                                        {Object.keys(
                                          testList.reduce((acc, m) => {
                                            if (!acc[m.category]) {
                                              acc[m.category] = [];
                                            }
                                            acc[m.category].push(m);
                                            return acc;
                                          }, {})
                                        ).map((category, index) => (
                                          <li
                                            className="nav-item"
                                            key={category}
                                          >
                                            <Link
                                              className={`nav-link ${
                                                index === 0 ? "active" : ""
                                              }`}
                                              to={`#bottom-tab${index + 1}`}
                                              data-bs-toggle="tab"
                                            >
                                              {category}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                      <div className="tab-content">
                                        {Object.entries(
                                          testList.reduce((acc, m) => {
                                            if (!acc[m.category]) {
                                              acc[m.category] = [];
                                            }
                                            acc[m.category].push(m);
                                            return acc;
                                          }, {})
                                        ).map(
                                          (
                                            [category, testsInCategory],
                                            index
                                          ) => (
                                            <div
                                              className={`tab-pane ${
                                                index === 0 ? "show active" : ""
                                              }`}
                                              id={`bottom-tab${index + 1}`}
                                              key={category}
                                            >
                                              <div
                                                className="checkbox-list"
                                                style={{
                                                  maxHeight: "200px",
                                                  overflowY: "auto",
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                }}
                                              >
                                                {testsInCategory.map(
                                                  (test, testIndex) => (
                                                    <div
                                                      className="form-check"
                                                      key={test.id}
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginRight: "15px",
                                                      }}
                                                    >
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`inlineCheckbox_${category}_${testIndex}`}
                                                        value={test.id}
                                                        onChange={() =>
                                                          handleCheckboxChange(
                                                            category,
                                                            test.id
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor={`inlineCheckbox_${category}_${testIndex}`}
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        {test.name}
                                                      </label>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* Selected Tests Table */}
                                <div className="mt-4 recomendedhide">
                                  <h6 className="card-title">
                                    Recommended Tests
                                  </h6>
                                  <ul>
                                    {Object.entries(selectedTests).flatMap(
                                      ([category, testIds]) =>
                                        testIds.map((testId) => {
                                          const test = testList.find(
                                            (t) => t.id === testId
                                          );
                                          return (
                                            <li key={testId}>
                                              <strong>{category}:</strong>{" "}
                                              {test
                                                ? test.name
                                                : "Unknown Test"}
                                            </li>
                                          );
                                        })
                                    )}
                                  </ul>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <div>
                                    <img
                                      src={dummysignature}
                                      width={150}
                                      height={100}
                                      alt=""
                                    />
                                    <h6>Stamp & Signature</h6>
                                  </div>
                                </div>
                                <hr className="print-hide"></hr>
                                <div className="d-flex justify-content-center print-hide">
                                  <div className="">
                                    <p className="text-align-center mb-0">
                                      Mobile: 9011053954/9881255159 Email:
                                      sabalehospital2020@gmail.com
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-12 col-md-6 col-xl-12 d-flex justify-content-end">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={handleSave}
                          >
                            Save
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={handlePrint}
                          >
                            Save & Print
                          </button>
                        </div>
                        {/* <div>
                            <button
                              type="button"
                              className="ml-5 btn btn-success"
                              onClick={addRow}
                            >
                              Add Row
                            </button>
                          </div> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddOpdSlave;
