import { calendar } from "./imagepath"; // Assuming 'dashboard' icon comes from 'imagepath'
import { patients } from "./imagepath"; // Assuming 'dashboard' icon comes from 'imagepath'
import { doctor } from "./imagepath"; // Assuming 'dashboard' icon comes from 'imagepath
// import { menuicon15 } from "./imagepath"; // Assuming 'dashboard' icon comes from 'imagepath'
import { settingicon01 } from "./imagepath"; // Assuming 'dashboard' icon comes from 'imagepath'
import { menuicon09 } from "./imagepath"; // Assuming 'dashboard' icon comes from 'imagepath'
import { menuicon06 } from "./imagepath"; // Assuming 'dashboard' icon comes from 'imagepath'
import { menuicon08 } from "./imagepath"; // Assuming 'dashboard' icon comes from 'imagepath'
import { blog } from "./imagepath"; // Assuming 'dashboard' icon comes from 'imagepath'
import { dashboard } from "./imagepath"; // Assuming 'dashboard' icon comes from 'imagepath'
import { sidemenu } from "./imagepath"; // Assuming 'dashboard' icon comes from 'imagepath'

const menuItems = [
  //   {
  //     name: "Dashboard",
  //     iconName: "",
  //     link: "/admin-dashboard",
  //     allowedUser: [9, 8, 7, 6, 5],
  //   },
  {
    name: "Patients",
    iconName: patients,
    link: "#",
    allowedUser: [8, 7, 6, 5],
    submenu: [
      {
        name: "Patient List",
        link: "/patientslist",
      },
      {
        name: "Add Patient",
        link: "/addpatients",
      },
    ],
  },
  {
    name: "Doctors",
    iconName: doctor,
    link: "#",
    allowedUser: [8, 7],
    submenu: [
      {
        name: "Doctor List",
        link: "/doctorlist",
      },
      {
        name: "Add Doctor",
        link: "/add-doctor",
      },
    ],
  },
  {
    name: "Booking",
    iconName: calendar,
    link: "#",
    allowedUser: [8, 7, 5],
    submenu: [
      {
        name: "Appointment List",
        link: "/appoinmentlist",
      },
      {
        name: "Add Appointment",
        link: "/addappoinments",
      },
    ],
  },
  
  {
    name: "OPD",
    iconName: blog,
    link: "/",
    allowedUser: [8, 7, 6],
    submenu: [
      {
        name: "OPD List",
        link: "/opdlist",
      },
    ],
  },
  {
    name: "IPD",
    iconName: menuicon06,
    link: "/",
    allowedUser: [8, 7, 6],
    submenu: [
      {
        name: "IPD Request",
        link: "/ipdrequest",
      },
      {
        name: "IPD List",
        link: "/ipdlist",
      },
    ],
  },
  {
    name: "Discharge Request",
    iconName: blog,
    link: "#",
    allowedUser: [8, 7, 6],
    submenu: [
      {
        name: "Requested List",
        link: "/dischargerequestlist",
      },
      {
        name: "Discharged List",
        link: "/dischargedlist",
      },
    ],
  },
  {
    name: "Billing",
    iconName: sidemenu,
    link: "#",
    allowedUser: [8, 7, 6],
    submenu: [
      {
        name: "Billing List",
        link: "/billinglist",
      },
    ],
  },
  {
    name: "Reports",
    iconName: sidemenu,
    link: "#",
    allowedUser: [8, 7, 6],
    submenu: [
      {
        name: "Reports List",
        link: "/report-list",
      },
    ],
  },
  {
    name: "RAD",
    iconName: sidemenu,
    link: "#",
    allowedUser: [8, 7, 6],
    submenu: [
      {
        name: "RAD",
        link: "/red-list",
      },
    ],
  },
  {
    name: "Payments",
    iconName: menuicon09,
    link: "#",
    allowedUser: [8],
    submenu: [
      {
        name: "Appointment Receipts",
        link: "/paymentreceiptlist",
        // activeClassName: "paymentreceiptlist",
      },
      {
        name: "Billing Receipts",
        link: "/billingreceiptlist",
      },
    ],
  },
  {
    name: "SMS",
    iconName: menuicon09,
    link: "#",
    allowedUser: [8],
    submenu: [
      {
        name: "SMS",
        link: "/sms-list",
      },
      
    ],
  },
  
  
  // {
  //   name: "Template",
  //   iconName: menuicon15,
  //   link: "#",
  //   allowedUser: [8,7,6],
  //   submenu: [
  //     {
  //       name: "Template List",
  //       link: "/precriptiontemplatelist",
  //     },
  //     {
  //       name: "Add Template",
  //       link: "/addprecriptiontemplate",
  //     },
  //   ],
  // },
  {
    name: "Settings",
    iconName: settingicon01,
    link: "#",
    allowedUser: [8, 7, 6],
    submenu: [
      {
        name: "Medicine List",
        link: "/medicineslist",
      },
      // {
      //   name: "Add Medicine",
      //   link: "/addmedicine",
      // },
      {
        name: "Lab Test List",
        link: "/testlist",
      },
      // {
      //   name: "Add Lab Test",
      //   link: "/addtest",
      // },
      {
        name: "Test Category",
        link: "/testcategorylist",
      },
      // {
      //   name: "Add Test Category",
      //   link: "/addtestcategory",
      // },
      {
        name: "Precription Templates",
        link: "/precriptiontemplatelist",
      },
      {
        name: "Bed",
        link: "/bedsettinglist",
      },
      {
        name: "Bed Category",
        link: "/bedcategorylist",
      },
    ],
  },
  {
    name: "Master Setting",
    iconName: settingicon01,
    link: "#",
    allowedUser: [8,7,6],
    submenu: [
      {
        name: "Settings",
        link: "/settingpage",
      }
    ],
  },
  {
    name: "TPA",
    iconName: settingicon01,
    link: "#",
    allowedUser: [8,7,6],
    submenu: [
      {
        name: "TPA",
        link: "/tpadashboard",
      }
    ],
  },
  // {
  //   name: "Receptionist",
  //   iconName: menuicon08,
  //   link: "/",
  //   allowedUser: [8, 7],
  //   submenu: [
  //     {
  //       name: "Receptionist List",
  //       link: "/receptionistlist",
  //     },
  //     {
  //       name: "Add Receptionist",
  //       link: "/receptionistadd",
  //     },
  //   ],
  // },
  {
    name: "Account",
    iconName: menuicon08,
    link: "/",
    allowedUser: [8, 7],
    submenu: [
      {
        name: "Voucher",
        link: "/accountsdashboard",
      },
      {
        name: "Register",
        link: "#",
      },
    ],
  },
  {
    name: "Hospital",
    iconName: dashboard,
    link: "#",
    allowedUser: [9],
    submenu: [
      {
        name: "Hospital List",
        link: "/hospitallist",
      },
      {
        name: "Add Hospital",
        link: "/addhospital",
      },
    ],
  },
];

export default menuItems;
