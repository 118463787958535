/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import Select from "react-select";
import { loadingService } from "../../helper/observer";

const OpdList = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [AppointmentList, setAppointmentList] = useState([]);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);

  const handleDelete = async () => {
    // Implement your delete logic here using deleteId

    loadingService.next(true);

    const { data, status } = await AuthApi.get(`${Api.movetoipd}/${show}`);
    loadingService.next(false);

    if (status != 200) {
      toast.success("Patient successfully moved to IPD");
      setShow(false);
      getAppointmentList(userData);
      if (status == 401) navigate("/login");
      return;
    }
  };

  const outsidePuneAppointments = AppointmentList.filter(
    (appointment) => appointment.outside_pune === "Yes"
  );

  const withinPuneAppointments = AppointmentList.filter(
    (appointment) => appointment.outside_pune === "No"
  );

  // outsidePuneAppointments.sort((a, b) => {
  //   return new Date(b.appointment_date) - new Date(a.appointment_date);
  // });

  // withinPuneAppointments.sort((a, b) => {
  //   return new Date(a.appointment_date) - new Date(b.appointment_date);
  // });

  const sortedAppointments = [
    ...outsidePuneAppointments,
    ...withinPuneAppointments,
  ];

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "OPD Id",
      dataIndex: "opd_id",
      sorter: (a, b) => a.opd_id.length - b.opd_id.length,
    },
    {
      title: "Patient",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.length - b.patient_name.length,
    },
    {
      title: "Doctor",
      dataIndex: "doctor_name",
      sorter: (a, b) => a.doctor_name.length - b.doctor_name.length,
    },

    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      sorter: (a, b) => a.mobile_number.length - b.mobile_number.length,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => a.gender.length - b.gender.length,
    },
    {
      title: "OPD Type",
      dataIndex: "opd_type",
      sorter: (a, b) => a.opd_type.length - b.opd_type.length,
    },

    {
      title: "Charge Type",
      dataIndex: "charge_type",
      sorter: (a, b) => a.charge_type.length - b.charge_type.length,
    },
    {
      title: "Appointment Date",
      dataIndex: "appoitment_date",
      sorter: (a, b) => a.appointment_date.length - b.appointment_date.length,
      render: (text, record) => {
        // Convert the date of birth to a Date object
        const dob = new Date(record.appointment_date);

        // Define an array to map month numbers to their respective names
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // Format the date in the desired format
        const formattedDate = `${dob.getDate()}-${
          monthNames[dob.getMonth()]
        }-${dob.getFullYear()}`;

        // Return the formatted date
        return formattedDate;
      },
    },
    {
      title: "At",
      dataIndex: "appointment_from",
      sorter: (a, b) => a.appointment_from.length - b.appointment_from.length,
    },
    {
      title: "Outside Pune",
      dataIndex: "outside_pune",
      sorter: (a, b) => a.outside_pune.length - b.outside_pune.length,
    },

    // {
    //   title: "Branch Id",
    //   dataIndex: "branch_id",
    //   sorter: (a, b) => a.branch_id.length - b.branch_id.length,
    // },
    {
      title: "Actions",
      dataIndex: null, // You can keep this null if it's just for the action
      render: (text, record) => {
        return (
          <>

              <a
                className="badge bg-danger-light"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the row click event
                  navigate("/opdbill")
                  
                }}
              >
                OPD Bill
              </a>
            {/* <br></br>
            <br></br> */}
            {/* <a
              className="badge bg-primary-light"
              
              >
              Health Chart
            </a> */}
            <br></br>
            <br></br>
              <a
                className="badge bg-success-light"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/opdprocedure")
                  
                }}
                >
                Procedure
              </a>
          </>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "id",
      render: (text, record) => (
        <>
          <div className="text-end" onClick={(e) => e.stopPropagation()}>
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <Link className="dropdown-item" to={`/infoappointment/${record.id}`}>
                      <i className="far fa-file me-2" />
                      Info
                    </Link> */}
                <Link className="dropdown-item" to=""><i className="far fa-file me-2" />History</Link>
                <Link className="dropdown-item" to=""><i className="far fa-file me-2" />LAB BILL</Link>
                <Link className="dropdown-item" to=""><i className="far fa-file me-2" />Appointment</Link>
                <Link className="dropdown-item" to=""><i className="far fa-file me-2" />Medicine Sale</Link>
                <Link className="dropdown-item" to=""><i className="far fa-file me-2" />Estimate</Link>
                <Link className="dropdown-item" to=""><i className="far fa-file me-2" />Vaccination</Link>
                <Link className="dropdown-item" to=""><i className="far fa-file me-2" />OPD Bill</Link>
                <Link className="dropdown-item" to=""><i className="far fa-file me-2" />Prescription</Link>
                <Link className="dropdown-item" to=""><i className="far fa-file me-2" />Combined Bill</Link>
                {/* <Link className="dropdown-item" to={`/editopd/${record.id}`}>
                      <i className="far fa-edit me-2" />
                      Edit
                    </Link> */}
                {record.is_movetoipd == 1 ? (
                  <Link className="dropdown-item" disabled>
                    <i className="fa fa-trash-alt m-r-5"></i>Moved To IPD
                  </Link>
                ) : (
                  <Link
                    className="dropdown-item"
                    onClick={() => setShow(record.id)}
                  >
                    <i className="fa fa-trash-alt m-r-5"></i>Move To IPD
                  </Link>
                )}
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  const getAppointmentList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.opdList;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchopdList}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchopdList}/${user.branch_id}`;
    } else {
      url = `${Api.branchopdList}/${user.branch_id}`;
    }
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setAppointmentList(data);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    setUserData(data.data);
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getAppointmentList(userData);
    }
  }, [userData]);

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">OPD </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">OPD List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>OPD List</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                            {/* <div className="add-group">
                              <Link
                                to="/addappoinments"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2"></Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: sortedAppointments.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={sortedAppointments}
                      rowKey={(record) => record.id}
                      rowClassName={(record) =>
                        record.outside_pune == "Yes"
                          ? "green-row clickable-row"
                          : "clickable-row"
                      }
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: () => {
                            // Redirect to the info page when the row is clicked
                            navigate(`/addopdslave/${record.id}`);
                          },
                        };
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure want to Move this Patient To IPD ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imagesend} alt="#" width={50} height={46} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white me-2"
            variant="secondary"
            onClick={() => setShow(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="btn btn-danger"
            onClick={handleDelete}
          >
            Move
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OpdList;
