/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";
import Select from "react-select";
import html2pdf from "html2pdf.js";

const ActionList = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [AppointmentList, setAppointmentList] = useState([]);
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");

  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [paymentmethod, setPaymentmethod] = useState(false);
  const [paymentoption, setPaymentoption] = useState([
    { value: "", label: "No Charge" },
    { value: "1", label: "Cash" },
    { value: "2", label: "Credit Card/Debit Card" },
    { value: "3", label: "Net Banking" },
    { value: "4", label: "UPI(Online)" },
  ]);
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("NA");
  const [patientName, setPatientName] = useState("");
  const [OpdType, setOpdType] = useState("");

  const handleDelete = async () => {
    // Implement your delete logic here using deleteId

    loadingService.next(true);

    const { data, status } = await AuthApi.delete(
      `${Api.appointmentDelete}/${show}`
    );
    loadingService.next(false);

    if (status != 200) {
      toast.success("Deleted Successfully");
      setShow(false);
      getAppointmentList(userData);
      if (status == 401) navigate("/login");
      return;
    }
    console.log("Deleting patient with id:", show);
  };

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Working Days",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.length - b.patient_name.length,
    },
    {
      title: "Working Hours",
      dataIndex: "doctor_name",
      sorter: (a, b) => a.doctor_name.length - b.doctor_name.length,
    },

    {
      title: "Rest Hours",
      dataIndex: "mobile_number",
      sorter: (a, b) => a.mobile_number.length - b.mobile_number.length,
    },
    
    // {
    //   title: "Actions",
    //   dataIndex: null, // You can keep this null if it's just for the action
    //   render: (text, record) => {
    //     return (
    //       <>
    //         {record.status == 1 ? (
    //           <a
    //             className="badge bg-danger-light"
    //             onClick={(e) => {
    //               e.stopPropagation(); // Prevent the row click event
    //               setShowPayment(record.id); // Only trigger when amount is empty or null
    //               setPatientName(record.patient_name);
    //               setOpdType(record.opd_type);
    //             }}
    //           >
    //             Take Payment
    //           </a>
    //         ) : (
    //           <a
    //             className="badge bg-success-light"
    //             onClick={(e) => {
    //               e.stopPropagation(); // Prevent the row click event, but don't open payment modal
    //             }}
    //           >
    //             Payment Done
    //           </a>
    //         )}
    //         <br></br>
    //         <br></br>
    //         <a
    //           className="badge bg-primary-light"
    //           onClick={(e) => {
    //             sendPrescription(record.id); // Only trigger when amount is empty or null
    //           }}
    //         >
    //           Send Prescription
    //         </a>
    //         <br></br>
    //         <br></br>
    //         {record.status == 0 && (
    //           <a
    //             className="badge bg-success-light"
    //             onClick={(e) => {
    //               sendReceipt(record.id); // Only trigger when amount is empty or null
    //             }}
    //           >
    //             Send Receipt
    //           </a>
    //         )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "",
    //   dataIndex: null, // You can keep this null if it's just for the action
    //   render: (text, record) => {
    //     return (
    //       <>
    //         <a
    //           className="badge bg-primary-light"
    //           onClick={(e) => {
    //             sendPrescription(record.appointment_id); // Only trigger when amount is empty or null
    //           }}
    //         >
    //           Send Prescription
    //         </a>
    //       </>
    //     );
    //   },
    // },

    {
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <Link className="dropdown-item" to={`/infoappointment/${record.id}`}>
                      <i className="far fa-file me-2" />
                      Info
                    </Link> */}
                <Link
                  className="dropdown-item"
                  to={`/editappointmentlist/${record.id}`}
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </Link>
                <Link
                  className="dropdown-item"
                  onClick={() => setShow(record.id)}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  const getAppointmentList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.appointmentList;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchappointmentList}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchappointmentList}/${user.branch_id}`;
    } else {
      url = `${Api.branchappointmentList}/${user.branch_id}`;
    }
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setAppointmentList(data);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    setUserData(data.data);
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getAppointmentList(userData);
    }
  }, [userData]);

  const outsidePuneAppointments = AppointmentList.filter(
    (appointment) => appointment.outside_pune === "Yes"
  );

  const withinPuneAppointments = AppointmentList.filter(
    (appointment) => appointment.outside_pune === "No"
  );

  // outsidePuneAppointments.sort((a, b) => {
  //   return new Date(b.appointment_date) - new Date(a.appointment_date);
  // });

  // withinPuneAppointments.sort((a, b) => {
  //   return new Date(a.appointment_date) - new Date(b.appointment_date);
  // });

  const sortedAppointments = [
    ...outsidePuneAppointments,
    ...withinPuneAppointments,
  ];

  const handleSavePayment = async () => {
    // Generate the PDF
    const pdfBlob = await generatePDF();
    const pdfFile = new Blob([pdfBlob], { type: "application/pdf" });
    // Create a FormData object
    let formData = new FormData();

    // Append fields to form data
    formData.append("paymentmethod", paymentmethod.label);
    formData.append("amount", amount);
    formData.append("transactionId", transactionId);
    formData.append("file", pdfFile, "Payment Receipt.pdf");

    loadingService.next(true);

    try {
      const { data, message } = await AuthApi.post(
        `${Api.addappointpayment}/${showPayment}`,
        formData, // Pass form data instead of JSON payload
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the correct content type for form data
          },
        }
      );
      loadingService.next(false);

      if (message === "Appointment payment updated successfully.") {
        toast.success("Added Successfully");
        setShowPayment(false);
        getAppointmentList(userData);
        if (status === 401) navigate("/login");
        return;
      }
    } catch (error) {
      loadingService.next(false);
      console.error("Error updating payment:", error);
    }
  };

  const generatePDF = async () => {
    try {
      const printButtons = document.querySelectorAll(".receipthide");
      printButtons.forEach((button) => {
        button.style.display = "block";
      });

      const content = document.getElementById("pdf-content");
      const pdfOptions = {
        margin: [5, 5, 5, 5],
        filename: "Payment Receipt.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1.5 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { mode: ["avoid-all", "css"] },
      };

      const pdfBlob = await html2pdf()
        .set(pdfOptions)
        .from(content)
        .outputPdf("blob", "Payment Receipt.pdf");

      printButtons.forEach((button) => {
        button.style.display = "none";
      });

      return pdfBlob;
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Error generating PDF!");
    }
  };

  const sendPrescription = async (id) => {
    let url;

    url = `${Api.sendprescriptionlink}/${id}`;

    loadingService.next(true);
    const response = await AuthApi.get(url);
    loadingService.next(false);

    getAppointmentList(userData);
    if (response.data.status_code == 404) {
      toast.error(response.data.detail);
    } else if (response.data.response.status == "success") {
      toast.success("Send Successfully");
    }
  };

  const sendReceipt = async (id) => {
    let url;

    url = `${Api.sendreceiptlink}/${id}`;

    loadingService.next(true);
    const response = await AuthApi.get(url);
    loadingService.next(false);

    getAppointmentList(userData);
    if (response.status) {
      toast.success("Send Successfully");
    } else {
      toast.error("Not Sent");
    }
  };

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Appointments </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Doctor Details</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="card-title">Appointments Dashboard</h6>
                      <ul className="nav nav-tabs nav-tabs-bottom">
                        <li className="nav-item">
                          <Link
                            to="/appoinmentlist"
                            className={
                              window.location.pathname === "/appoinmentlist"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Appointments
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/actionlist"
                            className={
                              window.location.pathname === "/actionlist"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Action
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col ">
                        <div className="doctor-table-blk">
                          <h3>Doctor Details</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                            <div className="add-group">
                              <Link
                                to="/addappoinments"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2"></Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div>
                    </div>
                    {/* <!-- Filters Row --> */}
                    <div className="row align-items-center my-3">
                      <div className="col-md-3">
                        <label> Doctor Name</label>
                        <select className="form-select">
                          <option>Select</option>
                          {/* <!-- Doctor names options --> */}
                        </select>
                      </div>

                      <div className="col-md-2">
                        <label>Status</label>
                        <select className="form-select">
                          <option>Select</option>
                          {/* <!-- Appointment types options --> */}
                        </select>
                      </div>
                      <div className="col-md-2">
                        <label>Appointment Time( Min.)</label>
                        <select className="form-select">
                          <option>Select</option>
                          {/* <!-- Appointment types options --> */}
                        </select>
                      </div>
                      <div className="col-md-2">
                        <label>Chart Display Slot Time ( Min.)</label>
                        <select className="form-select">
                          <option>Select</option>
                          {/* <!-- Appointment types options --> */}
                        </select>
                      </div>
                      {/* <!-- Filter and Reset Buttons --> */}
                      <div className="col-md-3 d-flex  pt-3 doctor-submit">
                        <button
                          className="btn btn-primary me-2"
                          id="filter-btn"
                        >
                          Filter
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary cancel-form"
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: sortedAppointments.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={sortedAppointments}
                      rowKey={(record) => record.id}
                      rowClassName={(record) =>
                        record.outside_pune == "Yes" ? "green-row" : ""
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure want to delete this ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imagesend} alt="#" width={50} height={46} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white me-2"
            variant="secondary"
            onClick={() => setShow(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="btn btn-danger"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure want to delete this ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imagesend} alt="#" width={50} height={46} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white me-2"
            variant="secondary"
            onClick={() => setShow(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="btn btn-danger"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPayment} onHide={() => setShowPayment(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Take A Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 col-md-12 col-xl-12">
            <div className="col-12 col-md-12 col-xl-12">
              <div className="form-group local-forms">
                <label>
                  OPD Type
                  <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={OpdType}
                  disabled
                />
              </div>
            </div>
            <div className="form-group local-forms">
              <label>
                Payment Method
                <span className="login-danger">*</span>
              </label>
              <Select
                defaultValue={paymentmethod}
                onChange={setPaymentmethod}
                options={paymentoption}
                menuPortalTarget={document.body}
                id="search-commodity"
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "none"
                      : "2px solid rgba(46, 55, 164, 0.1);",
                    boxShadow: state.isFocused ? "0 0 0 1px #2e37a4" : "none",
                    "&:hover": {
                      borderColor: state.isFocused
                        ? "none"
                        : "2px solid rgba(46, 55, 164, 0.1)",
                    },
                    borderRadius: "10px",
                    fontSize: "14px",
                    minHeight: "45px",
                  }),
                  dropdownIndicator: (base, state) => ({
                    ...base,
                    transform: state.selectProps.menuIsOpen
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "250ms",
                    width: "35px",
                    height: "35px",
                  }),
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-xl-12">
            <div className="form-group local-forms">
              <label>
                Amount
                <span className="login-danger">*</span>
              </label>
              <input
                className="form-control"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-xl-12">
            <div className="form-group local-forms">
              <label>
                Transaction Id
                <span className="login-danger"></span>
              </label>
              <input
                className="form-control"
                type="text"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                placeholder="Put Your Transaction Id Here"
              />
            </div>
          </div>
          <div className="receipthide" id="pdf-content">
            {/* Header Section */}
            <div className="d-flex justify-content-center mb-4">
              <div className="text-center">
                <h2 className="mb-0">Payment Receipt</h2>
              </div>
            </div>
            <hr></hr>
            <div className="d-flex justify-content-center mb-4">
              <div className="text-center">
                <h2 className="mb-0">
                  SABALE&apos;S SUPER SPECIALITY HOSPITAL
                </h2>
                <p className="mb-0">
                  Sai Venkata Tread Center, Pune-Nashik Highway, Bhosari, Pune
                  411039
                </p>
                <p className="mb-0">
                  Mobile: 9011053954/9881255159 | Email:
                  sabalehospital2020@gmail.com
                </p>
              </div>
            </div>
            <hr></hr>
            {/* Payment Receipt Details */}
            <div className="col-12">
              <div className="form-group">
                <label>
                  <strong>OPD Type:</strong>
                </label>
                <p>{OpdType}</p> {/* Display payment method value */}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>
                  <strong>Patient Name:</strong>
                </label>
                <p>{patientName}</p> {/* Display payment method value */}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>
                  <strong>Payment Method:</strong>
                </label>
                <p>{paymentmethod.label ? paymentmethod.label : ""}</p>{" "}
                {/* Display payment method value */}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>
                  <strong>Amount:</strong>
                </label>
                <p>₹ {amount}</p> {/* Display amount value */}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>
                  <strong>Transaction ID:</strong>
                </label>
                <p>{transactionId}</p> {/* Display transaction ID value */}
              </div>
            </div>
            <hr></hr>
            {/* Footer Section */}
            <div className="d-flex justify-content-center print-hide mt-4">
              <div className="text-center">
                <p className="mb-0">
                  Mobile: 9011053954/9881255159 | Email:
                  sabalehospital2020@gmail.com
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowPayment(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={handleSavePayment}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActionList;
