import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";

const opdbill = () => {
  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">OPD </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">OPD Bill</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            {/* First Form */}
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-10">
                        <div className="form-heading">
                          <h4>Billing Details</h4>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Receipt No. <span className="login-danger">*</span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Bill Date <span className="login-danger"></span>
                          </label>
                          <DatePicker
                            className="form-control datetimepicker"
                            format="DD-MMM-YYYY"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Doctor <span className="login-danger">*</span>
                          </label>
                          <select className="form-control select">
                            <option>Select Doctor</option>
                            <option>Dr Rahul</option>
                            <option>Dr Shubham</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            OPD Type <span className="login-danger">*</span>
                          </label>
                          <select className="form-control select">
                            <option>Select</option>
                            <option>Follow Up</option>
                            <option>Consultation</option>
                            <option>Dressing</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            OPD Sub Type <span className="login-danger">*</span>
                          </label>
                          <select className="form-control select">
                            <option>Select</option>
                            <option>NA</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Charge Type <span className="login-danger">*</span>
                          </label>
                          <select className="form-control select">
                            <option>Select</option>
                            <option>First Visit</option>
                            <option>Free</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Diagnosis <span className="login-danger">*</span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Remark <span className="login-danger">*</span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                    </div>

                    {/* Form submission buttons */}
                    {/* <div className="col-12 col-md-6 col-xl-12">
                      <div className="doctor-submit text-end">
                        <button
                          type="submit"
                          className="btn btn-primary submit-form me-2"
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary cancel-form"
                        >
                          Cancel
                        </button>
                      </div>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>

            {/* Second Form */}
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-10">
                        <div className="form-heading">
                          <h4>OPD Detail</h4>
                        </div>
                      </div>
                      {/* Copy form elements here (similar to the first form) */}
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Total Amount <span className="login-danger">*</span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                          Discount <span className="login-danger">*</span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-12">
                            <div className="form-group local-forms">
                            <label>
                            Payable Amount <span className="login-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Pay Mode</h4>
                                            <ul className="nav nav-tabs nav-tabs-solid nav-justified">
                                                <li className="nav-item"><Link className="nav-link active" to="#solid-justified-tab1" data-bs-toggle="tab">Cash</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to="#solid-justified-tab2" data-bs-toggle="tab">Bank</Link></li>
                                                <li className="nav-item"><Link className="nav-link" to="#solid-justified-tab3" data-bs-toggle="tab">Card</Link></li>
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="solid-justified-tab1">
                                                    <div className="col-12 col-md-6 col-xl-12">
                                                        <div className="form-group local-forms">
                                                            <div className="col-md-10">
                                                                <div className="input-group mb-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="800"
                                                                    aria-label="Recipient's username"
                                                                    aria-describedby="basic-addon2"
                                                                />
                                                                <span className="input-group-text" id="basic-addon2">
                                                                    INR
                                                                </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-12">
                                                        <div className="form-group local-forms">
                                                        <label>
                                                        Received Amount <span className="login-danger">*</span>
                                                        </label>
                                                        <div className="col-md-10">
                                                            <div className="input-group mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="800"
                                                                aria-label="Recipient's username"
                                                                aria-describedby="basic-addon2"
                                                            />
                                                            <span className="input-group-text" id="basic-addon2">
                                                                INR
                                                            </span>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-12">
                                                        <div className="form-group local-forms">
                                                        <label>
                                                        Balanced Amount <span className="login-danger">*</span>
                                                        </label>
                                                        <div className="col-md-10">
                                                            <div className="input-group mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="800"
                                                                aria-label="Recipient's username"
                                                                aria-describedby="basic-addon2"
                                                            />
                                                            <span className="input-group-text" id="basic-addon2">
                                                                INR
                                                            </span>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="solid-justified-tab2">
                                                    <div className="col-12 col-md-6 col-xl-12">
                                                        <div className="form-group local-forms">
                                                            <div className="col-md-10">
                                                                <div className="input-group mb-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="800"
                                                                    aria-label="Recipient's username"
                                                                    aria-describedby="basic-addon2"
                                                                />
                                                                <span className="input-group-text" id="basic-addon2">
                                                                    INR
                                                                </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-10">
                                                        <div className="form-group local-forms">
                                                        <label>
                                                            Transaction Type <span className="login-danger">*</span>
                                                        </label>
                                                        <select className="form-control select">
                                                            <option>-</option>
                                                            <option>-</option>
                                                            <option>-</option>
                                                        </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-10">
                                                        <div className="form-group local-forms">
                                                        <label>
                                                            Trans No./Cheque No <span className="login-danger">*</span>
                                                        </label>
                                                        <input className="form-control" type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-10">
                                                        <div className="form-group local-forms">
                                                        <label>
                                                            Date <span className="login-danger"></span>
                                                        </label>
                                                        <DatePicker
                                                            className="form-control datetimepicker"
                                                            format="DD-MMM-YYYY"
                                                        />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-10">
                                                        <div className="form-group local-forms">
                                                        <label>
                                                            Bank Name <span className="login-danger">*</span>
                                                        </label>
                                                        <select className="form-control select">
                                                            <option>Select Bank</option>
                                                            <option>SBI</option>
                                                            <option>Bank of India</option>
                                                        </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-10">
                                                        <div className="form-group local-forms">
                                                        <label>
                                                            Remark <span className="login-danger">*</span>
                                                        </label>
                                                        <input className="form-control" type="text" />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-12 col-md-6 col-xl-12">
                                                        <div className="form-group local-forms">
                                                        <label>
                                                        Received Amount <span className="login-danger">*</span>
                                                        </label>
                                                        <div className="col-md-10">
                                                            <div className="input-group mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="800"
                                                                aria-label="Recipient's username"
                                                                aria-describedby="basic-addon2"
                                                            />
                                                            <span className="input-group-text" id="basic-addon2">
                                                                INR
                                                            </span>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-12">
                                                        <div className="form-group local-forms">
                                                        <label>
                                                        Balanced Amount <span className="login-danger">*</span>
                                                        </label>
                                                        <div className="col-md-10">
                                                            <div className="input-group mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="800"
                                                                aria-label="Recipient's username"
                                                                aria-describedby="basic-addon2"
                                                            />
                                                            <span className="input-group-text" id="basic-addon2">
                                                                INR
                                                            </span>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="solid-justified-tab3">
                                                    <div className="col-12 col-md-6 col-xl-12">
                                                        <div className="form-group local-forms">
                                                            <div className="col-md-10">
                                                                <div className="input-group mb-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="800"
                                                                    aria-label="Recipient's username"
                                                                    aria-describedby="basic-addon2"
                                                                />
                                                                <span className="input-group-text" id="basic-addon2">
                                                                    INR
                                                                </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-10">
                                                        <div className="form-group local-forms">
                                                        <label>
                                                            Trans No./Cheque No <span className="login-danger">*</span>
                                                        </label>
                                                        <input className="form-control" type="text" />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-12 col-md-6 col-xl-10">
                                                        <div className="form-group local-forms">
                                                        <label>
                                                            Bank Name <span className="login-danger">*</span>
                                                        </label>
                                                        <select className="form-control select">
                                                            <option>Select Bank</option>
                                                            <option>SBI</option>
                                                            <option>Bank of India</option>
                                                        </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-10">
                                                        <div className="form-group local-forms">
                                                        <label>
                                                            Remark <span className="login-danger">*</span>
                                                        </label>
                                                        <input className="form-control" type="text" />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-12 col-md-6 col-xl-12">
                                                        <div className="form-group local-forms">
                                                        <label>
                                                        Received Amount <span className="login-danger">*</span>
                                                        </label>
                                                        <div className="col-md-10">
                                                            <div className="input-group mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="800"
                                                                aria-label="Recipient's username"
                                                                aria-describedby="basic-addon2"
                                                            />
                                                            <span className="input-group-text" id="basic-addon2">
                                                                INR
                                                            </span>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 col-xl-12">
                                                        <div className="form-group local-forms">
                                                        <label>
                                                        Balanced Amount <span className="login-danger">*</span>
                                                        </label>
                                                        <div className="col-md-10">
                                                            <div className="input-group mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="800"
                                                                aria-label="Recipient's username"
                                                                aria-describedby="basic-addon2"
                                                            />
                                                            <span className="input-group-text" id="basic-addon2">
                                                                INR
                                                            </span>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>

                   

                    {/* Form submission buttons */}
                    <div className="col-12">
                      <div className="doctor-submit text-end">
                        <button
                          type="submit"
                          className="btn btn-primary submit-form me-2"
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary cancel-form"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default opdbill;
