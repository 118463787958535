import React from "react";

class Api extends React.Component {
  // static baseUrl = "http://localhost:8000";
  static baseUrl = 'https://sable-hsptl-stg-api.demoadmsoft.in/api'; // Adjusted base URL to include "/api"

  static loginUrl = `${this.baseUrl}/login`;
  static register = `${this.baseUrl}/register`;
  static forgetpassword = `${this.baseUrl}/forgetpassword`;
  
  // patient
  static patientAdd = `${this.baseUrl}/patientAdd`;
  static patientList = `${this.baseUrl}/patientList`;
  static patientUpdate = `${this.baseUrl}/patientUpdate`;
  static patientDelete = `${this.baseUrl}/patientDelete`;
  static PatientInfo = `${this.baseUrl}/PatientInfo`;

  // doctor
  static doctorAdd = `${this.baseUrl}/doctorAdd`;
  static doctorList = `${this.baseUrl}/doctorList`;
  static doctorUpdate = `${this.baseUrl}/doctorUpdate`;
  static doctorDelete = `${this.baseUrl}/doctorDelete`;
  static doctorInfo = `${this.baseUrl}/doctorInfo`;

  // appointment
  static appointmentAdd = `${this.baseUrl}/appointmentAdd`;
  static appointmentList = `${this.baseUrl}/appointmentList`;
  static appointmentDelete = `${this.baseUrl}/appointmentDelete`;
  static assignmentEdit = `${this.baseUrl}/assignmentEdit`;
  static updateAssignment = `${this.baseUrl}/updateAssignment`;
  static assignmentInfo = `${this.baseUrl}/assignmentInfo`;

  // opd
  static opdList = `${this.baseUrl}/opdList`;
  static addopdvisitslave = `${this.baseUrl}/addopdvisitslave`;
  static updateopdvisitslave = `${this.baseUrl}/updateopdvisitslave`;
  static updateopdprescriptionlave = `${this.baseUrl}/updateopdprescriptionlave`;

  // medicine
  static medicineList = `${this.baseUrl}/medicineList`;
  static deleteMedicine = `${this.baseUrl}/deleteMedicine`;
  static medicineAdd = `${this.baseUrl}/medicineAdd`;
  static medicineInfo = `${this.baseUrl}/medicineInfo`;
  static updateMedicine = `${this.baseUrl}/updateMedicine`;

  // test master
  static testList = `${this.baseUrl}/testList`;
  static deleteTest = `${this.baseUrl}/deleteTest`;
  static testAdd = `${this.baseUrl}/testAdd`;
  static testInfo = `${this.baseUrl}/testInfo`;
  static updateTest = `${this.baseUrl}/updateTest`;

  // test master
  static addPrescription = `${this.baseUrl}/addPrescription`;

  // hospital
  static hospitallist = `${this.baseUrl}/hospitallist`;
  static deletehospital = `${this.baseUrl}/deletehospital`;
  static hospitalAdd = `${this.baseUrl}/hospitalAdd`;
  static allsubbranches = `${this.baseUrl}/allsubbranches`;

  static branchpatientList = `${this.baseUrl}/branchpatientList`;
  static branchhospitallist = `${this.baseUrl}/branchhospitallist`;
  static branchappointmentList = `${this.baseUrl}/branchappointmentList`;
  static branchopdList = `${this.baseUrl}/branchopdList`;
  static branchmedicineList = `${this.baseUrl}/branchmedicineList`;
  static branchtestList = `${this.baseUrl}/branchtestList`;
  static branchdoctorList = `${this.baseUrl}/branchdoctorList`;
  static branchtpapanelList = `${this.baseUrl}/branchtpapanelList`;


  static receptionistDelete = `${this.baseUrl}/receptionistDelete`;
  static branchreceptionistList = `${this.baseUrl}/branchreceptionistList`;
  static receptionistList = `${this.baseUrl}/receptionistList`;
  static receptionistAdd = `${this.baseUrl}/receptionistAdd`;
  static receptionistInfo = `${this.baseUrl}/receptionistInfo`;
  static receptionistUpdate = `${this.baseUrl}/receptionistUpdate`;
  
  
  static opdslaveInfo = `${this.baseUrl}/opdslaveInfo`;
  static patientprescriptionList = `${this.baseUrl}/patientprescriptionList`;
  static opdslaveedit = `${this.baseUrl}/opdslaveedit`;
  static prescriptionpatientInfo = `${this.baseUrl}/prescriptionpatientInfo`;


  static testcategoryList = `${this.baseUrl}/testcategoryList`;
  static branchtestcategoryList = `${this.baseUrl}/branchtestcategoryList`;
  static deletetestcategory = `${this.baseUrl}/deletetestcategory`;
  static testcategoryAdd = `${this.baseUrl}/testcategoryadd`;
  static updateTestCategory = `${this.baseUrl}/updatetestcategory`;
  static testcategoryinfo = `${this.baseUrl}/testcategoryinfo`;

  static addappointpayment = `${this.baseUrl}/addappointpayment`;
  static paymentreceiptlist = `${this.baseUrl}/paymentreceiptlist`;
  static branchpaymentreceiptlist = `${this.baseUrl}/branchpaymentreceiptlist`;
  static savepdfprescription = `${this.baseUrl}/savepdfprescription`;
  static sendprescriptionlink = `${this.baseUrl}/sendprescriptionlink`;
  static sendreceiptlink = `${this.baseUrl}/sendreceiptlink`;
  
  
  // precripton tempplate 
  static addprecriptiontemplate = `${this.baseUrl}/addprecriptiontemplate`;
  static precriptiontemplateinfo = `${this.baseUrl}/precriptiontemplateinfo`;
  static updateprecriptiontemplate = `${this.baseUrl}/updateprecriptiontemplate`;
  static precriptiontemplatelist = `${this.baseUrl}/precriptiontemplatelist`;
  static branchprecriptiontemplatelist = `${this.baseUrl}/branchprecriptiontemplatelist`;
  static deleteprecriptiontemplate = `${this.baseUrl}/deleteprecriptiontemplate`;
  
  // IPD
  static movetoipd = `${this.baseUrl}/movetoipd`;
  static ipdlist = `${this.baseUrl}/ipdlist`;
  static branchipdlist = `${this.baseUrl}/branchipdlist`;
  static ipdformadd = `${this.baseUrl}/ipdformadd`;
  static ipdformfirstinfo = `${this.baseUrl}/ipdformfirstinfo`;
  static ipdformsecondadd = `${this.baseUrl}/ipdformsecondadd`;
  static ipdrecordlist = `${this.baseUrl}/ipdrecordlist`;
  static ipdnursingrecordlist = `${this.baseUrl}/ipdnursingrecordlist`;
  static ipdformsecondnursingchartadd = `${this.baseUrl}/ipdformsecondnursingchartadd`;
  static ipdmainlist = `${this.baseUrl}/ipdmainlist`;
  static branchmainipdlist = `${this.baseUrl}/branchmainipdlist`;
  
  // nursingchart update 
  static updatetime_9am = `${this.baseUrl}/updatetime_9am`;
  static updatetime_3pm = `${this.baseUrl}/updatetime_3pm`;
  static updatetime_9pm = `${this.baseUrl}/updatetime_9pm`;
  static updatepulsevalue = `${this.baseUrl}/updatepulsevalue`;
  static updatebpvalue = `${this.baseUrl}/updatebpvalue`;
  static updatetempvalue = `${this.baseUrl}/updatetempvalue`;
  static updateurinevalue = `${this.baseUrl}/updateurinevalue`;

  // bed 
  static deletebedcategory = `${this.baseUrl}/deletebedcategory`;
  static bedcategorylist = `${this.baseUrl}/bedcategorylist`;
  static branchbedcategorylist = `${this.baseUrl}/branchbedcategorylist`;
  static bedcategoryadd = `${this.baseUrl}/bedcategoryadd`;
  static deletebed = `${this.baseUrl}/deletebed`;
  static bedlist = `${this.baseUrl}/bedlist`;
  static branchbedlist = `${this.baseUrl}/branchbedlist`;
  static bedadd = `${this.baseUrl}/bedadd`;
  static getbeddataipdfromfirst = `${this.baseUrl}/getbeddataipdfromfirst`;


  // discharge
  static requestfordischarge = `${this.baseUrl}/requestfordischarge`;
  static ipddischargereqlist = `${this.baseUrl}/ipddischargereqlist`;
  static branchipddischargereqlist = `${this.baseUrl}/branchipddischargereqlist`;
  static approvedischargeLab = `${this.baseUrl}/approvedischargeLab`;
  static approvedischargePharma = `${this.baseUrl}/approvedischargePharma`;
  static approvedischargeXray = `${this.baseUrl}/approvedischargeXray`;
  static approvedischargeSonography = `${this.baseUrl}/approvedischargeSonography`;
  
  // billing
  static ipdbillinglist = `${this.baseUrl}/ipdbillinglist`;
  static branchipdbillinglist = `${this.baseUrl}/branchipdbillinglist`;
  static sendbillreceiptlink = `${this.baseUrl}/sendbillreceiptlink`;
  static billingreceiptlist = `${this.baseUrl}/billingreceiptlist`;
  static branchbillingreceipt = `${this.baseUrl}/branchbillingreceipt`;
  
  // mastersetting
  static mastersettings = `${this.baseUrl}/mastersettings`;
  static getsettingdata = `${this.baseUrl}/getsettingdata`;

  // ipd payment
  static finalipdpayment = `${this.baseUrl}/finalipdpayment`

}

export default Api;
