import axios from 'axios';

const HttpService = {
  get: (url, headers = {}) => axios.get(url, { headers }),
  post: (url, data, headers = {}) => axios.post(url, data, { headers }),
  loginpost: (url, data) => axios.post(url, data),
  put: (url, data, headers = {}) => axios.put(url, data, { headers }),
  delete: (url, headers = {}) => axios.delete(url, { headers }),
};

export default HttpService;