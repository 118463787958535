/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import toast from "react-hot-toast";
import { DatePicker } from "antd";
import Select from "react-select";
import { loadingService } from "../../helper/observer";
import moment from "moment";


const EditAppointment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState();
  const [patientName, setPatientName] = useState();
  const [doctor, setDoctor] = useState(null);
  const [dateOfAppointment, setDateOfAppointment] = useState("");
  const [notes, setNotes] = useState("");
  const [PatientList, setPatientList] = useState([]);
  const [DoctorList, setDoctorList] = useState([]);
  const [userData, setUserData] = useState(null);

  const [opdType, setOpdType] = useState("");
  const [chargeType, setChargeType] = useState("");

  const getPatientList = async () => {
    const url = Api.patientList;
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setPatientList(data);
  };

  useEffect(() => {
    getPatientList();
    getOpdList();
  }, []);

  const getDoctorList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.doctorList;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchdoctorList}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchdoctorList}/${user.branch_id}`;
    } else {
      url = `${Api.branchdoctorList}/${user.branch_id}`;
    }
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setDoctorList(data);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);

    setUserData(data.data);
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getDoctorList(userData);
    }
  }, [userData]);

  const getOpdList = async () => {
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(
      `${Api.assignmentEdit}/${id}`
    );
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setStartTime(data.appointment_from);
    setPatientName(data.patient_name);
    setDoctor(data.doctor_name);
    setDateOfAppointment(data.appointment_date);
    setNotes(data.notes);
    setOpdType(data.opd_type);
    setChargeType(data.charge_type);
  };

  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!dateOfAppointment || !startTime || !patientName || !doctor) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      patient_name: patientName,
      doctor_name: doctor.toString(),
      appointment_date: dateOfAppointment,
      appointment_from: startTime,
      notes: notes,
      opd_type: opdType,
      charge_type: chargeType,
    };

    try {
      loadingService.next(true);

      const { status } = await AuthApi.post(
        `${Api.updateAssignment}/${id}`,
        payload
      );
      loadingService.next(false);

      if (status) {
        toast.success("Success");
        navigate("/appoinmentlist");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  const options = DoctorList.map((doctor) => ({
    value: doctor.id,
    label: `${doctor.first_name} ${doctor.last_name}`,
  }));

  const handleChange = (selectedOption) => {
    setDoctor(selectedOption ? selectedOption.value : null);
  };

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="add-appoinment"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Appointment </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Appointment</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Patient Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Doctor<span className="login-danger">*</span>
                            </label>
                            <Select
                              className="form-control-alternative"
                              options={options}
                              value={options.find(
                                (option) => option.value == doctor
                              )}
                              onChange={handleChange}
                              placeholder="Search And Select"
                              isSearchable
                              isClearable
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused
                                    ? "none"
                                    : "2px solid rgba(46, 55, 164, 0.1);",
                                  boxShadow: state.isFocused
                                    ? "0 0 0 1px #2e37a4"
                                    : "none",
                                  "&:hover": {
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1)",
                                  },
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen
                                    ? "rotate(-180deg)"
                                    : "rotate(0)",
                                  transition: "250ms",
                                  width: "35px",
                                  height: "35px",
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Patient<span className="login-danger">*</span>
                            </label>
                            <select
                              className="form-select"
                              value={patientName}
                              // onChange={(e) => setPatientName(e.target.value)}
                              placeholder="Select City"
                              disabled
                            >
                              <option value="">Select The Patient</option>
                              {PatientList.map((s, sIndex) => (
                                <option key={sIndex} value={s.id}>
                                  {s.first_name}&nbsp;{s.last_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              OPD Type <span className="login-danger">*</span>
                            </label>
                            <select
                              className="form-select"
                              value={opdType}
                              onChange={(e) => setOpdType(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="FOLLOWUP">FOLLOWUP</option>
                              <option value="CONSULTATION">CONSULTATION</option>
                              <option value="DRESSING">DRESSING</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Charge Type{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <select
                              className="form-select"
                              value={chargeType}
                              onChange={(e) => setChargeType(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="FIRST VISIT">FIRST VISIT</option>
                              <option value="FREE">FREE</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12">
                          <div className="form-group local-forms">
                            <label>
                              complaints <span className="login-danger">*</span>
                            </label>
                            <textarea
                              className="form-control"
                              rows={3}
                              cols={30}
                              value={notes}
                              onChange={(event) => {
                                setNotes(event.target.value);
                              }}
                              defaultValue={""}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Appointment Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Date Of Appointment{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <DatePicker
                              className="form-control datetimepicker"
                              format="DD-MMM-YYYY"
                              selected={dateOfAppointment}
                              onChange={(date) => setDateOfAppointment(moment(date).format("YYYY-MM-DD"))}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              At <span className="login-danger">*</span>
                            </label>
                            <div className="">
                              <input
                                className="form-control"
                                id="outlined-controlled"
                                type="time"
                                value={startTime}
                                onChange={(event) => {
                                  setStartTime(event.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                navigate("/appoinmentlist");
                              }}
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EditAppointment;
