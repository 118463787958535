import React, { useState, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import { loadingService } from "./../../helper/observer";

const Loader = () => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const loadingOberver = loadingService.subscribe((value) => {
      setShowLoader(value);
    });
    return () => {
      loadingOberver.unsubscribe();
    };
  }, []);

  return (
    <>
      {showLoader && (
        <div className="overlay">
          {" "}
          {/* Wrapper div for overlay */}
          <div className="loader-container">
            {" "}
            {/* Wrapper div for loader */}
            <ThreeDots
              height="80"
              width="80"
              color="#4e57cd"
              ariaLabel="loading"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
