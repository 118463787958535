/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import Select from "react-select";
import { loadingService } from "../../helper/observer";

const IpdRequest = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [AppointmentList, setAppointmentList] = useState([]);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);

  const handleDelete = async () => {
    // Implement your delete logic here using deleteId

    loadingService.next(true);

    const { data, status } = await AuthApi.delete(
      `${Api.appointmentDelete}/${show}`
    );
    loadingService.next(false);

    if (status != 200) {
      toast.success("Deleted Successfully");
      setShow(false);
      getAppointmentList(userData);
      if (status == 401) navigate("/login");
      return;
    }
    console.log("Deleting patient with id:", show);
  };

  const outsidePuneAppointments = AppointmentList.filter(
    (appointment) => appointment.outside_pune === "Yes"
  );

  const withinPuneAppointments = AppointmentList.filter(
    (appointment) => appointment.outside_pune === "No"
  );

  // outsidePuneAppointments.sort((a, b) => {
  //   return new Date(b.appointment_date) - new Date(a.appointment_date);
  // });

  // withinPuneAppointments.sort((a, b) => {
  //   return new Date(a.appointment_date) - new Date(b.appointment_date);
  // });

  const sortedAppointments = [
    ...outsidePuneAppointments,
    ...withinPuneAppointments,
  ];

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "IPD Id",
      dataIndex: "ipd_id",
      sorter: (a, b) => a.ipd_id.length - b.ipd_id.length,
    },
    {
      title: "Patient",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.length - b.patient_name.length,
    },
    // {
    //   title: "Doctor",
    //   dataIndex: "doctor_name",
    //   sorter: (a, b) => a.doctor_name.length - b.doctor_name.length,
    // },

    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      sorter: (a, b) => a.mobile_number.length - b.mobile_number.length,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => a.gender.length - b.gender.length,
    },
    // {
    //   title: "OPD Type",
    //   dataIndex: "opd_type",
    //   sorter: (a, b) => a.opd_type.length - b.opd_type.length,
    // },

    // {
    //   title: "Charge Type",
    //   dataIndex: "charge_type",
    //   sorter: (a, b) => a.charge_type.length - b.charge_type.length,
    // },
    {
      title: "Appointment Date",
      dataIndex: "appoitment_date",
      sorter: (a, b) => a.appointment_date.length - b.appointment_date.length,
      render: (text, record) => {
        // Convert the date of birth to a Date object
        const dob = new Date(record.appointment_date);

        // Define an array to map month numbers to their respective names
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // Format the date in the desired format
        const formattedDate = `${dob.getDate()}-${
          monthNames[dob.getMonth()]
        }-${dob.getFullYear()}`;

        // Return the formatted date
        return formattedDate;
      },
    },
    {
      title: "At",
      dataIndex: "appointment_from",
      sorter: (a, b) => a.appointment_from.length - b.appointment_from.length,
    },
    {
      title: "Outside Pune",
      dataIndex: "outside_pune",
      sorter: (a, b) => a.outside_pune.length - b.outside_pune.length,
    },

    // {
    //   title: "Branch Id",
    //   dataIndex: "branch_id",
    //   sorter: (a, b) => a.branch_id.length - b.branch_id.length,
    // },

    // {
    //   title: "",
    //   dataIndex: "id",
    //   render: (text, record) => (
    //     <>
    //       <div className="text-end">
    //         <div className="dropdown dropdown-action">
    //           <Link
    //             to="#"
    //             className="action-icon dropdown-toggle"
    //             data-bs-toggle="dropdown"
    //             aria-expanded="false"
    //           >
    //             <i className="fas fa-ellipsis-v" />
    //           </Link>
    //           <div className="dropdown-menu dropdown-menu-end">
    //             {/* <Link className="dropdown-item" to={`/infoappointment/${record.id}`}>
    //                   <i className="far fa-file me-2" />
    //                   Info
    //                 </Link> */}
    //                 <Link className="dropdown-item" to={`/editopd/${record.id}`}>
    //                   <i className="far fa-edit me-2" />
    //                   Edit
    //                 </Link>
    //             {/* <Link
    //               className="dropdown-item"
    //               onClick={() => setShow(record.id)}
    //             >
    //               <i className="fa fa-trash-alt m-r-5"></i> Delete
    //             </Link> */}
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   ),
    // },
  ];

  const getAppointmentList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.ipdlist;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchipdlist}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchipdlist}/${user.branch_id}`;
    } else {
      url = `${Api.branchipdlist}/${user.branch_id}`;
    }
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setAppointmentList(data);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    setUserData(data.data);
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getAppointmentList(userData);
    }
  }, [userData]);

  

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">IPD </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">IPD Request</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>IPD Request</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                            {/* <div className="add-group">
                              <Link
                                to="/addappoinments"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2"></Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: sortedAppointments.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={sortedAppointments}
                      rowKey={(record) => record.id}
                      rowClassName={(record) =>
                        record.outside_pune == "Yes"
                          ? "green-row clickable-row"
                          : "clickable-row"
                      }
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: () => {
                            // Redirect to the info page when the row is clicked
                            navigate(`/addipdfromfirst/${record.id}`);
                          },
                        };
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IpdRequest;
