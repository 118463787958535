import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import { useNavigate } from "react-router-dom";
import menuItems from "./menuItems";
import { dashboard, logout } from "./imagepath"; // Assuming 'dashboard' icon comes from 'imagepath'

const Sidebar = () => {
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState("");
  const [allMenuItems, setAllMenuItems] = useState([...menuItems]);
  const [userData, setUserData] = useState(null);

  const handleClick = (e, item) => {
    e.preventDefault();
    setSidebar(sidebar === item ? "" : item);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    if (!udata) {
      navigate("/login");
      return;
    }
    if (udata != null) {
      let data = JSON.parse(udata);
      setUserData(data.data);
    }
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      let mItems = menuItems.filter((m) =>
        m.allowedUser.includes(parseInt(userData.role))
      );
      setAllMenuItems([...mItems]);
    }
  }, [userData]);

  const logoutUser = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/login");
  };

  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };

  return (
    <>
      <div className="sidebar" id="sidebar">
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <div
            className="sidebar-inner slimscroll"
            onMouseLeave={expandMenu}
            onMouseOver={expandMenuOpen}
          >
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                <li>
                  <Link to="/admin-dashboard">
                    <span className="menu-side">
                      <img src={dashboard} alt="" />
                      {/* Icon for logout */}
                    </span>{" "}
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className="menu-title">Main</li>
                {allMenuItems.map((mitem, mindex) => (
                  <li key={mindex}>
                    {mitem.submenu ? (
                      <Link to="#" onClick={(e) => handleClick(e, mitem.name)}>
                        <span className="menu-side">
                          <img src={mitem.iconName} alt="" />
                        </span>{" "}
                        <span>{mitem.name}</span>{" "}
                        <span className="menu-arrow" />
                      </Link>
                    ) : (
                      <Link className="nav-link" to={mitem.link}>
                        <span className="menu-title">{mitem.name}</span>
                      </Link>
                    )}
                    {mitem.submenu && (
                      <ul
                        style={{
                          display: sidebar === mitem.name ? "block" : "none",
                        }}
                        className="menu-items"
                      >
                        {mitem.submenu.map((subitem, subindex) => (
                          <li key={subindex}>
                            <Link to={subitem.link}>{subitem.name}</Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
                {/* Logout Link */}
                <li>
                  <Link to="#" onClick={logoutUser}>
                    <span className="menu-side">
                      <img src={logout} alt="" />
                      {/* Icon for logout */}
                    </span>{" "}
                    <span>Logout</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default Sidebar;
