import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";



const trail_balance = () => {
  

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Voucher </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Profit & Loss</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="card">
              <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-body border">
                        <div className="col-12">
                        <div className="form-heading">
                            <h4 className="text-danger">
                            Profit & Loss
                            </h4>
                        </div>
                        <hr></hr>
                        <br></br>
                        <table className="table table-responsive">
                            <thead className="text-primary">
                            <tr>
                                <td>
                                <b></b>
                                </td>
                                <td>
                                <b></b>
                                </td>
                                <td>
                                <b></b>
                                </td>
                                <td>
                                <b></b>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Opening Stock</td>
                                <td></td>
                                <td>121351</td>
                                <td>24545</td>
                            </tr>
                            <tr>
                                <td>Purchase Account</td>
                                <td></td>
                                <td>112351</td>
                                <td>23564</td>
                            </tr>
                            <tr>
                                <td>Direct Expense</td>
                                <td></td>
                                <td>354564</td>
                                <td>354564</td>
                            </tr>
                            <tr>
                                <td>Gross Profit</td>
                                <td></td>
                                <td>354564</td>
                                <td>354564</td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
              <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                    <div className="card-body border">
                        <div className="col-12">
                        <div className="form-heading">
                            <h4 className="text-danger">
                            Profit & Loss
                            </h4>
                        </div>
                        <hr></hr>
                        <br></br>
                        <table className="table table-responsive">
                            <thead className="text-primary">
                            <tr>
                                <td>
                                <b></b>
                                </td>
                                <td>
                                <b></b>
                                </td>
                                <td>
                                <b></b>
                                </td>
                                <td>
                                <b></b>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Closing Stock</td>
                                <td></td>
                                <td>121351</td>
                                <td>24545</td>
                            </tr>
                            <tr>
                                <td>Sales Accounts</td>
                                <td></td>
                                <td>112351</td>
                                <td>23564</td>
                            </tr>
                            <tr>
                                <td>Direct Income</td>
                                <td></td>
                                <td>354564</td>
                                <td>354564</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default trail_balance;
