import {React , useState} from "react";
import { Link } from "react-router-dom";
import { login02, loginlogo } from "../../imagepath";
import { loginicon01 } from "../../imagepath";
import { loginicon02 } from "../../imagepath";
import { loginicon03 } from "../../imagepath";
import Api from "../../../helper/api";
import AuthApi from "../../../helper/authApi";
import { loadingService } from "../../../helper/observer";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const SubmitForm = async (e) => {
    e.preventDefault();

    // Check if any required field is empty
    if (!email) {
      toast.error("Email Required!");
      return;
    }

    const payload = {
      email: email,
    };

    try {
      loadingService.next(true);
      const { data } = await AuthApi.loginpost(`${Api.forgetpassword}`, payload);
      loadingService.next(false);

      if (data.detail == "User not found") {
        return toast.error("We can't find a user with that email address!");
      }
      if (data) {
        toast.success("Success!");
        navigate("/");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later!"
      );
    }
  };
  return (
    <div>
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="Logo" />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/admin-dashboard">
                          <img src={loginlogo} alt="#" />
                        </Link>
                      </div>
                      <h2>Reset Password</h2>
                      {/* Form */}
                      <form onSubmit={SubmitForm}>
                        <div className="form-group">
                          <label>
                            Email <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group login-btn">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Reset Password
                          </button>
                        </div>
                      </form>
                      {/* /Form */}
                      <div className="next-sign">
                        <p className="account-subtitle">
                          Need an account?
                          <Link to="/login"> Login</Link>
                        </p>
                        {/* Social Login */}
                        <div className="social-login">
                          <Link to="#">
                            <img src={loginicon01} alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={loginicon02} alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={loginicon03} alt="#" />
                          </Link>
                        </div>
                        {/* /Social Login */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
