/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";

import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
  imguser,
} from "../imagepath";
import { Link } from "react-router-dom";
import Api from "../../helper/api";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";
import AuthApi from "../../helper/authApi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";

const AddIpdFormSecond = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [PatientData, setPatientData] = useState([]);
  const [medicineList, setMedicineList] = useState([]);
  const [IpdRecordList, setIpdRecordList] = useState([]);
  const [IpdNursingRecordList, setIpdNursingRecordList] = useState([]);
  const [selectedMedicineValue, setSelectedMedicineValue] = useState("");
  const [selectedTimes, setSelectedTimes] = useState({});
  const [vitalSigns, setVitalSigns] = useState({});
  const defaultContent = `
    <p>C/O</p>
    <p></p>
    <p></p>
    <p></p>
    <p>O/E</p>
    <p></p>
    <p></p>
    <p></p>
    <p>S/E</p>
  `;
  const [ClinicalProfile, setClinicalProfile] = useState(defaultContent);
  const [Treatment, setTreatment] = useState("");
  const [userData, setUserData] = useState(null);
  const debounceTimeoutRef = useRef(null); // Ref to hold the timeout ID

  useEffect(() => {
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    if (data) {
      getMedicineList(data.data);
      setUserData(data.data);
    }
  }, []);

  const getMedicineList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.medicineList;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchmedicineList}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchmedicineList}/${user.branch_id}`;
    } else {
      url = `${Api.branchmedicineList}/${user.branch_id}`;
    }

    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setMedicineList(data);
  };

  const medicineoptions = medicineList.map((m) => ({
    value: m.id,
    label: m.name,
  }));
  const handlemedicineChange = (selectedOption) => {
    setSelectedMedicineValue(selectedOption);
    // Update selected times and vital signs for newly selected medicines
    const updatedTimes = { ...selectedTimes };
    const updatedVitals = { ...vitalSigns };

    selectedOption.forEach((medicine) => {
      if (!updatedTimes[medicine.value]) {
        // If the medicine does not exist in the times, initialize it
        updatedTimes[medicine.value] = {
          "9am": false,
          "3pm": false,
          "9pm": false,
        };
      }

      if (!updatedVitals[medicine.value]) {
        // If the medicine does not exist in the vitals, initialize it
        updatedVitals[medicine.value] = {
          pulse: "",
          bp: "",
          temp: "",
          urine: "",
        };
      }
    });

    // Update the states with the merged data
    setSelectedTimes(updatedTimes);
    setVitalSigns(updatedVitals);
  };

  const getIpdInfo = async () => {
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(
      `${Api.ipdformfirstinfo}/${id}`
    );
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setPatientData(data.data[0]);
  };
  const getIpdRecordList = async () => {
    loadingService.next(true);

    try {
      // Fetch both lists concurrently
      const [recordListResponse, nursingRecordListResponse] = await Promise.all(
        [
          AuthApi.get(`${Api.ipdrecordlist}/${id}`),
          AuthApi.get(`${Api.ipdnursingrecordlist}/${id}`),
        ]
      );

      // Extract data and statuses from the responses
      const {
        data: recordListData,
        message: recordListMessage,
        status: recordListStatus,
      } = recordListResponse;
      const {
        data: nursingRecordListData,
        message: nursingRecordListMessage,
        status: nursingRecordListStatus,
      } = nursingRecordListResponse;

      loadingService.next(false);

      // Handle errors or unauthorized responses
      if (!recordListData || !nursingRecordListData) {
        toast.error(recordListMessage || nursingRecordListMessage);
        if (recordListStatus === 401 || nursingRecordListStatus === 401) {
          navigate("/login");
        }
        return;
      }

      // Set the states with their respective data
      setIpdRecordList(recordListData.data);
      setIpdNursingRecordList(nursingRecordListData.data);
    } catch (error) {
      loadingService.next(false);
      toast.error("An error occurred while fetching data.");
      console.error(error);
    }
  };

  useEffect(() => {
    getIpdInfo();
    getIpdRecordList();
  }, []);

  // Simplified handler function
  const handle9AMChange = async (e, id, property, isChecked) => {
    const newValue = isChecked ? 1 : 0; // Set to 1 for checked, 0 for unchecked
    // Update local state
    setIpdNursingRecordList((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, [property]: newValue } : item
      )
    );
    // API call to update the database
    try {
      loadingService.next(true);
      await AuthApi.post(`${Api.updatetime_9am}/${id}`, {
        [property]: newValue,
      });
      loadingService.next(false);
      console.log("Update successful");
    } catch (error) {
      console.error("Error updating medicine details:", error);
      // Optionally handle error state here
    }
  };
  // Simplified handler function
  const handle3PMChange = async (e, id, property, isChecked) => {
    const newValue = isChecked ? 1 : 0; // Set to 1 for checked, 0 for unchecked
    // Update local state
    setIpdNursingRecordList((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, [property]: newValue } : item
      )
    );
    // API call to update the database
    try {
      loadingService.next(true);
      await AuthApi.post(`${Api.updatetime_3pm}/${id}`, {
        [property]: newValue,
      });
      loadingService.next(false);
      console.log("Update successful");
    } catch (error) {
      console.error("Error updating medicine details:", error);
      // Optionally handle error state here
    }
  };
  // Simplified handler function
  const handle9PMChange = async (e, id, property, isChecked) => {
    const newValue = isChecked ? 1 : 0; // Set to 1 for checked, 0 for unchecked
    // Update local state
    setIpdNursingRecordList((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, [property]: newValue } : item
      )
    );
    // API call to update the database
    try {
      loadingService.next(true);
      await AuthApi.post(`${Api.updatetime_9pm}/${id}`, {
        [property]: newValue,
      });
      loadingService.next(false);
      console.log("Update successful");
    } catch (error) {
      console.error("Error updating medicine details:", error);
      // Optionally handle error state here
    }
  };

  const nursingeportcolumns = [
    {
      title: "Date",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      render: (text, record) => {
        const dob = new Date(record.created_at);
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const formattedDate = `${dob.getDate()}-${
          monthNames[dob.getMonth()]
        }-${dob.getFullYear()}`;
        return <strong>{formattedDate}</strong>;
      },
    },
    {
      title: "Name Of Medicine",
      dataIndex: "medicine",
      sorter: (a, b) => a.medicine.length - b.medicine.length,
    },
    {
      title: "Time(9.00am)",
      dataIndex: "time_9am",
      render: (text, record) => {
        return (
          <>
            {record.is_allow_time_9am == 1 ? (
              <input
                type="checkbox"
                className="form-check-input"
                checked={record.time_9am == 1}
                onChange={(e) =>
                  handle9AMChange(e, record.id, "time_9am", e.target.checked)
                }
              />
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      title: "Time(3.00pm)",
      dataIndex: "time_3pm",
      render: (text, record) => {
        return (
          <>
            {record.is_allow_time_3pm == 1 ? (
              <input
                type="checkbox"
                className="form-check-input"
                checked={record.time_3pm == 1}
                onChange={(e) =>
                  handle3PMChange(e, record.id, "time_3pm", e.target.checked)
                }
              />
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      title: "Time(9.00pm)",
      dataIndex: "time_9pm",
      render: (text, record) => {
        return (
          <>
            {record.is_allow_time_9pm == 1 ? (
              <input
                type="checkbox"
                className="form-check-input"
                checked={record.time_9pm == 1}
                onChange={(e) =>
                  handle9PMChange(e, record.id, "time_9pm", e.target.checked)
                }
              />
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      title: "Pulse",
      dataIndex: "pulse",
      render: (text, record) => (
        <input
          type="text"
          className="form-control"
          value={record.pulse}
          onChange={(e) => handlePulseChange(e, record.id, "pulse")}
          placeholder="Enter Pulse"
        />
      ),
    },
    {
      title: "BP",
      dataIndex: "bp",
      render: (text, record) => (
        <input
          type="text"
          className="form-control"
          value={record.bp}
          onChange={(e) => handleBPChange(e, record.id, "bp")}
          placeholder="Enter BP"
        />
      ),
    },
    {
      title: "Temp",
      dataIndex: "temp",
      render: (text, record) => (
        <input
          type="text"
          className="form-control"
          value={record.temp}
          onChange={(e) => handleTempChange(e, record.id, "temp")}
          placeholder="Enter Temp"
        />
      ),
    },
    {
      title: "Urine",
      dataIndex: "urine",
      render: (text, record) => (
        <input
          type="text"
          className="form-control"
          value={record.urine}
          onChange={(e) => handleUrineChange(e, record.id, "urine")} // Pass record.id instead of record
          placeholder="Enter Urine"
        />
      ),
    },
  ];

  const handlePulseChange = (e, id, property) => {
    const value = e.target.value; // Get the new value from the input

    // Update local state immediately
    const updatedDetails = IpdNursingRecordList.map((m) => {
      if (m.id === id) {
        return { ...m, [property]: value }; // Update the value in local state
      }
      return m;
    });

    setIpdNursingRecordList(updatedDetails); // Update the state with modified array

    // Clear the previous timeout to debounce
    clearTimeout(debounceTimeoutRef.current);

    // Set a new timeout for API call after 1 second
    debounceTimeoutRef.current = setTimeout(async () => {
      try {
        loadingService.next(true);
        const response = await AuthApi.post(`${Api.updatepulsevalue}/${id}`, {
          [property]: value,
        });
        loadingService.next(false);
        console.log(response); // Handle response if needed
      } catch (error) {
        console.error("Error updating details:", error);
        // Handle error as needed
      }
    }, 1000); // 1000 ms = 1 second delay
  };

  const handleBPChange = (e, id, property) => {
    const value = e.target.value; // Get the new value from the input

    // Update local state immediately
    const updatedDetails = IpdNursingRecordList.map((m) => {
      if (m.id === id) {
        return { ...m, [property]: value }; // Update the value in local state
      }
      return m;
    });

    setIpdNursingRecordList(updatedDetails); // Update the state with modified array

    // Clear the previous timeout to debounce
    clearTimeout(debounceTimeoutRef.current);

    // Set a new timeout for API call after 1 second
    debounceTimeoutRef.current = setTimeout(async () => {
      try {
        loadingService.next(true);
        const response = await AuthApi.post(`${Api.updatebpvalue}/${id}`, {
          [property]: value,
        });
        loadingService.next(false);
        console.log(response); // Handle response if needed
      } catch (error) {
        console.error("Error updating details:", error);
        // Handle error as needed
      }
    }, 1000); // 1000 ms = 1 second delay
  };

  const handleTempChange = (e, id, property) => {
    const value = e.target.value; // Get the new value from the input

    // Update local state immediately
    const updatedDetails = IpdNursingRecordList.map((m) => {
      if (m.id === id) {
        return { ...m, [property]: value }; // Update the value in local state
      }
      return m;
    });

    setIpdNursingRecordList(updatedDetails); // Update the state with modified array

    // Clear the previous timeout to debounce
    clearTimeout(debounceTimeoutRef.current);

    // Set a new timeout for API call after 1 second
    debounceTimeoutRef.current = setTimeout(async () => {
      try {
        loadingService.next(true);
        const response = await AuthApi.post(`${Api.updatetempvalue}/${id}`, {
          [property]: value,
        });
        loadingService.next(false);
        console.log(response); // Handle response if needed
      } catch (error) {
        console.error("Error updating details:", error);
        // Handle error as needed
      }
    }, 1000); // 1000 ms = 1 second delay
  };
  const handleUrineChange = (e, id, property) => {
    const value = e.target.value; // Get the new value from the input

    // Update local state immediately
    const updatedDetails = IpdNursingRecordList.map((m) => {
      if (m.id === id) {
        return { ...m, [property]: value }; // Update the value in local state
      }
      return m;
    });

    setIpdNursingRecordList(updatedDetails); // Update the state with modified array

    // Clear the previous timeout to debounce
    clearTimeout(debounceTimeoutRef.current);

    // Set a new timeout for API call after 1 second
    debounceTimeoutRef.current = setTimeout(async () => {
      try {
        loadingService.next(true);
        const response = await AuthApi.post(`${Api.updateurinevalue}/${id}`, {
          [property]: value,
        });
        loadingService.next(false);
        console.log(response); // Handle response if needed
      } catch (error) {
        console.error("Error updating details:", error);
        // Handle error as needed
      }
    }, 1000); // 1000 ms = 1 second delay
  };

  // start ipd report colom

  const ipdeportcolumns = [
    {
      title: "Date",
      dataIndex: "created_at",
      width: "10%", // Small width, equivalent to col-2
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at), // Correctly sorting by date
      render: (text, record) => {
        const dob = new Date(record.created_at); // Convert the date string to a Date object

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        const formattedDate = `${dob.getDate()}-${
          monthNames[dob.getMonth()]
        }-${dob.getFullYear()}`;

        return <strong>{formattedDate}</strong>; // Return formatted date string
      },
    },
    {
      title: "Clinical Profile",
      dataIndex: "clinical_profile",
      sorter: (a, b) => a.clinical_profile.length - b.clinical_profile.length,
      render: (text, record) => {
        let data = record.recordData;
        // If recordData is a JSON string, parse it
        if (typeof data === "string") {
          data = JSON.parse(data);
        }
        return (
          <>
            <div className="formsecondckeditor">
              <CKEditor
                editor={ClassicEditor}
                data={record.clinical_profile}
                disabled
              />
            </div>
            <br></br>
            <div>
              {data.map((item, index) => (
                <div key={index}>
                  <div>
                    <strong>Medicine:</strong> {item.medicine}
                  </div>
                </div>
              ))}
            </div>
          </>
        );
      },
    },
    {
      title: "Treatment",
      dataIndex: "treatment",
      sorter: (a, b) => a.treatment.length - b.treatment.length,
      render: (text, record) => {
        return (
          <div className="formsecondckeditor">
            <CKEditor editor={ClassicEditor} data={record.treatment} disabled />
          </div>
        );
      },
    },
    // {
    //   title: "Times & Vitals", // New column to display times and vitals
    //   dataIndex: "recordData", // Assuming the data is in this field
    //   render: (text, record) => {
    //     let data = record.recordData;

    //     // If recordData is a JSON string, parse it
    //     if (typeof data === "string") {
    //       data = JSON.parse(data);
    //     }
    //     return (
    //       <div>
    //         {data.map((item, index) => (
    //           <div key={index}>
    //             <div><strong>Medicine:</strong> {item.medicine}</div>
    //             <div><strong>Times:</strong>
    //               {Object.entries(item.times).map(([time, value]) => (
    //                 <span key={time}>{time}: {value} </span>
    //               ))}
    //             </div>
    //             <div><strong>Vitals:</strong>
    //               {Object.entries(item.vitals).map(([vital, value]) => (
    //                 <span key={vital}>{vital}: {value || "N/A"} </span>
    //               ))}
    //             </div>
    //             <hr /> {/* Optional: separator between medicines */}
    //           </div>
    //         ))}
    //       </div>
    //     );
    //   },
    // }
  ];

  const CheckboxChange = (medicineValue, timeSlot) => {
    setSelectedTimes((prev) => ({
      ...prev,
      [medicineValue]: {
        ...prev[medicineValue],
        [timeSlot]: !prev[medicineValue]?.[timeSlot], // Toggle the checkbox state
      },
    }));
  };

  const handleVitalChange = (medicineValue, field, value) => {
    setVitalSigns((prev) => ({
      ...prev,
      [medicineValue]: {
        ...prev[medicineValue],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    const savedData = selectedMedicineValue.map((medicine) => {
      const times = selectedTimes[medicine.value] || {};
      const vitals = vitalSigns[medicine.value] || {};

      return {
        medicine: medicine.label,
        times: {
          "9am": times["9am"] ? 1 : 0,
          "3pm": times["3pm"] ? 1 : 0,
          "9pm": times["9pm"] ? 1 : 0,
        },
        vitals: {
          pulse: vitals.pulse || "",
          bp: vitals.bp || "",
          temp: vitals.temp || "",
          urine: vitals.urine || "",
        },
      };
    });

    let payload = {
      ipd_id: PatientData.ipd_id,
      ipd_master_id_form_first: id,
      branch_id: userData.hospitalid ?? userData.branch_id,
      pId: PatientData.pId,
      patientId: PatientData.patientId,
      clinical_profile: ClinicalProfile,
      treatment: Treatment,
      status: 1,
      recordData: JSON.stringify(savedData),
    };

    try {
      loadingService.next(true);
      const { data } = await AuthApi.post(Api.ipdformsecondadd, payload);
      loadingService.next(false);
      if (data) {
        toast.success("Success");
        setSelectedMedicineValue("");
        setClinicalProfile("");
        setTreatment("");
        getIpdInfo();
        getIpdRecordList();
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }

    console.log("Submitting data:", savedData);
    // Add your submit logic here (e.g., API call)
  };

  const handleNursingFormSubmit = async () => {
    const savedData = selectedMedicineValue.map((medicine) => {
      const times = selectedTimes[medicine.value] || {};
      const vitals = vitalSigns[medicine.value] || {};

      return {
        medicine: medicine.label,
        times: {
          "9am": times["9am"] ? 1 : 0,
          "3pm": times["3pm"] ? 1 : 0,
          "9pm": times["9pm"] ? 1 : 0,
        },
        vitals: {
          pulse: vitals.pulse || "",
          bp: vitals.bp || "",
          temp: vitals.temp || "",
          urine: vitals.urine || "",
        },
      };
    });

    let payload = {
      ipd_id: PatientData.ipd_id,
      ipd_master_id_form_first: id,
      branch_id: userData.hospitalid ?? userData.branch_id,
      pId: PatientData.pId,
      patientId: PatientData.patientId,
      recordData: JSON.stringify(savedData),
    };

    try {
      loadingService.next(true);
      const { data } = await AuthApi.post(
        Api.ipdformsecondnursingchartadd,
        payload
      );
      loadingService.next(false);
      if (data) {
        toast.success("Success");
        setSelectedMedicineValue("");
        setClinicalProfile("");
        setTreatment("");
        getIpdInfo();
        getIpdRecordList();
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }

    console.log("Submitting data:", savedData);
    // Add your submit logic here (e.g., API call)
  };

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="add-appoinment"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">IPD </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add IPD</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="card-box profile-header">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img className="avatar" src={imguser} alt="#" />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {PatientData.patient_name}
                            </h3>
                            {/* <small className="text-muted">Gynecologist</small> */}
                            <div className="staff-id">
                              Ipd ID : {PatientData.ipd_id}
                            </div>
                            <div className="staff-id">
                              Patient ID : {PatientData.patientId}
                            </div>
                            <div className="staff-msg">
                              <Link to="/chat" className="btn"></Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <span className="title">Age:</span>
                              <span className="text">{PatientData.age}</span>
                            </li>
                            <li>
                              <span className="title">Gender:</span>
                              <span className="text">{PatientData.gender}</span>
                            </li>
                            <li>
                              <span className="title">Ward:</span>
                              <span className="text">{PatientData.ward}</span>
                            </li>
                            <li>
                              <span className="title">Bed Number:</span>
                              <span className="text">{PatientData.bed_number}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card">
                          <div className="card-body">
                            <h6 className="card-title">Reports</h6>
                            <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                              <li className="nav-item">
                                <Link
                                  className="nav-link active"
                                  to="#bottom-justified-tab1"
                                  data-bs-toggle="tab"
                                >
                                  IPD Report
                                </Link>
                              </li>
                              {/* tab2 */}
                              <li className="nav-item">
                                <Link
                                  className="nav-link"
                                  to="#bottom-justified-tab2"
                                  data-bs-toggle="tab"
                                >
                                  Nursing Chart
                                </Link>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div
                                className="tab-pane show active"
                                id="bottom-justified-tab1"
                              >
                                <div className="table-responsive doctor-list ipdformtablehead">
                                  <Table
                                    className="ipdformtable"
                                    pagination={false}
                                    columns={ipdeportcolumns}
                                    dataSource={IpdRecordList}
                                    rowKey={(record) => record.id}
                                    rowClassName={(record) =>
                                      record.outside_pune == "Yes"
                                        ? "green-row"
                                        : ""
                                    }
                                  />
                                </div>
                                {/* add ipd report form start */}
                                <form>
                                  <hr></hr>
                                  <br></br>
                                  <div className="card-body">
                                    <h6 className="card-title">
                                      Add New Record
                                    </h6>
                                    <div className="container">
                                      <div className="row">
                                        <div className="col-sm">
                                          <label>Clinical Profile</label>
                                          <div className="formsecondckeditor">
                                            <CKEditor
                                              editor={ClassicEditor}
                                              data={ClinicalProfile}
                                              onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setClinicalProfile(data);
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-sm">
                                          <label>Treatment</label>
                                          <div className="formsecondckeditor">
                                            <CKEditor
                                              editor={ClassicEditor}
                                              data={Treatment}
                                              onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setTreatment(data);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <br></br>
                                      <div className="row">
                                        <div className="col-sm">
                                          <div className="col-12 col-md-6 col-xl-12">
                                            <div className="form-group local-forms">
                                              <label>
                                                Medication
                                                <span className="login-danger">
                                                  *
                                                </span>
                                              </label>
                                              <Select
                                                className="form-control-alternative"
                                                options={medicineoptions}
                                                value={selectedMedicineValue}
                                                onChange={handlemedicineChange}
                                                placeholder="Search And Select"
                                                isSearchable
                                                isClearable
                                                isMulti
                                                styles={{
                                                  menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                  }),
                                                  control: (
                                                    baseStyles,
                                                    state
                                                  ) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused
                                                      ? "none"
                                                      : "2px solid rgba(46, 55, 164, 0.1);",
                                                    boxShadow: state.isFocused
                                                      ? "0 0 0 1px #2e37a4"
                                                      : "none",
                                                    "&:hover": {
                                                      borderColor:
                                                        state.isFocused
                                                          ? "none"
                                                          : "2px solid rgba(46, 55, 164, 0.1)",
                                                    },
                                                    borderRadius: "10px",
                                                    fontSize: "14px",
                                                    minHeight: "45px",
                                                  }),
                                                  dropdownIndicator: (
                                                    base,
                                                    state
                                                  ) => ({
                                                    ...base,
                                                    transform: state.selectProps
                                                      .menuIsOpen
                                                      ? "rotate(-180deg)"
                                                      : "rotate(0)",
                                                    transition: "250ms",
                                                    width: "35px",
                                                    height: "35px",
                                                  }),
                                                }}
                                              />
                                              {/* Render selected medicines in a table */}
                                              {selectedMedicineValue.length >
                                                0 && (
                                                <table
                                                  className="table table-bordered table-responsive"
                                                  style={{
                                                    marginTop: "20px",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th>Medicine Name</th>
                                                      <th>Time (9:00 AM)</th>
                                                      <th>Time (3:00 PM)</th>
                                                      <th>Time (9:00 PM)</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {selectedMedicineValue.map(
                                                      (medicine) => (
                                                        <tr
                                                          key={medicine.value}
                                                        >
                                                          <td>
                                                            {medicine.label}
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                              checked={
                                                                selectedTimes[
                                                                  medicine.value
                                                                ]?.["9am"] ||
                                                                false
                                                              }
                                                              onChange={() =>
                                                                CheckboxChange(
                                                                  medicine.value,
                                                                  "9am"
                                                                )
                                                              }
                                                            />
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                              checked={
                                                                selectedTimes[
                                                                  medicine.value
                                                                ]?.["3pm"] ||
                                                                false
                                                              }
                                                              onChange={() =>
                                                                CheckboxChange(
                                                                  medicine.value,
                                                                  "3pm"
                                                                )
                                                              }
                                                            />
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                              checked={
                                                                selectedTimes[
                                                                  medicine.value
                                                                ]?.["9pm"] ||
                                                                false
                                                              }
                                                              onChange={() =>
                                                                CheckboxChange(
                                                                  medicine.value,
                                                                  "9pm"
                                                                )
                                                              }
                                                            />
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-12">
                                        <div className="doctor-submit text-end">
                                          <button
                                            type="button"
                                            className="btn btn-primary submit-form me-2"
                                            onClick={handleSubmit}
                                          >
                                            Submit
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              navigate("/ipdlist");
                                            }}
                                            className="btn btn-primary cancel-form"
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              {/* tab2 */}
                              <div
                                className="tab-pane"
                                id="bottom-justified-tab2"
                              >
                                <div className="table-responsive doctor-list ipdformtablehead">
                                  <Table
                                    className="ipdformtable"
                                    pagination={false}
                                    columns={nursingeportcolumns}
                                    dataSource={IpdNursingRecordList}
                                    rowKey={(record) => record.id}
                                    rowClassName={(record) =>
                                      record.outside_pune == "Yes"
                                        ? "green-row"
                                        : ""
                                    }
                                  />
                                </div>
                                {/* add ipd report form start */}
                                <form>
                                  <hr></hr>
                                  <br></br>
                                  <div className="card-body">
                                    <h6 className="card-title">
                                      Add New Record
                                    </h6>
                                    <div className="container">
                                      <br></br>
                                      <div className="row">
                                        <div className="col-sm">
                                          <div className="col-12 col-md-6 col-xl-12">
                                            <div className="form-group local-forms">
                                              <label>
                                                Medication
                                                <span className="login-danger">
                                                  *
                                                </span>
                                              </label>
                                              <Select
                                                className="form-control-alternative"
                                                options={medicineoptions}
                                                value={selectedMedicineValue}
                                                onChange={handlemedicineChange}
                                                placeholder="Search And Select"
                                                isSearchable
                                                isClearable
                                                isMulti
                                                styles={{
                                                  menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                  }),
                                                  control: (
                                                    baseStyles,
                                                    state
                                                  ) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused
                                                      ? "none"
                                                      : "2px solid rgba(46, 55, 164, 0.1);",
                                                    boxShadow: state.isFocused
                                                      ? "0 0 0 1px #2e37a4"
                                                      : "none",
                                                    "&:hover": {
                                                      borderColor:
                                                        state.isFocused
                                                          ? "none"
                                                          : "2px solid rgba(46, 55, 164, 0.1)",
                                                    },
                                                    borderRadius: "10px",
                                                    fontSize: "14px",
                                                    minHeight: "45px",
                                                  }),
                                                  dropdownIndicator: (
                                                    base,
                                                    state
                                                  ) => ({
                                                    ...base,
                                                    transform: state.selectProps
                                                      .menuIsOpen
                                                      ? "rotate(-180deg)"
                                                      : "rotate(0)",
                                                    transition: "250ms",
                                                    width: "35px",
                                                    height: "35px",
                                                  }),
                                                }}
                                              />
                                              {/* Render selected medicines in a table */}
                                              {selectedMedicineValue.length >
                                                0 && (
                                                <table
                                                  className="table table-bordered table-responsive"
                                                  style={{
                                                    marginTop: "20px",
                                                    width: "100%",
                                                  }}
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th>Medicine Name</th>
                                                      <th>Time (9:00 AM)</th>
                                                      <th>Time (3:00 PM)</th>
                                                      <th>Time (9:00 PM)</th>
                                                      <th>Pulse</th>
                                                      <th>BP</th>
                                                      <th>Temp</th>
                                                      <th>Urine</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {selectedMedicineValue.map(
                                                      (medicine) => (
                                                        <tr
                                                          key={medicine.value}
                                                        >
                                                          <td>
                                                            {medicine.label}
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                              checked={
                                                                selectedTimes[
                                                                  medicine.value
                                                                ]?.["9am"] ||
                                                                false
                                                              }
                                                              onChange={() =>
                                                                CheckboxChange(
                                                                  medicine.value,
                                                                  "9am"
                                                                )
                                                              }
                                                            />
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                              checked={
                                                                selectedTimes[
                                                                  medicine.value
                                                                ]?.["3pm"] ||
                                                                false
                                                              }
                                                              onChange={() =>
                                                                CheckboxChange(
                                                                  medicine.value,
                                                                  "3pm"
                                                                )
                                                              }
                                                            />
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                              checked={
                                                                selectedTimes[
                                                                  medicine.value
                                                                ]?.["9pm"] ||
                                                                false
                                                              }
                                                              onChange={() =>
                                                                CheckboxChange(
                                                                  medicine.value,
                                                                  "9pm"
                                                                )
                                                              }
                                                            />
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              value={
                                                                vitalSigns[
                                                                  medicine.value
                                                                ]?.pulse || ""
                                                              }
                                                              onChange={(e) =>
                                                                handleVitalChange(
                                                                  medicine.value,
                                                                  "pulse",
                                                                  e.target.value
                                                                )
                                                              }
                                                              placeholder="Enter Pulse"
                                                            />
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              value={
                                                                vitalSigns[
                                                                  medicine.value
                                                                ]?.bp || ""
                                                              }
                                                              onChange={(e) =>
                                                                handleVitalChange(
                                                                  medicine.value,
                                                                  "bp",
                                                                  e.target.value
                                                                )
                                                              }
                                                              placeholder="Enter BP"
                                                            />
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              value={
                                                                vitalSigns[
                                                                  medicine.value
                                                                ]?.temp || ""
                                                              }
                                                              onChange={(e) =>
                                                                handleVitalChange(
                                                                  medicine.value,
                                                                  "temp",
                                                                  e.target.value
                                                                )
                                                              }
                                                              placeholder="Enter Temp"
                                                            />
                                                          </td>
                                                          <td>
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              value={
                                                                vitalSigns[
                                                                  medicine.value
                                                                ]?.urine || ""
                                                              }
                                                              onChange={(e) =>
                                                                handleVitalChange(
                                                                  medicine.value,
                                                                  "urine",
                                                                  e.target.value
                                                                )
                                                              }
                                                              placeholder="Enter Urine"
                                                            />
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-12">
                                        <div className="doctor-submit text-end">
                                          <button
                                            type="button"
                                            className="btn btn-primary submit-form me-2"
                                            onClick={handleNursingFormSubmit}
                                          >
                                            Submit
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              navigate("/ipdlist");
                                            }}
                                            className="btn btn-primary cancel-form"
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddIpdFormSecond;
