/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import toast from 'react-hot-toast';
import { loadingService } from "../../helper/observer";


const AddMedicine = () => {
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [category, setCategory] = useState();
  const [BeforeAfter, setBeforeAfter] = useState("");
  const [NumberTimes, setNumberTimes] = useState({
    M: false,
    A: false,
    N: false,
    SOS: false,
  });
  const [userData, setUserData] = useState(null);

  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!name || !category) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      branch_id:userData.hospitalid ?? userData.branch_id,
      name: name,
      category: category,
      NumberTimes: formattedTimes,
      BeforeAfter: BeforeAfter,
    };

    try {
      loadingService.next(true);
      const { data } = await AuthApi.post(Api.medicineAdd, payload);
      loadingService.next(false);
      if (data) {
        toast.success("Success");
        navigate("/medicineslist");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  const handleCheckboxChange = (time) => {
    setNumberTimes((prevTimes) => ({
      ...prevTimes,
      [time]: !prevTimes[time],
    }));
  };

  const convertToBinaryString = () => {
    const binaryValues = Object.entries(NumberTimes).map(([time, checked]) => {
      if (time === "SOS") {
        // Treat SOS checkbox differently
        return checked ? "SOS" : "";
      }
      return checked ? "1" : "0";
    });

    // Remove empty values before joining
    const nonEmptyBinaryValues = binaryValues.filter((value) => value !== "");

    return nonEmptyBinaryValues.join("-");
  };

  const formattedTimes = convertToBinaryString();


  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem('userdata');
    let data = JSON.parse(udata);

    setUserData(data.data);
    
  }, []);
  console.log(userData)

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="add-appoinment"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Medicine </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Medicine</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Medicine Details</h4>
                          </div>
                        </div>

                        {/* name */}
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Medicine Name{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Medicine Content{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <label className="form-control-label">
                            Number Of Times
                          </label>
                          <div className="row">
                            {Object.entries(NumberTimes).map(
                              ([time, checked]) => (
                                <div className="col" key={time}>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="select-checkbox hidecheck"
                                      id={`${time}Checkbox`}
                                      name={`${time}Checkbox`}
                                      checked={checked}
                                      onChange={() =>
                                        handleCheckboxChange(time)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`${time}Checkbox`}
                                    >
                                      {time.toUpperCase()}
                                    </label>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Before/After Meal
                              <span className="login-danger">*</span>
                            </label>
                            <select
                              className="form-select"
                              value={BeforeAfter}
                              onChange={(e) => setBeforeAfter(e.target.value)}
                              placeholder="Select City"
                            >
                              <option value="">Select</option>
                              <option value="Before Meal">Before Meal</option>
                              <option value="After Meal">After Meal</option>
                              <option value="जेवणानंतर">जेवणानंतर</option>
                              <option value="जेवणापूर्वी">जेवणापूर्वी</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                navigate("/medicineslist");
                              }}
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddMedicine;
