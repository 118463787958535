/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";
import Select from "react-select";

import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
  imguser,
} from "../imagepath";
import { Link } from "react-router-dom";
import Api from "../../helper/api";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";
import AuthApi from "../../helper/authApi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddIpdSlave = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [PatientData, setPatientData] = useState({});
  const [OpdData, setOpdData] = useState({});
  const [doctor, setDoctor] = useState(null);
  const [DoctorList, setDoctorList] = useState([]);
  const [BedCategoryList, setBedCategoryList] = useState([]);
  const [BedOptionList, setBedOptionList] = useState([]);
  const [disabledBedsArray, setDisabledBedsArray] = useState([]);

  // Add state variables for Admission Details
  const [doctorName, setDoctorName] = useState("");
  const [bedNumber, setBedNumber] = useState("");
  const [wardRoomType, setWardRoomType] = useState("");
  const [reasonForAdmission, setReasonForAdmission] = useState("");

  const SubmitForm = async (e) => {
    e.preventDefault();

    let payload = {
      appointment_id: id,
      branch_id: userData.hospitalid ?? userData.branch_id,
      checklist_inputValues: JSON.stringify(inputValues),
      pId: PatientData.pId,
      patientId: PatientData.patientId,
      ipd_id: PatientData.ipd_id,
      doctor_name: doctorName,
      bed_number: bedNumber?.value,
      ward_room_type: wardRoomType?.value,
      consultation_doctor: doctor?.value,
      reason_for_admission: reasonForAdmission,
      status: "1",
      is_discharge: "0",
      req_for_discharge: "0",
      discharge_approved_by_lab: "0",
      discharge_approved_by_pharma: "0",
      discharge_approved_by_xray: "0",
      discharge_approved_by_sonography: "0",
    };

    try {
      loadingService.next(true);
      const { data } = await AuthApi.post(Api.ipdformadd, payload);
      loadingService.next(false);
      if (data) {
        toast.success("Success");
        navigate(`/addipdformsecond/${data.data.id}`);
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  // Inside your AddIpdSlave component
  const [checkboxes, setCheckboxes] = useState({
    review_opd_doctors_notes: false,
    handover_sheet_available: false,
    verify_ongoing_medications: false,
    diagnosis_treatment_plan_clear: false,
    crosscheck_allergies: false,
    prescription_details_clear: false,
    verify_current_medications: false,
    doublecheck_timing_doses: false,
    confirm_pending_investigations: false,
  });

  const checklistItems = [
    {
      id: "review_opd_doctors_notes",
      label: "Review OPD Doctor's Notes",
      inputType: "text",
    },
    {
      id: "handover_sheet_available",
      label: "Ensure Handover Sheet Available",
      inputType: "text",
    },
    {
      id: "verify_ongoing_medications",
      label: "Verify Ongoing Medications",
      inputType: "text",
    },
    {
      id: "diagnosis_treatment_plan_clear",
      label: "Clear Diagnosis & Provisional Treatment Plan",
      inputType: "text",
    },
    {
      id: "crosscheck_allergies",
      label: "Cross-Check Allergies List",
      inputType: "text",
    },
    {
      id: "prescription_details_clear",
      label: "Ensure Prescription Details are Clear",
      inputType: "text",
    },
    {
      id: "verify_current_medications",
      label: "Verify Injections, Medications, or IV Fluids Being Administered",
      inputType: "text",
    },
    {
      id: "doublecheck_timing_doses",
      label: "Double-Check Timing & Doses for Ongoing Medications",
      inputType: "text",
    },
    {
      id: "confirm_pending_investigations",
      label: "Confirm Pending Investigations (Lab Tests, Scans)",
      inputType: "text",
    },
  ];

  // Handler for checkbox toggle
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setCheckboxes((prev) => ({ ...prev, [id]: checked }));
    setInputValues((prev) => ({ ...prev, [id]: checked ? "" : "" }));
  };

  const getDoctorList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.doctorList;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchdoctorList}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchdoctorList}/${user.branch_id}`;
    } else {
      url = `${Api.branchdoctorList}/${user.branch_id}`;
    }
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setDoctorList(data);
  };

  const getBedCategoryList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.bedcategorylist;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchbedcategorylist}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchbedcategorylist}/${user.branch_id}`;
    } else {
      url = `${Api.branchbedcategorylist}/${user.branch_id}`;
    }
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setBedCategoryList(data);
  };

  const options = DoctorList.map((doctor) => ({
    value: doctor.id,
    label: `${doctor.first_name} ${doctor.last_name}`,
  }));

  const bedcategoryoptions = BedCategoryList.map((b) => ({
    value: b.id,
    label: b.category_type,
  }));

  const bedoptions = BedOptionList.map((b) => ({
    value: b.bed,
    label: disabledBedsArray.includes(b.bed)
      ? `${b.bed} (Bed Not Available)`
      : b.bed,
    isDisabled: disabledBedsArray.includes(b.bed), // Check if the bed is in the disabled array
  }));

  const handleChange = (selectedOption) => {
    setDoctor(selectedOption);
  };

  const handlechangeBed = (selectedOption) => {
    setBedNumber(selectedOption);
  };

  const handleBedChange = async (selectedOption) => {
    setWardRoomType(selectedOption);

    if (selectedOption) {
      try {
        // Make the API call to fetch bed data
        const response = await AuthApi.get(
          `${Api.getbeddataipdfromfirst}/${selectedOption.value}`
        );
        const data = response.data;

        // Update the bed options list from the API response
        // Assuming 'data.beds' contains the list of available beds
        setBedOptionList(data.beds || []); // Set bed options
        setDisabledBedsArray(data.booked_beds || []); // Set booked beds

        // Optional: Process the data if needed
        console.log("API Response Data:", data);
      } catch (error) {
        // Log any errors that occur during the API call
        console.error("Error fetching bed data:", error);
        // Optionally, handle errors like displaying a message to the user
      }
    } else {
      // Clear the bed options if no option is selected
      setBedOptionList([]);
      setDisabledBedsArray([]); // Clear disabled beds array if no option selected
    }
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);

    setUserData(data.data);
  }, []);
  console.log(userData);

  const getOpdList = async () => {
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(
      `${Api.assignmentInfo}/${id}`
    );
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }

    setPatientData(data);
  };

  const getopdslavedata = async () => {
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(
      `${Api.opdslaveInfo}/${id}`
    );
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setOpdData(data);
  };

  useEffect(() => {
    getOpdList();
    getopdslavedata();
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getDoctorList(userData);
      getBedCategoryList(userData);
    }
  }, [userData]);

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="add-appoinment"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">IPD </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add IPD</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="card-box profile-header">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img className="avatar" src={imguser} alt="#" />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {PatientData.patient_name}
                            </h3>
                            {/* <small className="text-muted">Gynecologist</small> */}
                            <div className="staff-id">
                              Ipd ID : {PatientData.ipd_id}
                            </div>
                            <div className="staff-id">
                              Patient ID : {PatientData.patientId}
                            </div>
                            <div className="staff-msg">
                              <Link to="/chat" className="btn"></Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <span className="title">Age:</span>
                              <span className="text">{PatientData.age}</span>
                            </li>
                            <li>
                              <span className="title">Gender:</span>
                              <span className="text">{PatientData.gender}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-heading">
                          <h4>Opd Queue</h4>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <p>
                            <b>BP :</b> {OpdData.bp}
                          </p>
                          <p>
                            <b>Sugar :</b> {OpdData.sugar}
                          </p>
                        </div>
                        <div>
                          <p>
                            <b>weight :</b> {OpdData.weight}
                          </p>
                          <p>
                            <b>Oxygen :</b> {OpdData.oxygen}
                          </p>
                        </div>
                      </div>
                      <hr></hr>
                      <details>
                        <summary className="fw-bold">Prescription</summary>
                        <div className="">
                          <iframe
                            src={OpdData.precription_path}
                            width="100%"
                            height="600px"
                            title="PDF Viewer"
                          >
                            Your browser does not support PDFs.{" "}
                            <a href={OpdData.precription_path}>
                              Download the PDF
                            </a>
                            .
                          </iframe>
                        </div>
                      </details>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h6 className="card-title">Checklist</h6>
                  <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        to="#bottom-justified-tab1"
                        data-bs-toggle="tab"
                      >
                        Step 1
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#bottom-justified-tab2"
                        data-bs-toggle="tab"
                      >
                        Step 2
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane show active"
                      id="bottom-justified-tab1"
                    >
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-heading">
                                    <h4>Checklist</h4>
                                  </div>
                                </div>
                                {checklistItems.map((item, index) => (
                                  <div
                                    key={index}
                                    className="col-12 col-md-12 col-xl-6 pb-3 d-flex align-items-start"
                                  >
                                    <div className="col-md-1">
                                      <input
                                        type="checkbox"
                                        className="select-checkbox hidecheck me-2"
                                        id={item.id}
                                        name={item.id}
                                        checked={checkboxes[item.id]}
                                        onChange={handleCheckboxChange}
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <label
                                        className="form-check-label"
                                        htmlFor={item.id}
                                      >
                                        {item.label}
                                      </label>
                                    </div>
                                    <div className="col-md-5">
                                      <input
                                        className="form-control"
                                        type={item.inputType}
                                        value={inputValues[item.id] || ""}
                                        placeholder="Additonal comment...."
                                        onChange={(e) =>
                                          setInputValues({
                                            ...inputValues,
                                            [item.id]: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                ))}
                                <hr></hr>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="bottom-justified-tab2">
                      <form onSubmit={SubmitForm}>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="card">
                              <div className="card-body">
                                {/* <details> */}
                                <h6 className="fw-bold">Admission Details</h6>
                                <div className="row py-3">
                                  <div className="col-12">
                                    <div className="form-heading">
                                      <h4>Admission</h4>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-4 col-xl-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        Doctor Name{" "}
                                        <span className="login-danger">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={doctorName}
                                        onChange={(e) =>
                                          setDoctorName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  {/* Ward/Room Type */}
                                  <div className="col-12 col-md-4 col-xl-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        Ward/Room Type
                                        <span className="login-danger">*</span>
                                      </label>
                                      <Select
                                        className="form-control-alternative"
                                        options={bedcategoryoptions}
                                        value={wardRoomType}
                                        onChange={handleBedChange}
                                        placeholder="Search And Select"
                                        isSearchable
                                        isClearable
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused
                                              ? "none"
                                              : "2px solid rgba(46, 55, 164, 0.1);",
                                            boxShadow: state.isFocused
                                              ? "0 0 0 1px #2e37a4"
                                              : "none",
                                            "&:hover": {
                                              borderColor: state.isFocused
                                                ? "none"
                                                : "2px solid rgba(46, 55, 164, 0.1)",
                                            },
                                            borderRadius: "10px",
                                            fontSize: "14px",
                                            minHeight: "45px",
                                          }),
                                          dropdownIndicator: (base, state) => ({
                                            ...base,
                                            transform: state.selectProps
                                              .menuIsOpen
                                              ? "rotate(-180deg)"
                                              : "rotate(0)",
                                            transition: "250ms",
                                            width: "35px",
                                            height: "35px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-4 col-xl-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        Bed Number{" "}
                                        <span className="login-danger">*</span>
                                      </label>
                                      <Select
                                        className="form-control-alternative"
                                        options={bedoptions}
                                        value={bedNumber}
                                        onChange={handlechangeBed}
                                        placeholder="Search And Select"
                                        isSearchable
                                        isClearable
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused
                                              ? "none"
                                              : "2px solid rgba(46, 55, 164, 0.1);",
                                            boxShadow: state.isFocused
                                              ? "0 0 0 1px #2e37a4"
                                              : "none",
                                            "&:hover": {
                                              borderColor: state.isFocused
                                                ? "none"
                                                : "2px solid rgba(46, 55, 164, 0.1)",
                                            },
                                            borderRadius: "10px",
                                            fontSize: "14px",
                                            minHeight: "45px",
                                          }),
                                          dropdownIndicator: (base, state) => ({
                                            ...base,
                                            transform: state.selectProps
                                              .menuIsOpen
                                              ? "rotate(-180deg)"
                                              : "rotate(0)",
                                            transition: "250ms",
                                            width: "35px",
                                            height: "35px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        Consultation Doctor
                                        <span className="login-danger">*</span>
                                      </label>
                                      <Select
                                        className="form-control-alternative"
                                        options={options}
                                        value={doctor}
                                        onChange={handleChange}
                                        placeholder="Search And Select"
                                        isSearchable
                                        isClearable
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused
                                              ? "none"
                                              : "2px solid rgba(46, 55, 164, 0.1);",
                                            boxShadow: state.isFocused
                                              ? "0 0 0 1px #2e37a4"
                                              : "none",
                                            "&:hover": {
                                              borderColor: state.isFocused
                                                ? "none"
                                                : "2px solid rgba(46, 55, 164, 0.1)",
                                            },
                                            borderRadius: "10px",
                                            fontSize: "14px",
                                            minHeight: "45px",
                                          }),
                                          dropdownIndicator: (base, state) => ({
                                            ...base,
                                            transform: state.selectProps
                                              .menuIsOpen
                                              ? "rotate(-180deg)"
                                              : "rotate(0)",
                                            transition: "250ms",
                                            width: "35px",
                                            height: "35px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-4 col-xl-4">
                                    <div className="form-group local-forms">
                                      <label>
                                        Reason for Admission{" "}
                                        <span className="login-danger">*</span>
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        value={reasonForAdmission}
                                        onChange={(e) =>
                                          setReasonForAdmission(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="doctor-submit text-end">
                                      <button
                                        type="submit"
                                        className="btn btn-primary submit-form me-2"
                                      >
                                        Save & Next
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {/* </details> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddIpdSlave;
