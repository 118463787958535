import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import { DatePicker } from "antd";
import { Table } from "antd";

import { blogimg2, blogimg4, searchnormal } from "../imagepath";

const bank_reconcile = () => {
  const datasource = [
    {
      id: 1,
      Img: blogimg2,
      Name: "Andrea Lalema",
      Department: "Otolaryngology",
      Specialization: "Infertility",
      Degree: "MBBS, MS",
      Mobile: "+1 23 456890",
      Email: "example@email.com",
      JoiningDate: "01.10.2022",
      FIELD9: "",
    },
    {
      id: 2,
      Img: blogimg4,
      Name: "Dr.Smith Bruklin",
      Department: "Urology",
      Specialization: "Prostate",
      Degree: "MBBS, MS",
      Mobile: "+1 23 456890",
      Email: "example@email.com",
      JoiningDate: "01.10.2022",
      FIELD9: "",
    },
  ];
  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      render: (text, record) => (
        <>
          <h2 className="profile-image">
            <Link to="#" className="avatar avatar-sm me-2">
              <img
                className="avatar-img rounded-circle"
                src={record.Img}
                alt="rounded circle"
              />
            </Link>
            <Link to="#">{record.Name}</Link>
          </h2>
        </>
      ),
    },
    {
      title: "Department",
      dataIndex: "Department",
      sorter: (a, b) => a.Department.length - b.Department.length,
    },
    {
      title: "Specialization",
      dataIndex: "Specialization",
      sorter: (a, b) => a.Specialization.length - b.Specialization.length,
    },
    {
      title: "Degree",
      dataIndex: "Degree",
      sorter: (a, b) => a.Degree.length - b.Degree.length,
    },
    {
      title: "Mobile",
      dataIndex: "Mobile",
      sorter: (a, b) => a.Mobile.length - b.Mobile.length,
      render: (text, record) => (
        <>
          <Link to="#">{record.Mobile}</Link>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email.length - b.Email.length,
    },
    {
      title: "JoiningDate",
      dataIndex: "JoiningDate",
      sorter: (a, b) => a.JoiningDate.length - b.JoiningDate.length,
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: () => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item" to="/editstaff">
                  <i className="far fa-edit me-2" />
                  Edit
                </Link>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#delete_patient"
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Voucher </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Bank Reconcile</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Bank Reconciliation</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center my-3">
                      <div className="col-md-3">
                        <label>Filter by Date</label>
                        <div className="input-group align-items-baseline">
                          <DatePicker
                            className="form-control datetimepicker" // Keeping it like input
                            // selected={fromdate}
                            // onChange={(date) => setFromdate(date)}
                            placeholderText="From"
                            dateFormat="dd/MM/yyyy" // Change format as per your requirement
                          />
                          <span className="input-group-text">to</span>
                          <DatePicker
                            className="form-control datetimepicker" // Keeping it like input
                            // selected={todate}
                            // onChange={(date) => setTodate(date)}
                            placeholderText="To"
                            dateFormat="dd/MM/yyyy" // Change format as per your requirement
                          />
                        </div>
                      </div>
                      {/* <!-- Filter and Reset Buttons --> */}
                      <div className="col-md-3 d-flex justify-content-end pt-3 doctor-submit">
                        <button
                          className="btn btn-primary me-2"
                          id="filter-btn"
                        >
                          Filter
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary cancel-form"
                        >
                          Reset
                        </button>
                      </div>
                      <div className="col-md-4">
                        <label>Bank Account</label>
                        <div className="input-group align-items-baseline">
                          <select className="form-control select">
                            <option>Default Bank Account</option>
                            <option>SBI</option>
                            <option>BOI</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      columns={columns}
                      dataSource={datasource}
                      rowKey={(record) => record.id}
                      rowClassName={(record) =>
                        record.outside_pune == "Yes"
                          ? "green-row clickable-row"
                          : "clickable-row"
                      }
                    />
                  </div>
                  <div className="row">
                    {/* First Form */}
                    <div className="col-sm-7">
                      <div className="card">
                        <div className="card-body"></div>
                      </div>
                    </div>

                    {/* Second Form */}
                    <div className="col-sm-5">
                      <div className="card">
                        <div className="card-body">
                          <form>
                            <div className="row">
                              <div className="col-12 col-md-6 col-xl-12">
                                <div className="form-group local-forms">
                                  <label>
                                    Balance as per bank{" "}
                                    <span className="login-danger">*</span>
                                  </label>
                                  <input className="form-control" type="text" />
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-xl-12">
                                <div className="form-group local-forms">
                                  <label>
                                    Amount not reflected in Bank{" "}
                                    <span className="login-danger">*</span>
                                  </label>
                                  <input className="form-control" type="text" />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12 col-md-6 col-xl-12">
                                <div className="form-group local-forms">
                                  <label>
                                    Balance as per company book{" "}
                                    <span className="login-danger">*</span>
                                  </label>
                                  <input className="form-control" type="text" />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    {/* Form submission buttons */}
                    <div className="col-12">
                      <div className="doctor-submit text-end">
                        <button
                          type="submit"
                          className="btn btn-primary submit-form me-2"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary cancel-form"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default bank_reconcile;
