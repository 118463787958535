/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";

const DischargedList = () => {
  const navigate = useNavigate();
  const [AppointmentList, setAppointmentList] = useState([]);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);

  const handleDelete = async () => {
    // Implement your delete logic here using deleteId

    loadingService.next(true);
    const { data, status } = await AuthApi.delete(
      `${Api.deletetestcategory}/${show}`
    );
    loadingService.next(false);
    if (status != 200) {
      toast.success("Deleted Successfully");
      setShow(false);
      getAppointmentList(userData);
      if (status == 401) navigate("/login");
      return;
    }
  };

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Ipd Id",
      dataIndex: "ipd_id",
      sorter: (a, b) => a.ipd_id.length - b.ipd_id.length,
    },
    {
      title: "Patient Id",
      dataIndex: "patientId",
      sorter: (a, b) => a.patientId.length - b.patientId.length,
    },
    {
      title: "Patient",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.length - b.patient_name.length,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      sorter: (a, b) => a.mobile_number.length - b.mobile_number.length,
    },
    {
      title: "Outside Pune",
      dataIndex: "outside_pune",
      sorter: (a, b) => a.outside_pune.length - b.outside_pune.length,
    },
    {
      title: "Ward",
      dataIndex: "ward",
      sorter: (a, b) => a.ward.length - b.ward.length,
    },
    {
      title: "Bed No",
      dataIndex: "bed_number",
      sorter: (a, b) => a.bed_number.length - b.bed_number.length,
    },
    // {
    //   title: "Branch Id",
    //   dataIndex: "branch_id",
    //   sorter: (a, b) => a.branch_id.length - b.branch_id.length,
    // },
    
  ];

  const getAppointmentList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.billingreceiptlist;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchbillingreceipt}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchbillingreceipt}/${user.branch_id}`;
    } else {
      url = `${Api.branchbillingreceipt}/${user.branch_id}`;
    }
    loadingService.next(true);
    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);
    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setAppointmentList(data);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    setUserData(data.data);
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getAppointmentList(userData);
    }
  }, [userData]);

  

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Discharged </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Discharged List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Discharged List</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                            {/* <div className="add-group">
                              <Link
                                to="/addtestcategory"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2"></Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: AppointmentList.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={AppointmentList}
                      rowKey={(record) => record.id}
                      rowClassName={(record) =>
                        record.outside_pune == "Yes"
                          ? "green-row clickable-row"
                          : "clickable-row"
                      }
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: () => {
                            // Redirect to the info page when the row is clicked
                            navigate(`/dischargesummary/${record.id}`);
                          },
                        };
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure want to delete this ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imagesend} alt="#" width={50} height={46} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white me-2"
            variant="secondary"
            onClick={() => setShow(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="btn btn-danger"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DischargedList;
