import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { DatePicker } from "antd";
// import Select from "react-select";
// import Modal from "react-bootstrap/Modal";
import { loadingService } from "../../helper/observer";
import { imguser } from "../imagepath";

const ClaimNowPage = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  // const [userData, setUserData] = useState(null);
  const [PatientData, setPatientData] = useState([]);

  // useEffect(() => {
  //   // set default styling
  //   let udata = localStorage.getItem("userdata");
  //   let data = JSON.parse(udata);

  //   setUserData(data.data);
  // }, []);

  const getIpdInfo = async () => {
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(
      `${Api.ipdformfirstinfo}/${id}`
    );
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setPatientData(data.data[0]);
  };

  useEffect(() => {
    getIpdInfo();
  }, []);

  return (
    <>
      <div>
        <Header />
        <Sidebar
          id="menu-item2"
          id1="menu-items2"
          activeClassName="add-patient"
        />
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dicharge Form </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Discharge</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-box profile-header">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img className="avatar" src={imguser} alt="#" />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {PatientData.patient_name}
                            </h3>
                            {/* <small className="text-muted">Gynecologist</small> */}
                            <div className="staff-id">
                              Ipd ID : {PatientData.ipd_id}
                            </div>
                            <div className="staff-id">
                              Patient ID : {PatientData.patientId}
                            </div>
                            <div className="staff-msg">
                              <Link to="/chat" className="btn"></Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <span className="title">Age:</span>
                              <span className="text">{PatientData.age}</span>
                            </li>
                            <li>
                              <span className="title">Gender:</span>
                              <span className="text">{PatientData.gender}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br></br>

            <div className="row">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div className="col-12">
                      <div className="form-heading">
                        <h4 className="text-danger">
                          <b>TPA / PANEL DETAILS</b>
                        </h4>
                      </div>
                    </div>
                    <div className="col-12" style={{ marginLeft: "10px" }}>
                      <div className="form-heading">
                        <h3 className="text-primary">
                          <b>STAR HEALTH</b>
                        </h3>
                        <p>STAR HEALTH</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="col-12">
                        <div className="form-heading">
                          <h4 className="text-danger">
                            <b>Bill Details</b>
                          </h4>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6 col-xl-12">
                          <div className="form-group local-forms">
                            <label>
                              Receipt No<span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value=""
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Bill Date/Time{" "}
                              <span className="login-danger"></span>
                            </label>
                            <DatePicker
                              className="form-control datetimepicker"
                              format="DD-MMM-YYYY"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              <span className="login-danger"></span>
                            </label>
                            <div className="">
                              <input
                                className="form-control"
                                id="outlined-controlled"
                                type="time"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Bill Type <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={PatientData.ipd_id}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Bill Amount <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value=""
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Credit Amount{" "}
                            <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value=""
                            disabled
                          />
                        </div>
                      </div>
                      <hr></hr>
                      <div className="col-12">
                        <div className="form-heading">
                          <h4 className="text-danger">
                            <b>Claim Details</b>
                          </h4>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            CCN NO. <span className="login-danger"></span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value=""
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="col-12 col-sm-12">
                              <div className="form-group local-forms">
                                <label>
                                  Remarks{" "}
                                  <span className="login-danger">*</span>
                                </label>
                                <textarea
                                  className="form-control"
                                  rows={3}
                                  cols={30}
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div className="col-12">
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            <b>Claim Status</b>
                          </label>
                          <select className="form-select custom-badge status-orange">
                            <option value="">Select</option>
                            <option value="">Claimed</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="col-12">
                      <div className="form-heading">
                        <h4 className="text-danger">
                          <b>ON CLAIM</b>
                        </h4>
                      </div>
                    </div>
                    <div className="row d-flex">
                      <div className="col-12 col-md-6 col-xl-12">
                        <label className="col-form-label col-lg-12">
                          Claim Amount
                        </label>
                        <div className="col-md-12">
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              value="42046.00"
                            />
                            <span
                              className="input-group-text"
                              id="basic-addon2"
                            >
                              INR
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClaimNowPage;
