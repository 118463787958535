/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import axios from "axios";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import toast from "react-hot-toast";
import Select from "react-select";
import { loadingService } from "../../helper/observer";




const AddTest = () => {
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [category, setCategory] = useState();
  const [userData, setUserData] = useState(null);
  const [CategoryList, setCategoryList] = useState([]);

  const getCategoryList = async (puser) => {
    let url;
    if (puser.role == 9) {
      url = Api.testcategoryList;
    } else if (puser.role == 8 || puser.role == 7) {
      url = `${Api.branchtestcategoryList}/${puser.hospitalid}`;
    } else if (puser.role == 6) {
      url = `${Api.branchtestcategoryList}/${puser.branch_id}`;
    } else {
      url = `${Api.branchtestcategoryList}/${puser.branch_id}`;
    }
    loadingService.next(true);
    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);
    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setCategoryList(data);
  };

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getCategoryList(userData);
    }
  }, [userData]);

  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!name || !category) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      branch_id: userData.hospitalid ?? userData.branch_id,
      name: name,
      category: category.value,
    };

    try {
      loadingService.next(true);
      const { data } = await AuthApi.post(Api.testAdd, payload);
      loadingService.next(false);
      if (data) {
        toast.success("Success");
        navigate("/testlist");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);

    setUserData(data.data);
  }, []);
  console.log(userData);


  const options = CategoryList.map((v) => ({
    value: v.category,
    label: v.category,
  }));
  const handleChange = (selectedOption) => {
    setCategory(selectedOption);
  };

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="add-appoinment"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Test </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Test</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Test Details</h4>
                          </div>
                        </div>

                        {/* name */}
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Test Name <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Test Category{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <Select
                              className="form-control-alternative"
                              options={options}
                              value={category}
                              onChange={handleChange}
                              placeholder="Search And Select"
                              isSearchable
                              isClearable
                              styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ?'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                   boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                    minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                navigate("/testlist");
                              }}
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddTest;
