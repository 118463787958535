import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { DatePicker } from "antd";
import Select from "react-select";
import moment from "moment";

const AddPatients = () => {
  const navigate = useNavigate();

  const [registrationType, setRegistrationType] = useState("");
  const [referredBy, setreferredBy] = useState("");
  const [department, setDepartment] = useState("");
  const [tpa_panelName, setTpa_panelName] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [dispensary, setDispensary] = useState("");
  const [corporate, setCorporate] = useState("");
  const [mobileNumber2, setMobileNumber2] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [outsidePune, setOutsidePune] = useState("No");
  const [age, setAge] = useState("");
  const [address, setAddress] = useState("");

  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedState, setSelectedState] = useState(null);
  const [userData, setUserData] = useState(null);
  const [email, setEmail] = useState("");

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const clearImage = () => {
    setSelectedImage(null);
  };

  const triggerFileInput = () => {
    document.getElementById("imageUpload").click();
  };

  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!firstName || !mobileNumber) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      branch_id: userData.hospitalid ?? userData.branch_id,
      first_name: firstName,
      last_name: lastName,
      age: age,
      mobile_number: mobileNumber,
      date_of_birth: dateOfBirth,
      gender: gender,
      address: address,
      outside_pune: outsidePune,
      referred_by: referredBy,
      email: email,
    };

    try {
      const { data } = await AuthApi.post(Api.patientAdd, payload);
      if (data) {
        toast.success("Success");
        navigate("/patientslist");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);

    setUserData(data.data);
  }, []);

  // options

  const departmentOptions = [
    { value: "", label: "Select" },
    { value: "NURSING", label: "NURSING" },
    { value: "ICU", label: "ICU" },
    { value: "HOUSEKEEPING", label: "HOUSEKEEPING" },
    { value: "SURGICAL", label: "SURGICAL" },
    { value: "CSSD", label: "CSSD" },
    { value: "MRD", label: "MRD" },
    { value: "OPD", label: "OPD" },
    { value: "PATHOLOGY", label: "PATHOLOGY" },
    { value: "X-RAY", label: "X-RAY" },
    { value: "RADIOLOGY", label: "RADIOLOGY" },
    { value: "IPD", label: "IPD" },
  ];
  const options = departmentOptions.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const handleChange = (selectedOption) => {
    setTpa_panelName(selectedOption);
  };

  const departmentoptions = departmentOptions.map((e) => ({
    value: e.value,
    label: e.label,
  }));

  const departmenthandleChange = (selectedOption) => {
    setDepartment(selectedOption);
  };

  const stateOptions = [
    { value: "", label: "Select State" },
    {
      value: "Andaman and Nicobar Islands",
      label: "Andaman and Nicobar Islands",
    },
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    {
      value: "Dadra and Nagar Haveli and Daman and Diu",
      label: "Dadra and Nagar Haveli and Daman and Diu",
    },
    { value: "Delhi", label: "Delhi (National Capital Territory of Delhi)" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Ladakh", label: "Ladakh" },
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
  ];

  const stateoptions = stateOptions.map((e) => ({
    value: e.value,
    label: e.label,
  }));

  const statehandleChange = (selectedOption) => {
    setSelectedState(selectedOption);
  };

  const insurance_companyoptions = stateOptions.map((e) => ({
    value: e.value,
    label: e.label,
  }));

  const insurance_companyhandleChange = (selectedOption) => {
    setInsuranceCompany(selectedOption);
  };

  const corporateoptions = stateOptions.map((e) => ({
    value: e.value,
    label: e.label,
  }));

  const corporatehandleChange = (selectedOption) => {
    setCorporate(selectedOption);
  };

  const dispensaryoptions = stateOptions.map((e) => ({
    value: e.value,
    label: e.label,
  }));

  const dispensaryhandleChange = (selectedOption) => {
    setDispensary(selectedOption);
  };

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Patients </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Add Patient</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={SubmitForm}>
                    <div className="row">
                      {/* Form fields */}
                      <h5>Personal Details</h5>
                      <hr></hr>
                      <br></br>
                      {/* First Name */}
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Registration Type{" "}
                            <span className="login-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            value={registrationType}
                            onChange={(e) =>
                              setRegistrationType(e.target.value)
                            }
                            placeholder="Select City"
                          >
                            <option value="">Select</option>
                            <option value="Private">Private</option>
                            <option value="TPA">TPA</option>
                            <option value="PANEL">PANEL</option>
                          </select>
                        </div>
                      </div>
                      {registrationType == "PANEL" && (
                        <>
                          <div className="col-12 col-md-6 col-xl-12">
                            <div className="form-group local-forms">
                              <label>
                                TPA / PANEL Name{" "}
                                <span className="login-danger"></span>
                              </label>
                              <Select
                                className="form-control-alternative"
                                options={options}
                                value={tpa_panelName}
                                onChange={handleChange}
                                placeholder="Search And Select"
                                isSearchable
                                isClearable
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1);",
                                    boxShadow: state.isFocused
                                      ? "0 0 0 1px #2e37a4"
                                      : "none",
                                    "&:hover": {
                                      borderColor: state.isFocused
                                        ? "none"
                                        : "2px solid rgba(46, 55, 164, 0.1)",
                                    },
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen
                                      ? "rotate(-180deg)"
                                      : "rotate(0)",
                                    transition: "250ms",
                                    width: "35px",
                                    height: "35px",
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {registrationType == "PANEL" && (
                        <>
                          <div className="col-12 col-md-6 col-xl-12">
                            <div className="form-group local-forms">
                              <label>
                                Dispensary Name{" "}
                                <span className="login-danger"></span>
                              </label>
                              <Select
                                className="form-control-alternative"
                                options={dispensaryoptions}
                                value={dispensary}
                                onChange={dispensaryhandleChange}
                                placeholder="Search And Select"
                                isSearchable
                                isClearable
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1);",
                                    boxShadow: state.isFocused
                                      ? "0 0 0 1px #2e37a4"
                                      : "none",
                                    "&:hover": {
                                      borderColor: state.isFocused
                                        ? "none"
                                        : "2px solid rgba(46, 55, 164, 0.1)",
                                    },
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen
                                      ? "rotate(-180deg)"
                                      : "rotate(0)",
                                    transition: "250ms",
                                    width: "35px",
                                    height: "35px",
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {registrationType == "TPA" && (
                        <>
                          <div className="col-12 col-md-6 col-xl-12">
                            <div className="form-group local-forms">
                              <label>
                                TPA / PANEL Name{" "}
                                <span className="login-danger"></span>
                              </label>
                              <Select
                                className="form-control-alternative"
                                options={options}
                                value={tpa_panelName}
                                onChange={handleChange}
                                placeholder="Search And Select"
                                isSearchable
                                isClearable
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1);",
                                    boxShadow: state.isFocused
                                      ? "0 0 0 1px #2e37a4"
                                      : "none",
                                    "&:hover": {
                                      borderColor: state.isFocused
                                        ? "none"
                                        : "2px solid rgba(46, 55, 164, 0.1)",
                                    },
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen
                                      ? "rotate(-180deg)"
                                      : "rotate(0)",
                                    transition: "250ms",
                                    width: "35px",
                                    height: "35px",
                                  }),
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-12">
                            <div className="form-group local-forms">
                              <label>
                                Insurance Company{" "}
                                <span className="login-danger"></span>
                              </label>
                              <Select
                                className="form-control-alternative"
                                options={insurance_companyoptions}
                                value={insuranceCompany}
                                onChange={insurance_companyhandleChange}
                                placeholder="Search And Select"
                                isSearchable
                                isClearable
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1);",
                                    boxShadow: state.isFocused
                                      ? "0 0 0 1px #2e37a4"
                                      : "none",
                                    "&:hover": {
                                      borderColor: state.isFocused
                                        ? "none"
                                        : "2px solid rgba(46, 55, 164, 0.1)",
                                    },
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen
                                      ? "rotate(-180deg)"
                                      : "rotate(0)",
                                    transition: "250ms",
                                    width: "35px",
                                    height: "35px",
                                  }),
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-12">
                            <div className="form-group local-forms">
                              <label>
                                Corporate <span className="login-danger"></span>
                              </label>
                              <Select
                                className="form-control-alternative"
                                options={corporateoptions}
                                value={corporate}
                                onChange={corporatehandleChange}
                                placeholder="Search And Select"
                                isSearchable
                                isClearable
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? "none"
                                      : "2px solid rgba(46, 55, 164, 0.1);",
                                    boxShadow: state.isFocused
                                      ? "0 0 0 1px #2e37a4"
                                      : "none",
                                    "&:hover": {
                                      borderColor: state.isFocused
                                        ? "none"
                                        : "2px solid rgba(46, 55, 164, 0.1)",
                                    },
                                    borderRadius: "10px",
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen
                                      ? "rotate(-180deg)"
                                      : "rotate(0)",
                                    transition: "250ms",
                                    width: "35px",
                                    height: "35px",
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Title <span className="login-danger">*</span>
                          </label>
                          <select className="form-select">
                            <option value="">Select</option>
                            <option value="">Mr</option>
                            <option value="">Miss</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            First Name <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Last Name <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Email Id <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Age <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                          />
                        </div>
                      </div>

                      {/* Date of Birth */}
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Date Of Birth <span className="login-danger"></span>
                          </label>
                          <DatePicker
                            className="form-control datetimepicker"
                            format="DD-MMM-YYYY"
                            selected={dateOfBirth}
                            onChange={(date) => setDateOfBirth(moment(date).format("YYYY-MM-DD"))}

                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group select-gender">
                          <label className="gen-label">
                            Outside Pune?<span className="login-danger">*</span>
                          </label>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name=""
                                className="form-check-input"
                                value="Yes"
                                checked={outsidePune === "Yes"}
                                onChange={(e) => setOutsidePune(e.target.value)}
                              />
                              Yes
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name=""
                                className="form-check-input"
                                value="No"
                                checked={outsidePune === "No"}
                                onChange={(e) => setOutsidePune(e.target.value)}
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* Gender */}
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group select-gender">
                          <label className="gen-label">
                            Gender<span className="login-danger">*</span>
                          </label>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name="gender"
                                className="form-check-input"
                                value="male"
                                checked={gender === "male"}
                                onChange={(e) => setGender(e.target.value)}
                              />
                              Male
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name="gender"
                                className="form-check-input"
                                value="female"
                                checked={gender === "female"}
                                onChange={(e) => setGender(e.target.value)}
                              />
                              Female
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name="gender"
                                className="form-check-input"
                                value="other"
                                checked={gender === "other"}
                                onChange={(e) => setGender(e.target.value)}
                              />
                              Other
                            </label>
                          </div>
                        </div>
                      </div>

                      {/* Country */}
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Country <span className="login-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            value={selectedCountry}
                            onChange={(e) => setSelectedCountry(e.target.value)}
                            placeholder="Select Country"
                          >
                            <option value="">Select Country</option>
                            <option value="India">India</option>
                          </select>
                        </div>
                      </div>
                      {/* State */}
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            State/Province{" "}
                            <span className="login-danger">*</span>
                          </label>
                          <Select
                            className="form-control-alternative"
                            options={stateoptions}
                            value={selectedState}
                            onChange={statehandleChange}
                            placeholder="Search And Select"
                            isSearchable
                            isClearable
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? "none"
                                  : "2px solid rgba(46, 55, 164, 0.1);",
                                boxShadow: state.isFocused
                                  ? "0 0 0 1px #2e37a4"
                                  : "none",
                                "&:hover": {
                                  borderColor: state.isFocused
                                    ? "none"
                                    : "2px solid rgba(46, 55, 164, 0.1)",
                                },
                                borderRadius: "10px",
                                fontSize: "14px",
                                minHeight: "45px",
                              }),
                              dropdownIndicator: (base, state) => ({
                                ...base,
                                transform: state.selectProps.menuIsOpen
                                  ? "rotate(-180deg)"
                                  : "rotate(0)",
                                transition: "250ms",
                                width: "35px",
                                height: "35px",
                              }),
                            }}
                          />
                        </div>
                      </div>

                      {/* Form submission buttons */}
                      <div className="col-12">
                        <div className="doctor-submit text-end">
                          <button
                            type="submit"
                            className="btn btn-primary submit-form me-2"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              navigate("/patientslist");
                            }}
                            className="btn btn-primary cancel-form"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <h5>Contact Details</h5>
                    <hr></hr>
                    <br></br>
                    {/* Mobile */}
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Mobile <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Secondary Number{" "}
                          <span className="login-danger"></span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={mobileNumber2}
                          onChange={(e) => setMobileNumber2(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* Address */}
                    <div className="col-12 col-sm-12">
                      <div className="form-group local-forms">
                        <label>
                          Address <span className="login-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          rows={3}
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* City */}
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          City/District <span className="login-danger">*</span>
                        </label>

                        <input
                          className="form-control"
                          type="text"
                          value={selectedCity}
                          onChange={(e) => setSelectedCity(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <h5>Other Details</h5>
                      <hr></hr>
                      <br></br>
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Referred By <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={referredBy}
                            onChange={(e) => setreferredBy(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-12">
                        <div className="form-group local-forms">
                          <label>
                            Department <span className="login-danger"></span>
                          </label>
                          <Select
                            className="form-control-alternative"
                            options={departmentoptions}
                            value={department}
                            onChange={departmenthandleChange}
                            placeholder="Search And Select"
                            isSearchable
                            isClearable
                            styles={{
                              menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? "none"
                                  : "2px solid rgba(46, 55, 164, 0.1);",
                                boxShadow: state.isFocused
                                  ? "0 0 0 1px #2e37a4"
                                  : "none",
                                "&:hover": {
                                  borderColor: state.isFocused
                                    ? "none"
                                    : "2px solid rgba(46, 55, 164, 0.1)",
                                },
                                borderRadius: "10px",
                                fontSize: "14px",
                                minHeight: "45px",
                              }),
                              dropdownIndicator: (base, state) => ({
                                ...base,
                                transform: state.selectProps.menuIsOpen
                                  ? "rotate(-180deg)"
                                  : "rotate(0)",
                                transition: "250ms",
                                width: "35px",
                                height: "35px",
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center">
                      {/* Left side: Label */}
                      <div className="col-4">
                        <label htmlFor="imageUpload">
                          <b>Browse Photo</b>
                        </label>
                        <br></br>
                        <b>
                          {" "}
                          <p className="text-danger">File Type : .jpg & .png</p>
                        </b>
                        <b>
                          {" "}
                          <p className="text-danger">
                            File Size : Less Than 2 MB
                          </p>
                        </b>
                      </div>

                      {/* Center: Image Circle */}
                      <div className="col-4 text-center">
                        {selectedImage ? (
                          <img
                            src={selectedImage}
                            alt="Preview"
                            className="img-thumbnail rounded-circle"
                            style={{ width: "100px", height: "100px" }}
                          />
                        ) : (
                          <div
                            className="img-thumbnail rounded-circle"
                            style={{
                              width: "100px",
                              height: "100px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#f0f0f0",
                              color: "#ccc",
                            }}
                          >
                            No Image
                          </div>
                        )}
                      </div>

                      {/* Right side: Buttons */}
                      <div className="col-4 text-right">
                        <button
                          className="btn btn-primary mr-2 me-2"
                          onClick={triggerFileInput}
                        >
                          Browse
                        </button>{" "}
                        <button className="btn btn-info mr-2 me-2">Cam</button>
                        <button className="btn btn-danger" onClick={clearImage}>
                          Clear
                        </button>
                      </div>

                      {/* Hidden file input */}
                      <input
                        id="imageUpload"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPatients;
