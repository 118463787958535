/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";
import Select from "react-select";
import html2pdf from "html2pdf.js";

const SmsList = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [AppointmentList, setAppointmentList] = useState([]);
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");

  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [paymentmethod, setPaymentmethod] = useState(false);
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("NA");
  const [patientName, setPatientName] = useState("");
  const [OpdType, setOpdType] = useState("");

  // const columns = [
  //   {
  //     title: "Sr No",
  //     dataIndex: "srNo",
  //     render: (text, record, index) => index + 1,
  //   },
  //   {
  //     title: "Patient",
  //     dataIndex: "patient_name",
  //     sorter: (a, b) => a.patient_name.length - b.patient_name.length,
  //   },
  //   {
  //     title: "Doctor",
  //     dataIndex: "doctor_name",
  //     sorter: (a, b) => a.doctor_name.length - b.doctor_name.length,
  //   },

  //   {
  //     title: "Mobile Number",
  //     dataIndex: "mobile_number",
  //     sorter: (a, b) => a.mobile_number.length - b.mobile_number.length,
  //   },
  //   {
  //     title: "Gender",
  //     dataIndex: "gender",
  //     sorter: (a, b) => a.gender.length - b.gender.length,
  //   },
  //   {
  //     title: "Actions",
  //     dataIndex: null, // You can keep this null if it's just for the action
  //     render: (text, record) => {
  //       return (
  //         <>
  //           {record.status == 1 ? (
  //             <a
  //               className="badge bg-danger-light"
  //               onClick={(e) => {
  //                 e.stopPropagation(); // Prevent the row click event
  //                 setShowPayment(record.id); // Only trigger when amount is empty or null
  //                 setPatientName(record.patient_name);
  //                 setOpdType(record.opd_type);
  //               }}
  //             >
  //               Take Payment
  //             </a>
  //           ) : (
  //             <a
  //               className="badge bg-success-light"
  //               onClick={(e) => {
  //                 e.stopPropagation(); // Prevent the row click event, but don't open payment modal
  //               }}
  //             >
  //               Payment Done
  //             </a>
  //           )}
  //           <br></br>
  //           <br></br>
  //           <a className="badge bg-primary-light" onClick={""}>
  //             Send Prescription
  //           </a>
  //           <br></br>
  //           <br></br>
  //           {record.status == 0 && (
  //             <a className="badge bg-success-light" onClick={""}>
  //               Send Receipt
  //             </a>
  //           )}
  //         </>
  //       );
  //     },
  //   },

  //   {
  //     title: "",
  //     dataIndex: "id",
  //     render: (text, record) => (
  //       <>
  //         <div className="text-end">
  //           <div className="dropdown dropdown-action">
  //             <Link
  //               to="#"
  //               className="action-icon dropdown-toggle"
  //               data-bs-toggle="dropdown"
  //               aria-expanded="false"
  //             >
  //               <i className="fas fa-ellipsis-v" />
  //             </Link>
  //             <div className="dropdown-menu dropdown-menu-end">
  //               {/* <Link className="dropdown-item" to={`/infoappointment/${record.id}`}>
  //                     <i className="far fa-file me-2" />
  //                     Info
  //                   </Link> */}
  //               <Link
  //                 className="dropdown-item"
  //                 to={`/editappointmentlist/${record.id}`}
  //               >
  //                 <i className="far fa-edit me-2" />
  //                 Edit
  //               </Link>
  //               <Link
  //                 className="dropdown-item"
  //                 onClick={() => setShow(record.id)}
  //               >
  //                 <i className="fa fa-trash-alt m-r-5"></i> Delete
  //               </Link>
  //             </div>
  //           </div>
  //         </div>
  //       </>
  //     ),
  //   },
  // ];

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    setUserData(data.data);
  }, []);

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">SMS </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Sms List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">SMS Dashboard</h4>
                      <ul className="nav nav-tabs nav-justified">
                        <li className="nav-item">
                          <Link
                            className="nav-link active"
                            to="#basic-justified-tab1"
                            data-bs-toggle="tab"
                          >
                            <i className="fas fa-tachometer-alt"></i> Overview
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="#basic-justified-tab2"
                            data-bs-toggle="tab"
                          >
                            <i className="fas fa-sms"></i> SMS
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="#basic-justified-tab3"
                            data-bs-toggle="tab"
                          >
                            <i className="fab fa-whatsapp"></i> WhatsApp
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="#basic-justified-tab4"
                            data-bs-toggle="tab"
                          >
                            <i className="fas fa-envelope"></i> Email
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <div className="card-body">
                      {/* <h4 className="card-title">SMS Dashboard</h4> */}
                      <div className="tab-content">
                        <div
                          className="tab-pane show active"
                          id="basic-justified-tab1"
                        >
                          <div className="row">
                            {/* Left Section - Pending SMS */}
                            <div className="col-sm-6">
                              <div className="card">
                                <div className="card-body">
                                  <h5 className="card-title">Pending SMS</h5>
                                  <p>No Record Exist</p>
                                  <div className="d-flex justify-content-between">
                                    <button className="btn btn-danger">
                                      Cancel All
                                    </button>
                                    <button className="btn btn-primary">
                                      Resend All
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Right Section - SMS Credit and Today's B'day */}
                            <div className="col-sm-6">
                              <div className="card">
                                <div className="card-body">
                                  <div className="sms-credit">
                                    <h5>
                                      <i className="fa fa-money"></i> Available
                                      SMS Credit:
                                    </h5>
                                    <p>0</p>
                                  </div>
                                  <hr />
                                  <div className="todays-bday">
                                    <h6>
                                      <i className="fa fa-birthday-cake"></i>{" "}
                                      Today&apos;s B&apos;day
                                    </h6>
                                    <p>No Record Exist</p>
                                    <button className="btn btn-primary">
                                      Wish To All
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="basic-justified-tab2">
                          <div className="card card-table show-entire">
                            <div className="card-body">
                              {/* Table Header */}
                              <div className="page-table-header mb-2">
                                <div className="row align-items-center">
                                  <div className="col">
                                    <div className="doctor-table-blk">
                                      <h3>Sms List</h3>
                                      <div className="doctor-search-blk">
                                        <div className="top-nav-search table-search-blk">
                                          <form>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search here"
                                            />
                                            <Link className="btn">
                                              <img src={searchnormal} alt="#" />
                                            </Link>
                                          </form>
                                        </div>
                                        <div className="add-group">
                                          <Link
                                            to="/addappoinments"
                                            className="btn btn-primary add-pluss ms-2"
                                          >
                                            <img src={plusicon} alt="#" />
                                          </Link>
                                          <Link
                                            to="#"
                                            className="btn btn-primary doctor-refresh ms-2"
                                          >
                                            <img src={refreshicon} alt="#" />
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto text-end float-end ms-auto download-grp">
                                    <Link to="#" className=" me-2">
                                      <img src={pdficon} alt="#" />
                                    </Link>
                                    <Link to="#" className=" me-2"></Link>
                                    <Link to="#" className=" me-2">
                                      <img src={pdficon3} alt="#" />
                                    </Link>
                                    <Link to="#">
                                      <img src={pdficon4} alt="#" />
                                    </Link>
                                  </div>
                                </div>
                                {/* <!-- Filters Row --> */}
                                <div className="row align-items-center my-3">
                                  <div className="col-md-3">
                                    <label>Filter by Date</label>
                                    <div className="input-group align-items-baseline">
                                      <DatePicker
                                        className="form-control datetimepicker" // Keeping it like input
                                        selected={fromdate}
                                        onChange={(date) => setFromdate(date)}
                                        placeholderText="From"
                                        dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                      />
                                      <span className="input-group-text">
                                        to
                                      </span>
                                      <DatePicker
                                        className="form-control datetimepicker" // Keeping it like input
                                        selected={todate}
                                        onChange={(date) => setTodate(date)}
                                        placeholderText="To"
                                        dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                      />
                                    </div>
                                  </div>
                                  {/* <!-- Filter and Reset Buttons --> */}
                                  <div className="col-md-3 d-flex justify-content-end pt-3 doctor-submit">
                                    <button
                                      className="btn btn-primary me-2"
                                      id="filter-btn"
                                    >
                                      Filter
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary cancel-form"
                                    >
                                      Reset
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="basic-justified-tab3">
                          <div className="card card-table show-entire">
                            <div className="card-body">
                              {/* Table Header */}
                              <div className="page-table-header mb-2">
                                <div className="row align-items-center">
                                  <div className="col">
                                    <div className="doctor-table-blk">
                                      <h3>Whatsapp Message List</h3>
                                      <div className="doctor-search-blk">
                                        <div className="top-nav-search table-search-blk">
                                          <form>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search here"
                                            />
                                            <Link className="btn">
                                              <img src={searchnormal} alt="#" />
                                            </Link>
                                          </form>
                                        </div>
                                        <div className="add-group">
                                          <Link
                                            to="/addappoinments"
                                            className="btn btn-primary add-pluss ms-2"
                                          >
                                            <img src={plusicon} alt="#" />
                                          </Link>
                                          <Link
                                            to="#"
                                            className="btn btn-primary doctor-refresh ms-2"
                                          >
                                            <img src={refreshicon} alt="#" />
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto text-end float-end ms-auto download-grp">
                                    <Link to="#" className=" me-2">
                                      <img src={pdficon} alt="#" />
                                    </Link>
                                    <Link to="#" className=" me-2"></Link>
                                    <Link to="#" className=" me-2">
                                      <img src={pdficon3} alt="#" />
                                    </Link>
                                    <Link to="#">
                                      <img src={pdficon4} alt="#" />
                                    </Link>
                                  </div>
                                </div>
                                {/* <!-- Filters Row --> */}
                                <div className="row align-items-center my-3">
                                  <div className="col-md-3">
                                    <label>Filter by Date</label>
                                    <div className="input-group align-items-baseline">
                                      <DatePicker
                                        className="form-control datetimepicker" // Keeping it like input
                                        selected={fromdate}
                                        onChange={(date) => setFromdate(date)}
                                        placeholderText="From"
                                        dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                      />
                                      <span className="input-group-text">
                                        to
                                      </span>
                                      <DatePicker
                                        className="form-control datetimepicker" // Keeping it like input
                                        selected={todate}
                                        onChange={(date) => setTodate(date)}
                                        placeholderText="To"
                                        dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                      />
                                    </div>
                                  </div>
                                  {/* <!-- Filter and Reset Buttons --> */}
                                  <div className="col-md-3 d-flex justify-content-end pt-3 doctor-submit">
                                    <button
                                      className="btn btn-primary me-2"
                                      id="filter-btn"
                                    >
                                      Filter
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary cancel-form"
                                    >
                                      Reset
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="basic-justified-tab4">
                          <div className="tab-pane" id="basic-justified-tab2">
                            <div className="card card-table show-entire">
                              <div className="card-body">
                                {/* Table Header */}
                                <div className="page-table-header mb-2">
                                  <div className="row align-items-center">
                                    <div className="col">
                                      <div className="doctor-table-blk">
                                        <h3>Email List</h3>
                                        <div className="doctor-search-blk">
                                          <div className="top-nav-search table-search-blk">
                                            <form>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search here"
                                              />
                                              <Link className="btn">
                                                <img
                                                  src={searchnormal}
                                                  alt="#"
                                                />
                                              </Link>
                                            </form>
                                          </div>
                                          <div className="add-group">
                                            <Link
                                              to="/addappoinments"
                                              className="btn btn-primary add-pluss ms-2"
                                            >
                                              <img src={plusicon} alt="#" />
                                            </Link>
                                            <Link
                                              to="#"
                                              className="btn btn-primary doctor-refresh ms-2"
                                            >
                                              <img src={refreshicon} alt="#" />
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-auto text-end float-end ms-auto download-grp">
                                      <Link to="#" className=" me-2">
                                        <img src={pdficon} alt="#" />
                                      </Link>
                                      <Link to="#" className=" me-2"></Link>
                                      <Link to="#" className=" me-2">
                                        <img src={pdficon3} alt="#" />
                                      </Link>
                                      <Link to="#">
                                        <img src={pdficon4} alt="#" />
                                      </Link>
                                    </div>
                                  </div>
                                  {/* <!-- Filters Row --> */}
                                  <div className="row align-items-center my-3">
                                    <div className="col-md-3">
                                      <label>Filter by Date</label>
                                      <div className="input-group align-items-baseline">
                                        <DatePicker
                                          className="form-control datetimepicker" // Keeping it like input
                                          selected={fromdate}
                                          onChange={(date) => setFromdate(date)}
                                          placeholderText="From"
                                          dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                        />
                                        <span className="input-group-text">
                                          to
                                        </span>
                                        <DatePicker
                                          className="form-control datetimepicker" // Keeping it like input
                                          selected={todate}
                                          onChange={(date) => setTodate(date)}
                                          placeholderText="To"
                                          dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                        />
                                      </div>
                                    </div>
                                    {/* <!-- Filter and Reset Buttons --> */}
                                    <div className="col-md-3 d-flex justify-content-end pt-3 doctor-submit">
                                      <button
                                        className="btn btn-primary me-2"
                                        id="filter-btn"
                                      >
                                        Filter
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary cancel-form"
                                      >
                                        Reset
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmsList;
