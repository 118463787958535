/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import axios from "axios";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import toast from 'react-hot-toast';
import { loadingService } from "../../helper/observer";
import AuthApi from "../../helper/authApi";


const AddTestCategory = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState();
  const [userData, setUserData] = useState(null);


 
  const SubmitForm = async (e) => {
    e.preventDefault();

    if (
      !category 
    ) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      branch_id:userData.hospitalid ?? userData.branch_id,
      category: category,
    };

    try {
      loadingService.next(true);
      const { data } = await AuthApi.post(Api.testcategoryAdd, payload);
      loadingService.next(false);
      if (data) {
        toast.success("Success");
        navigate("/testcategorylist");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem('userdata');
    let data = JSON.parse(udata);

    setUserData(data.data);
    
  }, []);
  console.log(userData)

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="add-appoinment"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Category </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Category</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Category Details</h4>
                          </div>
                        </div>
                    
                        {/* name */}
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Category Name <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            />
                          </div>
                        </div>
                        
                        

                       
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                navigate("/testcategorylist");
                              }}
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddTestCategory;
