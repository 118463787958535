import React from 'react';
import axios from 'axios';

class HttpInterceptor extends React.Component {
  setupInterceptor = async () => {
    axios.interceptors.request.use(async (req) => {
      let cType = req.headers.getContentType();
      if (cType) {
      // if (req.url.includes('partitioncreate') || req.url.includes('partitionupdate')) {
        req.headers['Content-Type'] = 'multipart/form-data';
        req.headers['Accept'] = 'multipart/form-data';
      } else {
        req.headers['Content-Type'] = 'application/json';
      }
      try {
        let udata = localStorage.getItem('userdata');
        if (udata) {
          const userData = JSON.parse(udata);
          if (userData && userData.access_token) {
            const token = `Bearer ${userData.access_token}`;
            req.headers['Authorization'] = token;
          }
        }
      } catch (e) {
        console.log('catch e', e)
      }
      return req;
    });
  };
}

export default new HttpInterceptor();
