import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { DatePicker } from "antd";
import Select from "react-select";
import { loadingService } from "../../helper/observer";
import moment from "moment";

const EditDoctor = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [education, setEducation] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [address, setAddress] = useState("");
  const [age, setAge] = useState("");

  const getPatientInfo = async () => {
    try {
      const { data, message, status } = await AuthApi.get(
        `${Api.doctorInfo}/${id}`
      );
      if (!data) {
        toast.error(message);
        if (status === 401) navigate("/login");
        return;
      }
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setAge(data.age);
      setMobileNumber(data.mobile_number);
      setDateOfBirth(data.date_of_birth);
      setGender(data.gender);
      setEducation(data.education);
      setDesignation(data.designation);
      setDepartment(data.department);
      setAddress(data.address);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPatientInfo();
  }, []);

  const SubmitForm = async (e) => {
    e.preventDefault();

    if (!firstName || !mobileNumber) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      first_name: firstName,
      last_name: lastName,
      age: age,
      mobile_number: mobileNumber,
      date_of_birth: dateOfBirth,
      gender: gender,
      education: education,
      designation: designation,
      department: department.toString(),
      address: address,
    };

    try {
      loadingService.next(true);

      const { status } = await AuthApi.post(
        `${Api.doctorUpdate}/${id}`,
        payload
      );
      loadingService.next(false);

      if (status) {
        toast.success("Update Success");
        navigate("/doctorlist");
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  const departmentOptions = [
    { value: "", label: "Select" },
    { value: "NURSING", label: "NURSING" },
    { value: "ICU", label: "ICU" },
    { value: "HOUSEKEEPING", label: "HOUSEKEEPING" },
    { value: "SURGICAL", label: "SURGICAL" },
    { value: "CSSD", label: "CSSD" },
    { value: "MRD", label: "MRD" },
    { value: "OPD", label: "OPD" },
    { value: "PATHOLOGY", label: "PATHOLOGY" },
    { value: "X-RAY", label: "X-RAY" },
    { value: "RADIOLOGY", label: "RADIOLOGY" },
    { value: "IPD", label: "IPD" },
  ];

  const departmentoptions = departmentOptions.map((e) => ({
    value: e.value,
    label: e.label,
  }));

  const departmenthandleChange = (selectedOption) => {
    setDepartment(selectedOption ? selectedOption.value : null);
  };

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Doctors </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Edit Doctor</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={SubmitForm}>
                    <div className="row">
                      {/* Form fields */}
                      {/* First Name */}
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>
                            First Name <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>
                            Last Name <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>
                            Age <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* Mobile */}
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            Mobile <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            maxLength={10}
                          />
                        </div>
                      </div>
                      {/* Date of Birth */}
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms ">
                          <label>
                            Date Of Birth <span className="login-danger"></span>
                          </label>

                          <DatePicker
                            className="form-control datetimepicker"
                            format="DD-MMM-YYYY"
                            selected={dateOfBirth}
                            onChange={(date) =>
                              setDateOfBirth(
                                moment(date).format("YYYY-MM-DD")
                              )
                            }
                          />
                        </div>
                      </div>

                      {/* Gender */}
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group select-gender">
                          <label className="gen-label">
                            Gender<span className="login-danger">*</span>
                          </label>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name="gender"
                                className="form-check-input"
                                value="male"
                                checked={gender === "male"}
                                onChange={(e) => setGender(e.target.value)}
                              />
                              Male
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name="gender"
                                className="form-check-input"
                                value="female"
                                checked={gender === "female"}
                                onChange={(e) => setGender(e.target.value)}
                              />
                              Female
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name="gender"
                                className="form-check-input"
                                value="other"
                                checked={gender === "other"}
                                onChange={(e) => setGender(e.target.value)}
                              />
                              Other
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* Education */}
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>
                            Education <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={education}
                            onChange={(e) => setEducation(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* Designation */}
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>
                            Designation <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={designation}
                            onChange={(e) => setDesignation(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* Department */}
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>
                            Department <span className="login-danger">*</span>
                          </label>
                          <Select
                            className="form-control-alternative"
                            options={departmentoptions}
                            value={departmentoptions.find(
                              (option) => option.value == department
                            )}
                            onChange={departmenthandleChange}
                            placeholder="Search And Select"
                            isSearchable
                            isClearable
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused
                                  ? "none"
                                  : "2px solid rgba(46, 55, 164, 0.1);",
                                boxShadow: state.isFocused
                                  ? "0 0 0 1px #2e37a4"
                                  : "none",
                                "&:hover": {
                                  borderColor: state.isFocused
                                    ? "none"
                                    : "2px solid rgba(46, 55, 164, 0.1)",
                                },
                                borderRadius: "10px",
                                fontSize: "14px",
                                minHeight: "45px",
                              }),
                              dropdownIndicator: (base, state) => ({
                                ...base,
                                transform: state.selectProps.menuIsOpen
                                  ? "rotate(-180deg)"
                                  : "rotate(0)",
                                transition: "250ms",
                                width: "35px",
                                height: "35px",
                              }),
                            }}
                          />
                        </div>
                      </div>
                      {/* Address */}
                      <div className="col-12 col-sm-12">
                        <div className="form-group local-forms">
                          <label>
                            Address <span className="login-danger">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            rows={3}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                      </div>

                      {/* Form submission buttons */}
                      <div className="col-12">
                        <div className="doctor-submit text-end">
                          <button
                            type="submit"
                            className="btn btn-primary submit-form me-2"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              navigate("/doctorlist");
                            }}
                            className="btn btn-primary cancel-form"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDoctor;
