/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";
import Select from "react-select";

import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
  imguser,
} from "../imagepath";
import { Link } from "react-router-dom";
import Api from "../../helper/api";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";
import AuthApi from "../../helper/authApi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const IpdViewFile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [PatientData, setPatientData] = useState({});
  const [doctor, setDoctor] = useState(null);
  const [DoctorList, setDoctorList] = useState([]);

  // Inside your AddIpdSlave component
  const [checkboxes, setCheckboxes] = useState({
    review_opd_doctors_notes: false,
    handover_sheet_available: false,
    verify_ongoing_medications: false,
    diagnosis_treatment_plan_clear: false,
    crosscheck_allergies: false,
    prescription_details_clear: false,
    verify_current_medications: false,
    doublecheck_timing_doses: false,
    confirm_pending_investigations: false,
  });

  const checklistItems = [
    {
      id: "review_opd_doctors_notes",
      label: "Review OPD Doctor's Notes",
      inputType: "text",
    },
    {
      id: "handover_sheet_available",
      label: "Ensure Handover Sheet Available",
      inputType: "text",
    },
    {
      id: "verify_ongoing_medications",
      label: "Verify Ongoing Medications",
      inputType: "text",
    },
    {
      id: "diagnosis_treatment_plan_clear",
      label: "Clear Diagnosis & Provisional Treatment Plan",
      inputType: "text",
    },
    {
      id: "crosscheck_allergies",
      label: "Cross-Check Allergies List",
      inputType: "text",
    },
    {
      id: "prescription_details_clear",
      label: "Ensure Prescription Details are Clear",
      inputType: "text",
    },
    {
      id: "verify_current_medications",
      label: "Verify Injections, Medications, or IV Fluids Being Administered",
      inputType: "text",
    },
    {
      id: "doublecheck_timing_doses",
      label: "Double-Check Timing & Doses for Ongoing Medications",
      inputType: "text",
    },
    {
      id: "confirm_pending_investigations",
      label: "Confirm Pending Investigations (Lab Tests, Scans)",
      inputType: "text",
    },
  ];

  // Handler for checkbox toggle
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setCheckboxes((prev) => ({ ...prev, [id]: checked }));
    setInputValues((prev) => ({ ...prev, [id]: checked ? "" : "" }));
  };

  const getDoctorList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.doctorList;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchdoctorList}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchdoctorList}/${user.branch_id}`;
    } else {
      url = `${Api.branchdoctorList}/${user.branch_id}`;
    }
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setDoctorList(data);
  };

  const options = DoctorList.map((doctor) => ({
    value: doctor.id,
    label: `${doctor.first_name} ${doctor.last_name}`,
  }));

  const handleChange = (selectedOption) => {
    setDoctor(selectedOption);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);

    setUserData(data.data);
  }, []);
  console.log(userData);

  const getOpdList = async () => {
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(
      `${Api.ipdformfirstinfo}/${id}`
    );
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }

    setPatientData(data.data[0]);
  };

  useEffect(() => {
    getOpdList();
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getDoctorList(userData);
    }
  }, [userData]);

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="add-appoinment"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Pre OperativePage </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">
                      Pre OperativePage
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="card-box profile-header">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img className="avatar" src={imguser} alt="#" />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {PatientData.patient_name}
                            </h3>
                            {/* <small className="text-muted">Gynecologist</small> */}
                            <div className="staff-id">
                              Ipd ID : {PatientData.ipd_id}
                            </div>
                            <div className="staff-id">
                              Patient ID : {PatientData.patientId}
                            </div>
                            <div className="staff-msg">
                              <Link to="/chat" className="btn"></Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <span className="title">Age:</span>
                              <span className="text">{PatientData.age}</span>
                            </li>
                            <li>
                              <span className="title">Gender:</span>
                              <span className="text">{PatientData.gender}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  {/* <h6 className="card-title">Checklist</h6> */}
                  <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        to="#bottom-justified-tab1"
                        data-bs-toggle="tab"
                      >
                        DOCTOR NOTE
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#bottom-justified-tab2"
                        data-bs-toggle="tab"
                      >
                        NURSE NOTE
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#bottom-justified-tab3"
                        data-bs-toggle="tab"
                      >
                        MEDICINE SUPPLY
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#bottom-justified-tab4"
                        data-bs-toggle="tab"
                      >
                        LAB CHARGES
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#bottom-justified-tab5"
                        data-bs-toggle="tab"
                      >
                        RAD CHARGES
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#bottom-justified-tab6"
                        data-bs-toggle="tab"
                      >
                        TREATMENT INFO
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#bottom-justified-tab7"
                        data-bs-toggle="tab"
                      >
                        CONSENT FORM
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#bottom-justified-tab8"
                        data-bs-toggle="tab"
                      >
                        ATTACHMENT
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane show active"
                      id="bottom-justified-tab1"
                    >
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="card">
                            <div className="card-body border">
                              <div className="col-12">
                                <div className="form-heading">
                                  <h4 className="text-danger">
                                    + Add Doctor Clinic Note
                                  </h4>
                                </div>
                                <hr></hr>
                                <p>No Record Exist</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="card">
                            <div className="card-body border">
                              <div className="col-12">
                                <div className="form-heading">
                                  <h4 className="text-primary">
                                    Pending Medicine
                                  </h4>
                                </div>
                                <hr></hr>
                                <p>No Record Exist</p>
                              </div>
                            </div>
                            <br></br>
                            <div className="card-body border">
                              <div className="col-12">
                                <div className="form-heading">
                                  <h4 className="text-primary">Pending Diet</h4>
                                </div>
                                <hr></hr>
                                <p>No Record Exist</p>
                              </div>
                            </div>
                            <br></br>
                            <div className="card-body border">
                              <div className="col-12">
                                <div className="form-heading">
                                  <h4 className="text-primary">
                                    Pending Investigation/Test
                                  </h4>
                                </div>
                                <hr></hr>
                                <p>No Record Exist</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="bottom-justified-tab2">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="col-12">
                                <div className="form-heading">
                                  <h4 className="text-danger">
                                    + Add Nurse Note
                                  </h4>
                                </div>
                                <hr></hr>
                                <p>No Record Exist</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="bottom-justified-tab3">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="col-12">
                                <div className="form-heading d-flex justify-content-between">
                                  <div className="d-flex">
                                    <h4 className="text-danger">
                                      + Add Medicine Supply
                                    </h4>
                                    &nbsp; &nbsp;
                                    <h4 className="text-danger">🖨 Print</h4>
                                  </div>
                                  <div>
                                    <button className="btn btn-primary me-2">
                                      Credit : 0
                                    </button>
                                    <button className="btn btn-success bed occupied me-2">
                                      Sale Discount : 0
                                    </button>
                                    <button className="btn btn-danger bed available me-2">
                                      Total Balance : 0
                                    </button>
                                    <button className="btn btn-warning bed available">
                                      Total : 0.00
                                    </button>
                                  </div>
                                </div>
                                <hr></hr>
                                <br></br>
                                <div className="d-flex">
                                  <button className="btn btn-success me-2">
                                    <b>SALE BILL</b>
                                  </button>
                                  <p className="text-primary">Add New</p>
                                </div>
                                <p>No Medicine Sale Bill Record Found</p>
                                <br></br>
                                <div className="d-flex">
                                  <button className="btn btn-danger me-2">
                                    <b>SALE RETURN</b>
                                  </button>
                                  <p className="text-primary">Add New</p>
                                </div>
                                <p>No Sale Return Record Found</p>
                                <br></br>
                                <div className="d-flex">
                                  <button className="btn btn-warning me-2">
                                    <b>IPD MEDICINE SUPPLY</b>
                                  </button>
                                  <p className="text-primary">Add New</p>
                                </div>
                                <p>No Medicine Sale Record Found</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="bottom-justified-tab4">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="col-12">
                                <div className="form-heading d-flex justify-content-between">
                                  <div className="d-flex">
                                    <h4 className="text-danger">🖨 Print</h4>
                                  </div>
                                  <div>
                                    <button className="btn btn-warning bed available">
                                      Total : 0.00
                                    </button>
                                  </div>
                                </div>
                                <hr></hr>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="bottom-justified-tab5">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="col-12">
                                <div className="form-heading d-flex justify-content-between">
                                  <div className="d-flex">
                                    <h4 className="text-danger">🖨 Print</h4>
                                  </div>
                                  <div>
                                    <button className="btn btn-warning bed available">
                                      Total : 0.00
                                    </button>
                                  </div>
                                </div>
                                <hr></hr>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="bottom-justified-tab6">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                            <div
                              className="card-body border"
                              style={{ backgroundColor: "#B6ADA1" }}
                            >
                              <div className="col-12 d-flex justify-content-evenly">
                                <div>
                                  <b>Booked Session : 0</b>
                                </div>
                                <div>
                                  <b>Booked Session : 0</b>
                                </div>
                                <div>
                                  <b>Total Payable : 0</b>
                                </div>
                                <div>
                                  <b>|&nbsp;&nbsp; Tital Paid : 0</b>
                                </div>
                                <div>
                                  <b>|&nbsp;&nbsp; Balance : 0</b>
                                </div>
                              </div>
                              <button className="btn btn-primary">
                                + Add New Treatment
                              </button>
                            </div>
                            <br></br>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="card">
                            <div className="card-body border">
                              <div className="col-12">
                                <div className="form-heading">
                                  <h4 className="text-primary">
                                    Complete Session Detail
                                  </h4>
                                </div>
                                <hr></hr>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="card">
                            <div className="card-body border">
                              <div className="col-12">
                                <div className="form-heading">
                                  <h4 className="text-primary">
                                    All Payment Details
                                  </h4>
                                </div>
                                <hr></hr>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="bottom-justified-tab7">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                            <div className="card-body border">
                              <div className="col-12">
                                <div className="form-heading">
                                  <h4 className="text-danger">
                                    + Add Ipd Consent Form
                                  </h4>
                                </div>
                                <hr></hr>
                                <br></br>
                                <table className="table table-responsive">
                                  <thead className="text-primary">
                                    <tr>
                                      <td>
                                        <b>SR.NO.</b>
                                      </td>
                                      <td>
                                        <b>DATE</b>
                                      </td>
                                      <td>
                                        <b>CONSENT TYPE</b>
                                      </td>
                                      <td>
                                        <b>DATE</b>
                                      </td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>No Record Exist</td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="bottom-justified-tab8">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                            <div className="card-body">
                              <div className="col-12">
                                <div className="form-heading">
                                  <button className="btn btn-light">
                                    ↑Upload File
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default IpdViewFile;
