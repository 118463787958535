/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import axios from "axios";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";
import AuthApi from "../../helper/authApi";
import "@fortawesome/fontawesome-free/css/all.min.css";
 

const SettingPage = () => {
  const navigate = useNavigate();
  const [DoctorCharges, setDoctorCharges] = useState();
  const [NurseCharges, setNurseCharges] = useState();
  const [WardBoyCharges, setWardBoyCharges] = useState();
  const [StaffCharges, setStaffCharges] = useState();
  const [userData, setUserData] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [opdshowDropdown, setOPDShowDropdown] = useState(false);
  const [billingshowDropdown, setBillingShowDropdown] = useState(false);

  const handleActionClick = () => {
    setShowDropdown(!showDropdown); // Toggle dropdown visibility
  };
  const handleopdActionClick = () => {
    setOPDShowDropdown(!opdshowDropdown); // Toggle dropdown visibility
  };
  const handlebillingActionClick = () => {
    setBillingShowDropdown(!billingshowDropdown); // Toggle dropdown visibility
  };

  const SubmitForm = async (e) => {
    e.preventDefault();

    if ((!DoctorCharges, !NurseCharges, !WardBoyCharges, !StaffCharges)) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      branch_id: userData.hospitalid ?? userData.branch_id,
      doctor_charges: DoctorCharges,
      nurse_charges: NurseCharges,
      wardboy_charges: WardBoyCharges,
      staff_charges: StaffCharges,
    };

    try {
      loadingService.next(true);
      const { data } = await AuthApi.post(
        `${Api.mastersettings}/${userData.hospitalid ?? userData.branch_id}`,
        payload
      );
      loadingService.next(false);
      if (data) {
        toast.success("Updated SuccessFully");
        fetchData();
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);

    setUserData(data.data);
  }, []);
  console.log(userData);

  const fetchData = async () => {
    try {
      const udata = localStorage.getItem("userdata");
      const data = JSON.parse(udata);
      if (data) {
        loadingService.next(true);
        const response = await AuthApi.get(
          `${Api.getsettingdata}/${data.data.hospitalid ?? data.data.branch_id}`
        );
        loadingService.next(false);
        
        setDoctorCharges(response.data.doctor_charges);
        setNurseCharges(response.data.nurse_charges);
        setWardBoyCharges(response.data.wardboy_charges);
        setStaffCharges(response.data.staff_charges);
      }
    } catch (error) {
      // Handle errors
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="add-appoinment"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Settings </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Settings</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <ul className="nav nav-tabs nav-tabs-bottom">
                    
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link"
                        href="#!"
                        onClick={handleActionClick}
                      >
                        Setting &nbsp;<i className="fas fa-caret-down"></i>
                      </a>
                      {/* Dropdown Menu */}
                      {showDropdown && (
                        <ul className="dropdown-menu show">
                          <li>
                            <a className="dropdown-item" href="#!">
                              Profile
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#!">
                              System Setting
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#!">
                              User Setting
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#!">
                              Hospital Setting
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#!">
                              Financial Year
                            </a>
                          </li>
                          <li><a className="dropdown-item" href="#!">Auto Code</a></li>
                          <li><a className="dropdown-item" href="#!">Sync Server Setting</a></li>
                          <li><a className="dropdown-item" href="#!">Backup Management</a></li>
                          <li><a className="dropdown-item" href="#!">Side Menu Setting</a></li>
                          <li><a className="dropdown-item" href="#!">City</a></li>
                          <li><a className="dropdown-item" href="#!">State</a></li>
                          <li><a className="dropdown-item" href="#!">Country</a></li>
                        </ul>
                      )}
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link"
                        href="#!"
                        onClick={handleopdActionClick}
                      >
                        OPD Management&nbsp;<i className="fas fa-caret-down"></i>
                      </a>
                      {/* Dropdown Menu */}
                      {opdshowDropdown && (
                        <ul className="dropdown-menu show">
                          <li><a className="dropdown-item" href="#!">OPD Type</a></li>
                          <li><a className="dropdown-item" href="#!">OPD sub Type</a></li>
                          <li><a className="dropdown-item" href="#!">OPD Charge Type</a></li>
                          <li><a className="dropdown-item" href="#!">Procedure Title</a></li>
                          <li><a className="dropdown-item" href="#!">Procedure Type</a></li>
                          <li><a className="dropdown-item" href="#!">Appointment Type</a></li>
                          <li><a className="dropdown-item" href="#!">Entry Type</a></li>
                          <li><a className="dropdown-item" href="#!">Fitness Certificate Templates</a></li>
                          <li><a className="dropdown-item" href="#!">Company Examination Type Master</a></li>
                          <li><a className="dropdown-item" href="#!">Tie-up Company Master</a></li>
                          <li><a className="dropdown-item" href="#!">Referral Type</a></li>
                          <li><a className="dropdown-item" href="#!">Referral Doctor</a></li>
                          <li><a className="dropdown-item" href="#!">Queue Master</a></li>
                          <li><a className="dropdown-item" href="#!">Eye Ex-Finding Fields</a></li>
                          <li><a className="dropdown-item" href="#!">Treatment Service Name</a></li>
                          <li><a className="dropdown-item" href="#!">Treatment Service Case</a></li>
                          <li><a className="dropdown-item" href="#!">Treatment Session Master</a></li>
                          <li><a className="dropdown-item" href="#!">Patient Type</a></li>
                        </ul>
                      )}
                    </li>
                    <li className="nav-item dropdown"><a className="nav-link" href="#!">IPD Management</a></li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link"
                        href="#!"
                        onClick={handlebillingActionClick}
                      >
                        Billing Management&nbsp;<i className="fas fa-caret-down"></i>
                      </a>
                      {/* Dropdown Menu */}
                      {billingshowDropdown && (
                        <ul className="dropdown-menu show">
                          
                          <li><a className="dropdown-item" href="#!">Card Bank Names</a></li>
                          <li><a className="dropdown-item" href="#!">Rate List Type</a></li>
                          <li><a className="dropdown-item" href="#!">Server Master</a></li>
                          <li><a className="dropdown-item" href="#!">Investigation Master</a></li>
                          <li><a className="dropdown-item" href="#!">Surgery Master</a></li>
                          <li><a className="dropdown-item" href="#!">Medicine Master</a></li>
                          <li><a className="dropdown-item" href="#!">Expense Category</a></li>
                          <li><a className="dropdown-item" href="#!">Daily Expense Heads</a></li>
                          <li><a className="dropdown-item" href="#!">Package Master</a></li>
                    
                        </ul>
                      )}
                    </li>
                    <li className="nav-item dropdown"><a className="nav-link" href="#!">User Management</a></li>
                    <li className="nav-item dropdown"><a className="nav-link" href="#!">TPA & Panel Management</a></li>
                    <li className="nav-item dropdown"><a className="nav-link" href="#!">Health Chart Management</a></li>
                    <br></br>
                    <br></br>
                    <li className="nav-item dropdown"><a className="nav-link" href="#!">RAD Management</a></li>
                    <li className="nav-item dropdown"><a className="nav-link" href="#!">Store Management</a></li>
                    <li className="nav-item dropdown"><a className="nav-link" href="#!">Finance Management</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={SubmitForm}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Settings Details</h4>
                          </div>
                        </div>

                        {/* name */}
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Doctor Charges/(Per Day){" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              value={DoctorCharges}
                              onChange={(e) => setDoctorCharges(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Nurse Charges/(Per Day){" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              value={NurseCharges}
                              onChange={(e) => setNurseCharges(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              WardBoy Charges/(Per Day){" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              value={WardBoyCharges}
                              onChange={(e) =>
                                setWardBoyCharges(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Staff Charges/(Per Day){" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="number"
                              value={StaffCharges}
                              onChange={(e) => setStaffCharges(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                navigate("/testcategorylist");
                              }}
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default SettingPage;
