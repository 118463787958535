/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";

import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
  imguser,
} from "../imagepath";
import { Link } from "react-router-dom";
import Api from "../../helper/api";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";
import AuthApi from "../../helper/authApi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";

const DischargeSummary = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [PatientData, setPatientData] = useState([]);
  const [medicineList, setMedicineList] = useState([]);
  const [IpdRecordList, setIpdRecordList] = useState([]);
  const [IpdNursingRecordList, setIpdNursingRecordList] = useState([]);
  const [selectedMedicineValue, setSelectedMedicineValue] = useState("");
  const [selectedTimes, setSelectedTimes] = useState({});
  const [vitalSigns, setVitalSigns] = useState({});
  const defaultContent = `
    <p>C/O</p>
    <p></p>
    <p></p>
    <p></p>
    <p>O/E</p>
    <p></p>
    <p></p>
    <p></p>
    <p>S/E</p>
  `;
  const [ClinicalProfile, setClinicalProfile] = useState(defaultContent);
  const [Treatment, setTreatment] = useState("");
  const [userData, setUserData] = useState(null);
  const debounceTimeoutRef = useRef(null); // Ref to hold the timeout ID

  useEffect(() => {
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    if (data) {
      getMedicineList(data.data);
      setUserData(data.data);
    }
  }, []);

  const getMedicineList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.medicineList;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchmedicineList}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchmedicineList}/${user.branch_id}`;
    } else {
      url = `${Api.branchmedicineList}/${user.branch_id}`;
    }

    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setMedicineList(data);
  };

  const medicineoptions = medicineList.map((m) => ({
    value: m.id,
    label: m.name,
  }));
  const handlemedicineChange = (selectedOption) => {
    setSelectedMedicineValue(selectedOption);
    // Update selected times and vital signs for newly selected medicines
    const updatedTimes = { ...selectedTimes };
    const updatedVitals = { ...vitalSigns };

    selectedOption.forEach((medicine) => {
      if (!updatedTimes[medicine.value]) {
        // If the medicine does not exist in the times, initialize it
        updatedTimes[medicine.value] = {
          "9am": false,
          "3pm": false,
          "9pm": false,
        };
      }

      if (!updatedVitals[medicine.value]) {
        // If the medicine does not exist in the vitals, initialize it
        updatedVitals[medicine.value] = {
          pulse: "",
          bp: "",
          temp: "",
          urine: "",
        };
      }
    });

    // Update the states with the merged data
    setSelectedTimes(updatedTimes);
    setVitalSigns(updatedVitals);
  };

  const getIpdInfo = async () => {
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(
      `${Api.ipdformfirstinfo}/${id}`
    );
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setPatientData(data.data[0]);
  };
  const getIpdRecordList = async () => {
    loadingService.next(true);

    try {
      // Fetch both lists concurrently
      const [recordListResponse, nursingRecordListResponse] = await Promise.all(
        [
          AuthApi.get(`${Api.ipdrecordlist}/${id}`),
          AuthApi.get(`${Api.ipdnursingrecordlist}/${id}`),
        ]
      );

      // Extract data and statuses from the responses
      const {
        data: recordListData,
        message: recordListMessage,
        status: recordListStatus,
      } = recordListResponse;
      const {
        data: nursingRecordListData,
        message: nursingRecordListMessage,
        status: nursingRecordListStatus,
      } = nursingRecordListResponse;

      loadingService.next(false);

      // Handle errors or unauthorized responses
      if (!recordListData || !nursingRecordListData) {
        toast.error(recordListMessage || nursingRecordListMessage);
        if (recordListStatus === 401 || nursingRecordListStatus === 401) {
          navigate("/login");
        }
        return;
      }

      // Set the states with their respective data
      setIpdRecordList(recordListData.data);
      setIpdNursingRecordList(nursingRecordListData.data);
    } catch (error) {
      loadingService.next(false);
      toast.error("An error occurred while fetching data.");
      console.error(error);
    }
  };

  useEffect(() => {
    getIpdInfo();
    getIpdRecordList();
  }, []);

  const nursingeportcolumns = [
    {
      title: "Date",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      render: (text, record) => {
        const dob = new Date(record.created_at);
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const formattedDate = `${dob.getDate()}-${
          monthNames[dob.getMonth()]
        }-${dob.getFullYear()}`;
        return <strong>{formattedDate}</strong>;
      },
    },
    {
      title: "Name Of Medicine",
      dataIndex: "medicine",
      sorter: (a, b) => a.medicine.length - b.medicine.length,
    },
    {
      title: "Time(9.00am)",
      dataIndex: "time_9am",
      render: (text, record) => {
        return (
          <>
            {record.is_allow_time_9am == 1 ? (
              <input
                type="checkbox"
                className="form-check-input"
                checked={record.time_9am == 1}
              />
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      title: "Time(3.00pm)",
      dataIndex: "time_3pm",
      render: (text, record) => {
        return (
          <>
            {record.is_allow_time_3pm == 1 ? (
              <input
                type="checkbox"
                className="form-check-input"
                checked={record.time_3pm == 1}
              />
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      title: "Time(9.00pm)",
      dataIndex: "time_9pm",
      render: (text, record) => {
        return (
          <>
            {record.is_allow_time_9pm == 1 ? (
              <input
                type="checkbox"
                className="form-check-input"
                checked={record.time_9pm == 1}
              />
            ) : (
              "NA"
            )}
          </>
        );
      },
    },
    {
      title: "Pulse",
      dataIndex: "pulse",
      render: (text, record) => (
        <input
          type="text"
          className="form-control"
          value={record.pulse}
          placeholder="Enter Pulse"
        />
      ),
    },
    {
      title: "BP",
      dataIndex: "bp",
      render: (text, record) => (
        <input
          type="text"
          className="form-control"
          value={record.bp}
          placeholder="Enter BP"
        />
      ),
    },
    {
      title: "Temp",
      dataIndex: "temp",
      render: (text, record) => (
        <input
          type="text"
          className="form-control"
          value={record.temp}
          placeholder="Enter Temp"
        />
      ),
    },
    {
      title: "Urine",
      dataIndex: "urine",
      render: (text, record) => (
        <input
          type="text"
          className="form-control"
          value={record.urine}
          placeholder="Enter Urine"
        />
      ),
    },
  ];

  // start ipd report colom

  const ipdeportcolumns = [
    {
      title: "Date",
      dataIndex: "created_at",
      width: "10%", // Small width, equivalent to col-2
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at), // Correctly sorting by date
      render: (text, record) => {
        const dob = new Date(record.created_at); // Convert the date string to a Date object

        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        const formattedDate = `${dob.getDate()}-${
          monthNames[dob.getMonth()]
        }-${dob.getFullYear()}`;

        return <strong>{formattedDate}</strong>; // Return formatted date string
      },
    },
    {
      title: "Clinical Profile",
      dataIndex: "clinical_profile",
      sorter: (a, b) => a.clinical_profile.length - b.clinical_profile.length,
      render: (text, record) => {
        let data = record.recordData;
        // If recordData is a JSON string, parse it
        if (typeof data === "string") {
          data = JSON.parse(data);
        }
        return (
          <>
            <div className="formsecondckeditor dischargeformsecondckeditor">
              <CKEditor
                editor={ClassicEditor}
                data={record.clinical_profile}
                disabled
              />
            </div>
            <br></br>
            <div>
              {data.map((item, index) => (
                <div key={index}>
                  <div>
                    <strong>Medicine:</strong> {item.medicine}
                  </div>
                </div>
              ))}
            </div>
          </>
        );
      },
    },
    {
      title: "Treatment",
      dataIndex: "treatment",
      sorter: (a, b) => a.treatment.length - b.treatment.length,
      render: (text, record) => {
        return (
          <div className="formsecondckeditor dischargeformsecondckeditor">
            <CKEditor editor={ClassicEditor} data={record.treatment} disabled />
          </div>
        );
      },
    },
  ];

  const handlePrint = () => {
    const printContent = document.getElementById("print-section").innerHTML;
    const originalContent = document.body.innerHTML;

    // Replace the body's HTML with the print section's HTML
    document.body.innerHTML = printContent;

    // Open the print dialog
    window.print();

    // Restore the original HTML after printing
    document.body.innerHTML = originalContent;
  };

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="add-appoinment"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Discharge Report </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Discharge Report</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div id="print-section">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-center mb-4">
                        <div className="text-center">
                          <h2 className="mb-0">
                            SABALE&apos;S SUPER SPECIALITY HOSPITAL
                          </h2>
                          <p className="mb-0">
                            Sai Venkata Tread Center, Pune-Nashik Highway,
                            Bhosari, Pune 411039
                          </p>
                          <p className="mb-0">
                            Mobile: 9011053954/9881255159 | Email:
                            sabalehospital2020@gmail.com
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box profile-header">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-view">
                      <div className="profile-img-wrap">
                        <div className="profile-img">
                          <Link to="#">
                            <img className="avatar" src={imguser} alt="#" />
                          </Link>
                        </div>
                      </div>
                      <div className="profile-basic">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="profile-info-left">
                              <h3 className="user-name m-t-0 mb-0">
                                {PatientData.patient_name}
                              </h3>
                              {/* <small className="text-muted">Gynecologist</small> */}
                              <div className="staff-id">
                                Ipd ID : {PatientData.ipd_id}
                              </div>
                              <div className="staff-id">
                                Patient ID : {PatientData.patientId}
                              </div>
                              <div className="staff-msg">
                                <Link to="/chat" className="btn"></Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-7">
                            <ul className="personal-info">
                              <li>
                                <span className="title">Age:</span>
                                <span className="text">{PatientData.age}</span>
                              </li>
                              <li>
                                <span className="title">Gender:</span>
                                <span className="text">
                                  {PatientData.gender}
                                </span>
                              </li>
                              <li>
                                <span className="title">Ward:</span>
                                <span className="text">{PatientData.ward}</span>
                              </li>
                              <li>
                                <span className="title">Bed Number:</span>
                                <span className="text">
                                  {PatientData.bed_number}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
              {/* /Page Header */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body">
                              <div className="d-flex justify-content-between">
                                <h6 className="card-title">Reports</h6>
                                <h6 className="card-title">
                                  <Button
                                    onClick={handlePrint}
                                    className="no-print"
                                    variant="primary"
                                  >
                                    Print Summary
                                  </Button>
                                </h6>
                              </div>
                              <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                <li className="nav-item">
                                  <Link
                                    className="nav-link active"
                                    to="#bottom-justified-tab1"
                                    data-bs-toggle="tab"
                                  >
                                    IPD Report
                                  </Link>
                                </li>
                                {/* tab2 */}
                                <li className="nav-item">
                                  <Link
                                    className="nav-link"
                                    to="#bottom-justified-tab2"
                                    data-bs-toggle="tab"
                                  >
                                    Nursing Chart
                                  </Link>
                                </li>
                              </ul>
                              <div className="tab-content">
                                <div
                                  className="tab-pane show active"
                                  id="bottom-justified-tab1"
                                >
                                  <div className="table-responsive doctor-list">
                                    <Table
                                      className="ipdformtable"
                                      pagination={false}
                                      columns={ipdeportcolumns}
                                      dataSource={IpdRecordList}
                                      rowKey={(record) => record.id}
                                      rowClassName={(record) =>
                                        record.outside_pune == "Yes"
                                          ? "green-row"
                                          : ""
                                      }
                                    />
                                  </div>
                                </div>
                                {/* tab2 */}
                                <div
                                  className="tab-pane"
                                  id="bottom-justified-tab2"
                                >
                                  <div className="table-responsive doctor-list">
                                    <Table
                                      className="ipdformtable"
                                      pagination={false}
                                      columns={nursingeportcolumns}
                                      dataSource={IpdNursingRecordList}
                                      rowKey={(record) => record.id}
                                      rowClassName={(record) =>
                                        record.outside_pune == "Yes"
                                          ? "green-row"
                                          : ""
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-center mb-4">
                        <div className="text-center">
                          <p className="mb-0">
                            Mobile: 9011053954/9881255159 | Email:
                            sabalehospital2020@gmail.com
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default DischargeSummary;
