import React,{useState} from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { DatePicker } from "antd";
import { onShowSizeChange, itemRender } from "../Pagination";

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Patient",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.length - b.patient_name.length,
    },
    {
      title: "Doctor",
      dataIndex: "doctor_name",
      sorter: (a, b) => a.doctor_name.length - b.doctor_name.length,
    },

    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      sorter: (a, b) => a.mobile_number.length - b.mobile_number.length,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => a.gender.length - b.gender.length,
    },
    {
      title: "Appointment Date",
      dataIndex: "appoitment_date",
      sorter: (a, b) => a.appointment_date.length - b.appointment_date.length,
      render: (text, record) => {
        // Convert the date of birth to a Date object
        const dob = new Date(record.appointment_date);

        // Define an array to map month numbers to their respective names
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // Format the date in the desired format
        const formattedDate = `${dob.getDate()}-${
          monthNames[dob.getMonth()]
        }-${dob.getFullYear()}`;

        // Return the formatted date
        return formattedDate;
      },
    },
    {
      title: "At",
      dataIndex: "appointment_from",
      sorter: (a, b) => a.appointment_from.length - b.appointment_from.length,
    },
    {
      title: "Outside Pune",
      dataIndex: "outside_pune",
      sorter: (a, b) => a.outside_pune.length - b.outside_pune.length,
    },
    // {
    //   title: "Branch Id",
    //   dataIndex: "branch_id",
    //   sorter: (a, b) => a.branch_id.length - b.branch_id.length,
    // },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        return record.status == 1 ? "Scheduleded" : "Completed";
      },
    },
    {
      title: "Actions",
      dataIndex: null, // You can keep this null if it's just for the action
      render: (text, record) => {
        return (
          <>
            {record.status == 1 ? (
              <a
                className="badge bg-danger-light"
                
              >
                Take Payment
              </a>
            ) : (
              <a
                className="badge bg-success-light"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the row click event, but don't open payment modal
                }}
              >
                Payment Done
              </a>
            )}
            <br></br>
            <br></br>
            <a
              className="badge bg-primary-light"
              
            >
              Send Prescription
            </a>
            <br></br>
            <br></br>
            {record.status == 0 && (
              <a
                className="badge bg-success-light"
               
              >
                Send Receipt
              </a>
            )}
          </>
        );
      },
    },
   

    {
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">

                <Link
                  className="dropdown-item"
                  to={`/editappointmentlist/${record.id}`}
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </Link>
                <Link
                  className="dropdown-item"
                 
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

const billingexpenses = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleActionClick = () => {
    setShowDropdown(!showDropdown); // Toggle dropdown visibility
  };

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Billing </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Billing Expenses</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <ul className="nav nav-tabs nav-tabs-bottom">
                    <li className="nav-item">
                      <Link className="nav-link" to="/billinglist">
                        Billing
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link active" to="/billingexpenses">
                        Expenses
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link"
                        href="#!"
                        onClick={handleActionClick}
                      >
                        Action
                      </a>
                      {/* Dropdown Menu */}
                      {showDropdown && (
                        <ul className="dropdown-menu show">
                          <li>
                            <a className="dropdown-item" href="#!">
                              Advance Payment
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#!">
                              Estimate
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#!">
                              Doctor Charges
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#!">
                              Patient Outstanding
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    {/* <!-- Filters Row --> */}
                    <div className="row align-items-center my-3">
                      <div className="col-md-3">
                        <label>Filter by Date</label>
                        <div className="input-group align-items-baseline">
                          <DatePicker
                            className="form-control datetimepicker" // Keeping it like input

                            placeholderText="From"
                            dateFormat="dd/MM/yyyy" // Change format as per your requirement
                          />
                          <span className="input-group-text">to</span>
                          <DatePicker
                            className="form-control datetimepicker" // Keeping it like input
     
                            placeholderText="To"
                            dateFormat="dd/MM/yyyy" // Change format as per your requirement
                          />
                        </div>
                      </div>
                      {/* <!-- Filter and Reset Buttons --> */}
                      <div className="col-md-3 d-flex justify-content-end pt-3 doctor-submit">
                        <button
                          className="btn btn-primary me-2"
                          id="filter-btn"
                        >
                          Filter
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary cancel-form"
                        >
                          Reset
                        </button>
                      </div>
                    </div>

                    <div className="row align-items-center my-3">
                      <div className="col-md-3">
                        <label>Filter Text</label>
                        <div className="input-group align-items-baseline">
                          <input className="form-control" type="text" placeholder="filter text"/>
                        </div>
                      </div>
                      {/* <!-- Filter and Reset Buttons --> */}
                      <div className="col-md-3 d-flex justify-content-end pt-3 doctor-submit">
                        <button
                          className="btn btn-primary me-2"
                          id="filter-btn"
                        >
                          Search
                        </button>
                        
                      </div>
                    </div>
                    
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: columns.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      rowKey={(record) => record.id}
                      rowClassName={(record) =>
                        record.outside_pune == "Yes" ? "green-row" : ""
                      }
                    />
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
  );
};

export default billingexpenses;
