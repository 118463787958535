/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from 'react-hot-toast';

const Branches = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [branchList, setBranchList] = useState([]);
  const [show, setShow] = useState(false);

  const handleDelete = async () => {
    // Implement your delete logic here using deleteId

    const { data, status } = await AuthApi.delete(
      `${Api.deletehospital}/${show}`
    );
    if (status != 200) {
      toast.success("Deleted Successfully");
      setShow(false);
      getBranchList();
      if (status == 401) navigate("/login");
      return;
    }
  };

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Hospital Id",
      dataIndex: "main_branch_id",
      sorter: (a, b) => a.main_branch_id.length - b.main_branch_id.length,
    },
    {
      title: "Branch Id",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Branch Name",
      dataIndex: "branch_name",
      sorter: (a, b) => a.branch_name.length - b.branch_name.length,
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address.length - b.address.length,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => a.city.length - b.city.length,
    },
    {
      title: "Main Branch",
      dataIndex: "is_main_branch",
      sorter: (a, b) => a.is_main_branch.length - b.is_main_branch.length,
      render: (isMainBranch) => (isMainBranch === 1 ? "Yes" : "No"),
    },

    {
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to={`/subbranchpatientlist/${record.id}`}
                >
                  <i className="far fa-file me-2" />
                  Branch Patient List
                </Link>
                <Link
                  className="dropdown-item"
                  to={`/subbranchdoctorList/${record.id}`}
                >
                  <i className="far fa-file me-2" />
                  Branch Doctor List
                </Link>
                <Link
                  className="dropdown-item"
                  to={`/subbranchappointmentList/${record.id}`}
                >
                  <i className="far fa-file me-2" />
                  Branch Appointment List
                </Link>
                <Link
                  className="dropdown-item"
                  to={`/subbranchopdlist/${record.id}`}
                >
                  <i className="far fa-file me-2" />
                  Branch OPD List
                </Link>
                <Link
                  className="dropdown-item"
                  onClick={() => setShow(record.id)}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete Branch
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  const getBranchList = async () => {
    const { data, message, status } = await AuthApi.get(
      `${Api.allsubbranches}/${id}`
    );

    if (!data) {
      if (status === 401) navigate("/login");
      return;
    }
    setBranchList(data);
  };

  useEffect(() => {
    getBranchList();
  }, []);

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">All Branches </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Branches List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Branches List</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                            <div className="add-group">
                              <Link
                                to="/addhospital"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2"></Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: branchList.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={branchList}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure want to delete this ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imagesend} alt="#" width={50} height={46} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white me-2"
            variant="secondary"
            onClick={() => setShow(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="btn btn-danger"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Branches;
