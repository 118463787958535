/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";
import Select from "react-select";

import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
  imguser,
} from "../imagepath";
import { Link } from "react-router-dom";
import Api from "../../helper/api";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";
import AuthApi from "../../helper/authApi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const PreOperativePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [PatientData, setPatientData] = useState({});
  const [doctor, setDoctor] = useState(null);
  const [DoctorList, setDoctorList] = useState([]);

  // Inside your AddIpdSlave component
  const [checkboxes, setCheckboxes] = useState({
    review_opd_doctors_notes: false,
    handover_sheet_available: false,
    verify_ongoing_medications: false,
    diagnosis_treatment_plan_clear: false,
    crosscheck_allergies: false,
    prescription_details_clear: false,
    verify_current_medications: false,
    doublecheck_timing_doses: false,
    confirm_pending_investigations: false,
  });

  const checklistItems = [
    {
      id: "review_opd_doctors_notes",
      label: "Review OPD Doctor's Notes",
      inputType: "text",
    },
    {
      id: "handover_sheet_available",
      label: "Ensure Handover Sheet Available",
      inputType: "text",
    },
    {
      id: "verify_ongoing_medications",
      label: "Verify Ongoing Medications",
      inputType: "text",
    },
    {
      id: "diagnosis_treatment_plan_clear",
      label: "Clear Diagnosis & Provisional Treatment Plan",
      inputType: "text",
    },
    {
      id: "crosscheck_allergies",
      label: "Cross-Check Allergies List",
      inputType: "text",
    },
    {
      id: "prescription_details_clear",
      label: "Ensure Prescription Details are Clear",
      inputType: "text",
    },
    {
      id: "verify_current_medications",
      label: "Verify Injections, Medications, or IV Fluids Being Administered",
      inputType: "text",
    },
    {
      id: "doublecheck_timing_doses",
      label: "Double-Check Timing & Doses for Ongoing Medications",
      inputType: "text",
    },
    {
      id: "confirm_pending_investigations",
      label: "Confirm Pending Investigations (Lab Tests, Scans)",
      inputType: "text",
    },
  ];

  // Handler for checkbox toggle
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setCheckboxes((prev) => ({ ...prev, [id]: checked }));
    setInputValues((prev) => ({ ...prev, [id]: checked ? "" : "" }));
  };

  const getDoctorList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.doctorList;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchdoctorList}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchdoctorList}/${user.branch_id}`;
    } else {
      url = `${Api.branchdoctorList}/${user.branch_id}`;
    }
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setDoctorList(data);
  };

  const options = DoctorList.map((doctor) => ({
    value: doctor.id,
    label: `${doctor.first_name} ${doctor.last_name}`,
  }));

  const handleChange = (selectedOption) => {
    setDoctor(selectedOption);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);

    setUserData(data.data);
  }, []);
  console.log(userData);

  const getOpdList = async () => {
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(
      `${Api.ipdformfirstinfo}/${id}`
    );
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }

    setPatientData(data.data[0]);
  };

  useEffect(() => {
    getOpdList();
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getDoctorList(userData);
    }
  }, [userData]);

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item4"
        id1="menu-items4"
        activeClassName="add-appoinment"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Pre OperativePage </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Pre OperativePage</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="card-box profile-header">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img className="avatar" src={imguser} alt="#" />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {PatientData.patient_name}
                            </h3>
                            {/* <small className="text-muted">Gynecologist</small> */}
                            <div className="staff-id">
                              Ipd ID : {PatientData.ipd_id}
                            </div>
                            <div className="staff-id">
                              Patient ID : {PatientData.patientId}
                            </div>
                            <div className="staff-msg">
                              <Link to="/chat" className="btn"></Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <span className="title">Age:</span>
                              <span className="text">{PatientData.age}</span>
                            </li>
                            <li>
                              <span className="title">Gender:</span>
                              <span className="text">{PatientData.gender}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  {/* <h6 className="card-title">Checklist</h6> */}
                  <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        to="#bottom-justified-tab1"
                        data-bs-toggle="tab"
                      >
                        Operative Details
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#bottom-justified-tab2"
                        data-bs-toggle="tab"
                      >
                        Pre Operative Check List
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane show active"
                      id="bottom-justified-tab1"
                    >
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-body">
                                      {/* <details> */}
                                      <div className="row py-3">
                                        <div className="col-12 col-md-4 col-xl-4">
                                          <div className="form-group local-forms">
                                            <label>
                                              Ipd No{" "}
                                              <span className="login-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              value={PatientData.ipd_id}
                                              disabled
                                            />
                                          </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-xl-4">
                                          <div className="form-group local-forms">
                                            <label>
                                              Surgery Name{" "}
                                              <span className="login-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              className="form-control"
                                              type="text"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-xl-2">
                                          <div className="form-group local-forms">
                                            <label>
                                              Surgery Date & Time{" "}
                                              <span className="login-danger"></span>
                                            </label>
                                            <DatePicker
                                              className="form-control datetimepicker"
                                              format="DD-MMM-YYYY"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-2">
                                          <div className="form-group local-forms">
                                            <label>
                                              <span className="login-danger"></span>
                                            </label>
                                            <div className="">
                                              <input
                                                className="form-control"
                                                id="outlined-controlled"
                                                type="time"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4">
                                          <div className="form-group local-forms">
                                            <label>
                                              Doctor Name
                                              <span className="login-danger"></span>
                                            </label>
                                            <Select
                                              className="form-control-alternative"
                                              options={options}
                                              value={doctor}
                                              onChange={handleChange}
                                              placeholder="Search And Select"
                                              isSearchable
                                              isClearable
                                              styles={{
                                                menuPortal: (base) => ({
                                                  ...base,
                                                  zIndex: 9999,
                                                }),
                                                control: (
                                                  baseStyles,
                                                  state
                                                ) => ({
                                                  ...baseStyles,
                                                  borderColor: state.isFocused
                                                    ? "none"
                                                    : "2px solid rgba(46, 55, 164, 0.1);",
                                                  boxShadow: state.isFocused
                                                    ? "0 0 0 1px #2e37a4"
                                                    : "none",
                                                  "&:hover": {
                                                    borderColor: state.isFocused
                                                      ? "none"
                                                      : "2px solid rgba(46, 55, 164, 0.1)",
                                                  },
                                                  borderRadius: "10px",
                                                  fontSize: "14px",
                                                  minHeight: "45px",
                                                }),
                                                dropdownIndicator: (
                                                  base,
                                                  state
                                                ) => ({
                                                  ...base,
                                                  transform: state.selectProps
                                                    .menuIsOpen
                                                    ? "rotate(-180deg)"
                                                    : "rotate(0)",
                                                  transition: "250ms",
                                                  width: "35px",
                                                  height: "35px",
                                                }),
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4">
                                          <div className="form-group local-forms">
                                            <label>
                                              AnasthType{" "}
                                              <span className="login-danger">
                                                *
                                              </span>
                                            </label>
                                            <select className="form-select">
                                              <option value="">NA</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4">
                                          <div className="form-group local-forms">
                                            <label>
                                              Anasth Doctor
                                              <span className="login-danger">
                                                *
                                              </span>
                                            </label>
                                            <Select
                                              className="form-control-alternative"
                                              options={options}
                                              value={doctor}
                                              onChange={handleChange}
                                              placeholder="Search And Select"
                                              isSearchable
                                              isClearable
                                              styles={{
                                                menuPortal: (base) => ({
                                                  ...base,
                                                  zIndex: 9999,
                                                }),
                                                control: (
                                                  baseStyles,
                                                  state
                                                ) => ({
                                                  ...baseStyles,
                                                  borderColor: state.isFocused
                                                    ? "none"
                                                    : "2px solid rgba(46, 55, 164, 0.1);",
                                                  boxShadow: state.isFocused
                                                    ? "0 0 0 1px #2e37a4"
                                                    : "none",
                                                  "&:hover": {
                                                    borderColor: state.isFocused
                                                      ? "none"
                                                      : "2px solid rgba(46, 55, 164, 0.1)",
                                                  },
                                                  borderRadius: "10px",
                                                  fontSize: "14px",
                                                  minHeight: "45px",
                                                }),
                                                dropdownIndicator: (
                                                  base,
                                                  state
                                                ) => ({
                                                  ...base,
                                                  transform: state.selectProps
                                                    .menuIsOpen
                                                    ? "rotate(-180deg)"
                                                    : "rotate(0)",
                                                  transition: "250ms",
                                                  width: "35px",
                                                  height: "35px",
                                                }),
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4">
                                          <div className="">
                                            <div className="">
                                              <div className="col-12 col-sm-12">
                                                <div className="form-group local-forms">
                                                  <label>
                                                    Remarks{" "}
                                                    <span className="login-danger">
                                                      *
                                                    </span>
                                                  </label>
                                                  <textarea
                                                    className="form-control"
                                                    rows={3}
                                                    cols={30}
                                                    defaultValue={""}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="doctor-submit text-end">
                                            <button
                                              type="button"
                                              className="btn btn-primary submit-form me-2"
                                            >
                                              Save & Next
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      {/* </details> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="bottom-justified-tab2">
                      <form>
                        <div className="row">
                          {checklistItems.map((item, index) => (
                            <div
                              key={index}
                              className="col-12 col-md-12 col-xl-6 pb-3 d-flex align-items-start"
                            >
                              <div className="col-md-1">
                                <input
                                  type="checkbox"
                                  className="select-checkbox hidecheck me-2"
                                  id={item.id}
                                  name={item.id}
                                  checked={checkboxes[item.id]}
                                  onChange={handleCheckboxChange}
                                />
                              </div>
                              <div className="col-md-6">
                                <label
                                  className="form-check-label"
                                  htmlFor={item.id}
                                >
                                  {item.label}
                                </label>
                              </div>
                              <div className="col-md-5">
                                <input
                                  className="form-control"
                                  type={item.inputType}
                                  value={inputValues[item.id] || ""}
                                  placeholder="Additonal comment...."
                                  onChange={(e) =>
                                    setInputValues({
                                      ...inputValues,
                                      [item.id]: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          ))}
                          <hr></hr>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default PreOperativePage;
