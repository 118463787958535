/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";

const IpdSearchOldRecordList = () => {
  const navigate = useNavigate();
  const [testList, setTestList] = useState([]);
  const [userData, setUserData] = useState(null);

  const columns = [
    {
      title: "Ipd No",
      dataIndex: "ipd_no",
      sorter: (a, b) => a.ipd_no.length - b.ipd_no.length,
    },
    {
      title: "UHID",
      dataIndex: "ipd_no",
      sorter: (a, b) => a.ipd_no.length - b.ipd_no.length,
    },
    {
      title: "Name",
      dataIndex: "ipd_no",
      sorter: (a, b) => a.ipd_no.length - b.ipd_no.length,
    },
    {
      title: "Age",
      dataIndex: "ipd_no",
      sorter: (a, b) => a.ipd_no.length - b.ipd_no.length,
    },
    {
      title: "Gender",
      dataIndex: "ipd_no",
      sorter: (a, b) => a.ipd_no.length - b.ipd_no.length,
    },
    {
      title: "Admission Date",
      dataIndex: "ipd_no",
      sorter: (a, b) => a.ipd_no.length - b.ipd_no.length,
    },
    {
      title: "Discharge Date",
      dataIndex: "ipd_no",
      sorter: (a, b) => a.ipd_no.length - b.ipd_no.length,
    },

    // {
    //   title: "Branch Id",
    //   dataIndex: "branch_id",
    //   sorter: (a, b) => a.branch_id.length - b.branch_id.length,
    // },
  ];

  const getTestList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.testcategoryList;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchtestcategoryList}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchtestcategoryList}/${user.branch_id}`;
    } else {
      url = `${Api.branchtestcategoryList}/${user.branch_id}`;
    }
    loadingService.next(true);
    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);
    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setTestList(data);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    setUserData(data.data);
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getTestList(userData);
    }
  }, [userData]);

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Old Record </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Old records</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Search By</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <div className="">
                                <select className="form-select">
                                  <option value="">Select</option>
                                  <option value="">IPD No</option>
                                  <option value="">UHID</option>
                                  <option value="">First Name</option>
                                  <option value="">Last Name</option>
                                  <option value="">Mobile No</option>
                                  <option value="">
                                    Admission Date (dd/mm/yyyy)
                                  </option>
                                  <option value="">
                                    Discharge Date (dd/mm/yyyy)
                                  </option>
                                  <option value="">IPD BIll No</option>
                                </select>
                              </div>
                            </div>
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search Input"
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2"></Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: testList.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={testList}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IpdSearchOldRecordList;
