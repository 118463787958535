/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";

const TPADashboard = () => {
  const navigate = useNavigate();
  const [testList, setTestList] = useState([]);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Bill Date",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      render: (text, record) => {
        // Convert the date of birth to a Date object
        const dob = new Date(record.created_at);

        // Define an array to map month numbers to their respective names
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // Format the date in the desired format
        const formattedDate = `${dob.getDate()}-${
          monthNames[dob.getMonth()]
        }-${dob.getFullYear()}`;

        // Return the formatted date
        return formattedDate;
      },
    },
    {
      title: "IPD Bill No",
      dataIndex: "ipd_id",
      sorter: (a, b) => a.ipd_id.length - b.ipd_id.length,
    },
    {
      title: "Bill Type",
      dataIndex: "ipd_id",
      sorter: (a, b) => a.ipd_id.length - b.ipd_id.length,
    },
    {
      title: "Insurance Company",
      dataIndex: "patientId",
      sorter: (a, b) => a.patientId.length - b.patientId.length,
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.length - b.patient_name.length,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      sorter: (a, b) => a.mobile_number.length - b.mobile_number.length,
    },
    {
      title: "Age",
      dataIndex: "age",
      sorter: (a, b) => a.age.length - b.age.length,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => a.gender.length - b.gender.length,
    },
    {
      title: "Credit Amount",
      dataIndex: "gender",
      sorter: (a, b) => a.gender.length - b.gender.length,
      render: (text, record) => {
        return `₹42046`;
      },
    },
    {
      title: "Added By",
      dataIndex: "gender",
      sorter: (a, b) => a.gender.length - b.gender.length,
    },

    {
      title: "Actions",
      dataIndex: null, // You can keep this null if it's just for the action
      render: (text, record) => {
        return (
          <>
            <a
              className="custom-badge status-orange me-2"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              View Bill
            </a>
            <a
              className="badge bg-primary-light"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/claimnowpage/${record.id}`);
              }}
            >
              Claim Now
            </a>
          </>
        );
      },
    },
  ];

  const getTestList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.ipdmainlist;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchmainipdlist}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchmainipdlist}/${user.branch_id}`;
    } else {
      url = `${Api.branchmainipdlist}/${user.branch_id}`;
    }
    loadingService.next(true);
    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);
    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setTestList(data);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    setUserData(data.data);
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getTestList(userData);
    }
  }, [userData]);

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">TPA </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">TPA List</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  {/* <h4 className="card-title">Basic justified tabs</h4> */}
                  <ul className="nav nav-tabs nav-justified">
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        to="#basic-justified-tab1"
                        data-bs-toggle="tab"
                      >
                        PENDING BILL
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#basic-justified-tab2"
                        data-bs-toggle="tab"
                      >
                        ACTIVE CLAIM
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#basic-justified-tab3"
                        data-bs-toggle="tab"
                      >
                        PAID CLAIM
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="#basic-justified-tab4"
                        data-bs-toggle="tab"
                      >
                        PANEL BILLING
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane show active"
                      id="basic-justified-tab1"
                    >
                      {/* /Page Header */}
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card card-table show-entire">
                            <div className="card-body">
                              {/* Table Header */}
                              <div className="page-table-header mb-2">
                                <div className="row align-items-center">
                                  <div className="col">
                                    <div className="doctor-table-blk">
                                      <div className="doctor-search-blk">
                                        <div className="top-nav-search table-search-blk">
                                          <form>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search here"
                                            />
                                            <Link className="btn">
                                              <img src={searchnormal} alt="#" />
                                            </Link>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto text-end float-end ms-auto download-grp">
                                    <h3 className="text-danger">
                                      <b>
                                        Total Pending Claim : &#x20b9;16074033
                                      </b>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              {/* /Table Header */}
                              <div className="table-responsive doctor-list">
                                <Table
                                  pagination={{
                                    total: testList.length,
                                    showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                    // showSizeChanger: true,
                                    onShowSizeChange: onShowSizeChange,
                                    itemRender: itemRender,
                                  }}
                                  columns={columns}
                                  dataSource={testList}
                                  rowKey={(record) => record.id}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="basic-justified-tab2">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card card-table show-entire">
                            <div className="card-body">
                              {/* Table Header */}
                              <div className="page-table-header mb-2">
                                <div className="row align-items-center">
                                  <div className="col">
                                    <div className="doctor-table-blk">
                                      <div className="doctor-search-blk">
                                        <div className="top-nav-search table-search-blk">
                                          <form>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search here"
                                            />
                                            <Link className="btn">
                                              <img src={searchnormal} alt="#" />
                                            </Link>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto text-end float-end ms-auto download-grp">
                                    <h3 className="text-danger">
                                      <b>Total Active Claim : &#x20b9;15246</b>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              {/* /Table Header */}
                              <div className="table-responsive doctor-list">
                                <Table
                                  pagination={{
                                    total: testList.length,
                                    showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                    // showSizeChanger: true,
                                    onShowSizeChange: onShowSizeChange,
                                    itemRender: itemRender,
                                  }}
                                  columns={columns}
                                  dataSource={testList}
                                  rowKey={(record) => record.id}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="tab-pane" id="basic-justified-tab3">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card card-table show-entire">
                            <div className="card-body">
                              {/* Table Header */}
                              <div className="page-table-header mb-2">
                                <div className="row align-items-center">
                                  <div className="col">
                                    <div className="doctor-table-blk">
                                      <div className="doctor-search-blk">
                                        <div className="top-nav-search table-search-blk">
                                          <form>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search here"
                                            />
                                            <Link className="btn">
                                              <img src={searchnormal} alt="#" />
                                            </Link>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto text-end float-end ms-auto download-grp">
                                    <h3 className="text-danger">
                                      <b>Total Paid Claim : &#x20b9;14256</b>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              {/* /Table Header */}
                              <div className="table-responsive doctor-list">
                                <Table
                                  pagination={{
                                    total: testList.length,
                                    showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                    // showSizeChanger: true,
                                    onShowSizeChange: onShowSizeChange,
                                    itemRender: itemRender,
                                  }}
                                  columns={columns}
                                  dataSource={testList}
                                  rowKey={(record) => record.id}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="tab-pane" id="basic-justified-tab4">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card card-table show-entire">
                            <div className="card-body">
                              {/* Table Header */}
                              <div className="page-table-header mb-2">
                                <div className="row align-items-center">
                                  <div className="col">
                                    <div className="doctor-table-blk">
                                      <div className="doctor-search-blk">
                                        <div className="top-nav-search table-search-blk">
                                          <form>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search here"
                                            />
                                            <Link className="btn">
                                              <img src={searchnormal} alt="#" />
                                            </Link>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto text-end float-end ms-auto download-grp">
                                    <h3 className="text-danger">
                                      <b>Total Panel Billing : &#x20b9;25846</b>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              {/* /Table Header */}
                              <div className="table-responsive doctor-list">
                                <Table
                                  pagination={{
                                    total: testList.length,
                                    showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                    // showSizeChanger: true,
                                    onShowSizeChange: onShowSizeChange,
                                    itemRender: itemRender,
                                  }}
                                  columns={columns}
                                  dataSource={testList}
                                  rowKey={(record) => record.id}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TPADashboard;
