import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import { useNavigate, useParams } from "react-router-dom";
import toast from 'react-hot-toast';

const InfoPatients = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [PatientData, setPatientData] = useState("");

  const getPatientInfo = async () => {
    try {
      const { data, message, status } = await AuthApi.get(
        `${Api.PatientInfo}/${id}`
      );
      if (!data) {
        toast.error(message);
        if (status === 401) navigate("/login");
        return;
      }
      setPatientData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPatientInfo();
  }, []);

  // Convert PatientData.dateOfBirth to a Date object if it's not already
  const dob = new Date(PatientData.date_of_birth);

  // Define an array to map month numbers to their respective names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Format the date in the desired format
  const formattedDate = `${dob.getDate()}-${
    monthNames[dob.getMonth()]
  }-${dob.getFullYear()}`;

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Patients </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Patient Info</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row">
                      {/* Form fields */}
                      {/* First Name */}
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Patient Id <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={PatientData.patient_id}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Registration Type{" "}
                            <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={PatientData.registratio_type}
                            disabled
                          />
                        </div>
                      </div>
                      {PatientData.registratio_type == "TPA" && (
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              TPA / PANEL Name{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              value={PatientData.tpa_panelName}
                              disabled
                            />
                          </div>
                        </div>
                      )}
                      {PatientData.registratio_type == "PANEL" && (
                        <>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                TPA / PANEL Name{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={PatientData.tpa_panelName}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Dispensary Name{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={PatientData.dispensary}
                                disabled
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {PatientData.registratio_type == "TPA" && (
                        <>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Insurance Company{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={PatientData.insurance_company}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Corporate{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={PatientData.corporate}
                                disabled
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>
                            First Name <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={PatientData.first_name}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>
                            Last Name <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={PatientData.last_name}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Age <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={PatientData.age}
                            disabled
                          />
                        </div>
                      </div>

                      {/* Mobile */}
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Mobile <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={PatientData.mobile_number}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Secondary Number{" "}
                            <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={PatientData.mobile_number2}
                            disabled
                          />
                        </div>
                      </div>
                      {/* Date of Birth */}
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Date Of Birth <span className="login-danger"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={formattedDate}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group select-gender">
                          <label className="gen-label">
                            Outside Pune?<span className="login-danger">*</span>
                          </label>
                          <div className="form-check-inline">
                            <input
                              type="text"
                              className="form-control"
                              value={PatientData.outside_pune}
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      {/* Gender */}
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group select-gender">
                          <label className="gen-label">
                            Gender<span className="login-danger">*</span>
                          </label>
                          <div className="form-check-inline">
                            <input
                              type="text"
                              className="form-control"
                              value={PatientData.gender}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      {/* Address */}
                      <div className="col-12 col-sm-12">
                        <div className="form-group local-forms">
                          <label>
                            Address <span className="login-danger">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            rows={3}
                            value={PatientData.address}
                            disabled
                          />
                        </div>
                      </div>

                      {/* Country */}
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Country <span className="login-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={PatientData.country}
                            disabled
                          />
                        </div>
                      </div>
                      {/* State */}
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            State/Province{" "}
                            <span className="login-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={PatientData.state}
                            disabled
                          />
                        </div>
                      </div>
                      {/* City */}
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            City <span className="login-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={PatientData.city}
                            disabled
                          />
                        </div>
                      </div>
                      {/* Postal Code */}
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Postal Code <span className="login-danger"></span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={PatientData.postal_code}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Referred By <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={PatientData.referred_by}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Department<span className="login-danger"></span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={PatientData.department}
                            disabled
                          />
                        </div>
                      </div>
                      {/* Biography */}
                      <div className="col-12 col-sm-12">
                        <div className="form-group local-forms">
                          <label>
                            Past Medical History{" "}
                            <span className="login-danger"></span>
                          </label>
                          <textarea
                            className="form-control"
                            rows={3}
                            value={PatientData.biography}
                            disabled
                          />
                        </div>
                      </div>

                      {/* Form submission buttons */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPatients;
