/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { DatePicker } from "antd";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";
import Select from "react-select";
import html2pdf from "html2pdf.js";

const ReportList = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [AppointmentList, setAppointmentList] = useState([]);
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");

  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [paymentmethod, setPaymentmethod] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [amount, setAmount] = useState("");
  const [patientName, setPatientName] = useState("");
  const [OpdType, setOpdType] = useState("");
  const [opdDropdownOpen, setOpdDropdownOpen] = useState(false);
  const [ipdDropdownOpen, setIpdDropdownOpen] = useState(false);
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [pharmacyDropdownOpen, setPharmacyDropdownOpen] = useState(false);
  const [labDropdownOpen, setLabDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const opdDropdownRef = useRef(null);
  const ipdDropdownRef = useRef(null);
  const accountDropdownRef = useRef(null);
  const pharmacyDropdownRef = useRef(null);
  const labDropdownRef = useRef(null);
  const [sortedAppointments, setSortedAppointments] = useState([]);
  const closeAllDropdowns = () => {
    setDropdownOpen(false);
    setOpdDropdownOpen(false);
    setIpdDropdownOpen(false);
    setAccountDropdownOpen(false);
    setPharmacyDropdownOpen(false);
    setLabDropdownOpen(false);
  };

  // Dropdown ko toggle karne ke liye function
  const toggleDropdown = () => {
    closeAllDropdowns();
    setDropdownOpen(!dropdownOpen);
  };

  // Toggle function for the Opd dropdown
  const toggleOpdDropdown = () => {
    closeAllDropdowns();
    setOpdDropdownOpen(!opdDropdownOpen);
  };
  // Toggle function for the Opd dropdown
  const toggleIpdDropdown = () => {
    closeAllDropdowns();
    setIpdDropdownOpen(!ipdDropdownOpen);
  };
  const toggleAccountDropdown = () => {
    closeAllDropdowns();
    setAccountDropdownOpen(!accountDropdownOpen);
  };
  const togglePharmacyDropdown = () => {
    closeAllDropdowns();
    setPharmacyDropdownOpen(!pharmacyDropdownOpen);
  };
  const togglelabDropdown = () => {
    closeAllDropdowns();
    setLabDropdownOpen(!labDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        opdDropdownRef.current &&
        !opdDropdownRef.current.contains(event.target) &&
        ipdDropdownRef.current &&
        !ipdDropdownRef.current.contains(event.target) &&
        accountDropdownRef.current &&
        !accountDropdownRef.current.contains(event.target) &&
        pharmacyDropdownRef.current &&
        !pharmacyDropdownRef.current.contains(event.target) &&
        labDropdownRef.current &&
        !labDropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
        setOpdDropdownOpen(false);
        setIpdDropdownOpen(false);
        setAccountDropdownOpen(false);
        setPharmacyDropdownOpen(false);
        setLabDropdownOpen(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    dropdownRef,
    opdDropdownRef,
    ipdDropdownRef,
    accountDropdownRef,
    pharmacyDropdownRef,
    labDropdownRef,
    labDropdownRef,
  ]);

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Patient",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.length - b.patient_name.length,
    },
    {
      title: "Doctor",
      dataIndex: "doctor_name",
      sorter: (a, b) => a.doctor_name.length - b.doctor_name.length,
    },

    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      sorter: (a, b) => a.mobile_number.length - b.mobile_number.length,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => a.gender.length - b.gender.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        return record.status == 1 ? "Scheduleded" : "Completed";
      },
    },
    {
      title: "Actions",
      dataIndex: null, // You can keep this null if it's just for the action
      render: (text, record) => {
        return (
          <>
            {record.status == 1 ? (
              <a
                className="badge bg-danger-light"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the row click event
                  setShowPayment(record.id); // Only trigger when amount is empty or null
                  setPatientName(record.patient_name);
                  setOpdType(record.opd_type);
                }}
              >
                Take Payment
              </a>
            ) : (
              <a
                className="badge bg-success-light"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the row click event, but don't open payment modal
                }}
              >
                Payment Done
              </a>
            )}
            <br></br>
            <br></br>
            <a className="badge bg-primary-light" onClick={""}>
              Send Prescription
            </a>
            <br></br>
            <br></br>
            {record.status == 0 && (
              <a className="badge bg-success-light" onClick={""}>
                Send Receipt
              </a>
            )}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <Link className="dropdown-item" to={`/infoappointment/${record.id}`}>
                      <i className="far fa-file me-2" />
                      Info
                    </Link> */}
                <Link
                  className="dropdown-item"
                  to={`/editappointmentlist/${record.id}`}
                >
                  <i className="far fa-edit me-2" />
                  Edit
                </Link>
                <Link
                  className="dropdown-item"
                  onClick={() => setShow(record.id)}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  const getAppointmentList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.appointmentList;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchappointmentList}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchappointmentList}/${user.branch_id}`;
    } else {
      url = `${Api.branchappointmentList}/${user.branch_id}`;
    }
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setAppointmentList(data);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    setUserData(data.data);
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getAppointmentList(userData);
    }
  }, [userData]);

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Report </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Report List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="card-title">Report Dashboard</h6>
                      <ul className="nav nav-tabs nav-tabs-bottom">
                        <li className="nav-item dropdown" ref={dropdownRef}>
                          <Link
                            to="#"
                            className="nav-link"
                            onClick={toggleDropdown}
                          >
                            Billing Report <i className="fas fa-caret-down"></i>{" "}
                            {/* Dropdown icon */}
                          </Link>

                          {dropdownOpen && (
                            <ul className="dropdown-menu show">
                              <li>
                                <Link to="#">
                                  <i className="fas fa-hospital"></i> Hospital
                                  Collection Report (All Department)
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-file-invoice-dollar"></i>{" "}
                                  Collection Report (IPD)
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-file-alt"></i> Collection
                                  Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-file-alt"></i> Collection
                                  Report II
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-user"></i> Userwise
                                  Collection Report (Active User)
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-user-friends"></i>{" "}
                                  Userwise Collection Report (All Department)
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-user"></i> Userwise
                                  Collection Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-chart-line"></i> Detail
                                  Collection Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-notes-medical"></i> OPD
                                  Type Wise Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-procedures"></i>{" "}
                                  Procedure Billing Type Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-stethoscope"></i>{" "}
                                  Procedure Wise Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-user-md"></i> Procedure
                                  Wise Referral Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-money-bill-wave"></i>{" "}
                                  Doctor Wise OPD Collection Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-user-check"></i>{" "}
                                  Available Patient Advance
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-chart-bar"></i>{" "}
                                  Collection Graph Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-file-alt"></i> Detail
                                  Daily Expense Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-user-md"></i> Referral
                                  Doctor Collection Report
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>
                        <li className="nav-item dropdown " ref={opdDropdownRef}>
                          <Link
                            to="#"
                            className="nav-link"
                            onClick={toggleOpdDropdown}
                          >
                            Opd Reports <i className="fas fa-caret-down"></i>
                          </Link>
                          {opdDropdownOpen && (
                            <ul className="dropdown-menu show">
                              <li>
                                <Link to="#">
                                  <i className="fas fa-file-alt"></i>{" "}
                                  Appointment Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-user"></i> Patient Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-vials"></i> Investigation
                                  List
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-stethoscope"></i> Patient
                                  Diagnoses Analysis
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-notes-medical"></i>{" "}
                                  Patient Complaint Analysis
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-map-marker-alt"></i>{" "}
                                  Patient Area Wise Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-file-excel"></i> Excel
                                  Reporting
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-syringe"></i> Suggested
                                  Surgery Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-chart-bar"></i> Patient
                                  Graphical Report
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>
                        <li className="nav-item dropdown" ref={ipdDropdownRef}>
                          <Link
                            to="#"
                            className="nav-link"
                            onClick={toggleIpdDropdown}
                          >
                            Ipd Reports <i className="fas fa-caret-down"></i>
                          </Link>
                          {ipdDropdownOpen && (
                            <ul className="dropdown-menu show">
                              <li>
                                <Link to="#">
                                  <i className="fas fa-file-alt"></i> Admit
                                  Patient Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-map-marker-alt"></i>{" "}
                                  Admit Patient Location Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-syringe"></i> Booked
                                  Surgery Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-notes-medical"></i>{" "}
                                  Admited Patient History Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-sign-out-alt"></i>{" "}
                                  Discharged Patient Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-user-md"></i> TPA Panel
                                  Admited Patient Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-bed"></i> Available
                                  Bed/Room Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-stethoscope"></i>{" "}
                                  Operation Done Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-baby"></i> Upcoming
                                  Delivery Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-book"></i> Counselor
                                  Booking Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-file-invoice-dollar"></i>{" "}
                                  Collection Report (IPD)
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>
                        <li
                          className="nav-item dropdown"
                          ref={accountDropdownRef}
                        >
                          <Link
                            to="#"
                            className="nav-link"
                            onClick={toggleAccountDropdown}
                          >
                            Account Reports{" "}
                            <i className="fas fa-caret-down"></i>
                          </Link>
                          {accountDropdownOpen && (
                            <ul className="dropdown-menu show">
                              <li>
                                <Link to="#">
                                  <i className="fas fa-file-alt"></i> Payment
                                  Register Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-map-marker-alt"></i>{" "}
                                  Reciept Register Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-syringe"></i> Booked
                                  Purchase Register Report
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>
                        <li
                          className="nav-item dropdown"
                          ref={pharmacyDropdownRef}
                        >
                          <Link
                            to="#"
                            className="nav-link"
                            onClick={togglePharmacyDropdown}
                          >
                            Pharmacy Report{" "}
                            <i className="fas fa-caret-down"></i>
                          </Link>
                          {pharmacyDropdownOpen && (
                            <ul className="dropdown-menu show">
                              <li>
                                <Link to="#">
                                  <i className="fas fa-file-alt"></i> User Wise
                                  Pharma Collection Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-clinic-medical"></i>{" "}
                                  Pharmacy Details Collection Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-money-bill-wave"></i>{" "}
                                  Purchase Tax Summary
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-chart-line"></i> Sale Tax
                                  Summary
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-undo-alt"></i> Sales
                                  Return Tax Summary
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-file-alt"></i> Payment
                                  Register Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-map-marker-alt"></i>{" "}
                                  Receipt Register Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-syringe"></i> Booked
                                  Purchase Register Report
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>
                        <li className="nav-item dropdown" ref={labDropdownRef}>
                          <Link
                            to="#"
                            className="nav-link"
                            onClick={togglelabDropdown}
                          >
                            Lab Report <i className="fas fa-caret-down"></i>
                          </Link>
                          {labDropdownOpen && (
                            <ul className="dropdown-menu show">
                              <li>
                                <Link to="#">
                                  <i className="fas fa-file-alt"></i> User Wise
                                  Lab Collection Report
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="fas fa-clinic-medical"></i> Lab
                                  Details Collection Report
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Reports List</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                            <div className="add-group">
                              <Link
                                to="#"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2"></Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div>
                    </div>
                    {/* <!-- Filters Row --> */}
                    <div className="row align-items-center my-3">
                      <div className="col-md-3">
                        <label>Filter by Date</label>
                        <div className="input-group align-items-baseline">
                          <DatePicker
                            className="form-control datetimepicker" // Keeping it like input
                            selected={fromdate}
                            onChange={(date) => setFromdate(date)}
                            placeholderText="From"
                            dateFormat="dd/MM/yyyy" // Change format as per your requirement
                          />
                          <span className="input-group-text">to</span>
                          <DatePicker
                            className="form-control datetimepicker" // Keeping it like input
                            selected={todate}
                            onChange={(date) => setTodate(date)}
                            placeholderText="To"
                            dateFormat="dd/MM/yyyy" // Change format as per your requirement
                          />
                        </div>
                      </div>
                      {/* <!-- Filter and Reset Buttons --> */}
                      <div className="col-md-3 d-flex justify-content-end pt-4 doctor-submit ">
                        <button
                          className="btn  btn-outline-warning  "
                          id="filter-btn"
                        >
                          <i className="fas fa-filter"></i> Filter
                        </button>
                        <button
                          type="button"
                          className="btn btn-info btn-sm mx-3"
                        >
                          <i className="fas fa-clock"></i> Today
                        </button>
                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                        >
                          <i className="fas fa-calendar"></i> This Month
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: sortedAppointments.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={sortedAppointments}
                      rowKey={(record) => record.id}
                      rowClassName={(record) =>
                        record.outside_pune == "Yes" ? "green-row" : ""
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportList;
