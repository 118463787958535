import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// import { DatePicker } from "antd";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import { loadingService } from "../../helper/observer";

const AddPatients = () => {
  const navigate = useNavigate();
  const [ShowBedPopup, setShowBedPopup] = useState(false);
  const [PatientList, setPatientList] = useState([]);
  const [patientName, setPatientName] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);

    setUserData(data.data);
  }, []);

  const getPatientList = async (puser) => {
    let url;
    if (puser.role == 9) {
      url = Api.patientList;
    } else if (puser.role == 8 || puser.role == 7) {
      url = `${Api.branchpatientList}/${puser.hospitalid}`;
    } else if (puser.role == 6) {
      url = `${Api.branchpatientList}/${puser.branch_id}`;
    } else {
      url = `${Api.branchpatientList}/${puser.branch_id}`;
    }
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setPatientList(data);
  };

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getPatientList(userData);
    }
  }, [userData]);

  const options2 = PatientList.map((patient) => ({
    value: patient.id,
    label: `${patient.first_name} ${patient.last_name}`,
  }));

  const handleChange2 = (selectedOption) => {
    setPatientName(selectedOption);
  };

  return (
    <>
      <div>
        <Header />
        <Sidebar
          id="menu-item2"
          id1="menu-items2"
          activeClassName="add-patient"
        />
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Admission Form </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body"></div>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Patient<span className="login-danger">*</span>
                        </label>
                        <Select
                          className="form-control-alternative"
                          options={options2}
                          value={patientName}
                          onChange={handleChange2}
                          placeholder="Search And Select"
                          isSearchable
                          isClearable
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused
                                ? "none"
                                : "2px solid rgba(46, 55, 164, 0.1);",
                              boxShadow: state.isFocused
                                ? "0 0 0 1px #2e37a4"
                                : "none",
                              "&:hover": {
                                borderColor: state.isFocused
                                  ? "none"
                                  : "2px solid rgba(46, 55, 164, 0.1)",
                              },
                              borderRadius: "10px",
                              fontSize: "14px",
                              minHeight: "45px",
                            }),
                            dropdownIndicator: (base, state) => ({
                              ...base,
                              transform: state.selectProps.menuIsOpen
                                ? "rotate(-180deg)"
                                : "rotate(0)",
                              transition: "250ms",
                              width: "35px",
                              height: "35px",
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group select-gender">
                        <label className="gen-label">
                          Admission Mode<span className="login-danger">*</span>
                        </label>
                        <div className="form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              name=""
                              className="form-check-input"
                              value="Yes"
                            />
                            Regular
                          </label>
                        </div>
                        <div className="form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              name=""
                              className="form-check-input"
                              value="No"
                            />
                            Daycare
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          IPD NO <span className="login-danger">*</span>
                        </label>
                        <input className="form-control" type="text" disabled />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            setShowBedPopup(true);
                          }}
                        >
                          Click To Add Bed
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Incharge Doctor 1{" "}
                          <span className="login-danger">*</span>
                        </label>
                        <select className="form-select">
                          <option value="">Select</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Incharge Doctor 2{" "}
                          <span className="login-danger"></span>
                        </label>
                        <select className="form-select">
                          <option value="">Select</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Referred By <span className="login-danger">*</span>
                        </label>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Admission Type<span className="login-danger"></span>
                        </label>
                        <select className="form-select">
                          <option value="">Select</option>
                          <option value="">GENERAL ADMISSION</option>
                          <option value="">MINOR SURGERY</option>
                          <option value="">MAJOR SURGERY</option>
                          <option value="">CONSERVATIVE</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Department<span className="login-danger"></span>
                        </label>
                        <select className="form-select">
                          <option value="">Select</option>
                          <option value="">NURSING</option>
                          <option value="">ICU</option>
                          <option value="">HOUSEKEEPING</option>
                          <option value="">SURGICAL</option>
                          <option value="">CSSD</option>
                          <option value="">MRD</option>
                          <option value="">OPD</option>
                          <option value="">PATHOLOGY</option>
                          <option value="">X</option>
                          <option value="">RADIOLOGY</option>
                          <option value="">IPD</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Applicable Rate List
                          <span className="login-danger"></span>
                        </label>
                        <select className="form-select">
                          <option value="">Select</option>
                          <option value="">HOSPITAL</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Package<span className="login-danger"></span>
                        </label>
                        <select className="form-select">
                          <option value="">Select</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Package Amount<span className="login-danger">*</span>
                        </label>
                        <input className="form-control" type="text" disabled />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Remark<span className="login-danger">*</span>
                        </label>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div className="col-12 col-sm-12">
                      <div className="form-group local-forms">
                        <label>
                          complaints on Admission{" "}
                          <span className="login-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          rows={3}
                          cols={30}
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div className="col-12 col-sm-12">
                      <div className="form-group local-forms">
                        <label>
                          Treatment Plan <span className="login-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          rows={3}
                          cols={30}
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {/* <h6 className="card-title">Bottom line tabs</h6> */}
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item">
                        <Link
                          className="nav-link active"
                          to="#bottom-tab4"
                          data-bs-toggle="tab"
                        >
                          All
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="#bottom-tab1"
                          data-bs-toggle="tab"
                        >
                          EX-FINDING
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="#bottom-tab2"
                          data-bs-toggle="tab"
                        >
                          SYS-FINDING
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="#bottom-tab3"
                          data-bs-toggle="tab"
                        >
                          LOCAL EXAMINATION
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane show active" id="bottom-tab4">
                        <h3 className="text-success">EX-FINDING :</h3>
                        <hr></hr>
                        <div className="form-group row">
                          <label className="col-form-label col-lg-2">
                            Temp
                          </label>
                          <div className="col-md-3">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                F
                              </span>
                            </div>
                          </div>

                          <label className="col-form-label col-lg-2">
                            Pulse
                          </label>
                          <div className="col-md-3">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                Min
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-form-label col-lg-2">Bp</label>
                          <div className="col-md-3">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                mmOfHg
                              </span>
                            </div>
                          </div>

                          <label className="col-form-label col-lg-2">
                            Weight
                          </label>
                          <div className="col-md-3">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                Kg
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-form-label col-lg-2">
                            Height
                          </label>
                          <div className="col-md-3">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                Cm
                              </span>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Tongue <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Throat <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Cyanosis <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Built <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Skin <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Nails <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Anaemla <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Stool <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Urine <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                        </div>
                        <h3 className="text-success">SYS-FINDING :</h3>
                        <hr></hr>
                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                CNS <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                RS <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                PA <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                CVS <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                        </div>
                        <h3 className="text-success">LOCAL EXAMINATION :</h3>
                        <hr></hr>
                        <div className="col-sm-3">
                          <div className="card">
                            <div className="card-body">
                              <div className="col-12 col-sm-12">
                                <div className="form-group local-forms">
                                  <label>
                                    LOCAL EXAMINATION{" "}
                                    <span className="login-danger">*</span>
                                  </label>
                                  <textarea
                                    className="form-control"
                                    rows={3}
                                    cols={30}
                                    defaultValue={""}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane show" id="bottom-tab1">
                        <h3 className="text-success">EX-FINDING :</h3>
                        <hr></hr>
                        <div className="form-group row">
                          <label className="col-form-label col-lg-2">
                            Temp
                          </label>
                          <div className="col-md-3">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                F
                              </span>
                            </div>
                          </div>

                          <label className="col-form-label col-lg-2">
                            Pulse
                          </label>
                          <div className="col-md-3">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                Min
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-form-label col-lg-2">Bp</label>
                          <div className="col-md-3">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                mmOfHg
                              </span>
                            </div>
                          </div>

                          <label className="col-form-label col-lg-2">
                            Weight
                          </label>
                          <div className="col-md-3">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                Kg
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-form-label col-lg-2">
                            Height
                          </label>
                          <div className="col-md-3">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                Cm
                              </span>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Tongue <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Throat <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Cyanosis <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Built <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Skin <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Nails <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Anaemla <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Stool <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Urine <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="bottom-tab2">
                        <h3 className="text-success">SYS-FINDING :</h3>
                        <hr></hr>
                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                CNS <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                RS <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                PA <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                CVS <span className="login-danger"></span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane" id="bottom-tab3">
                        <h3 className="text-success">LOCAL EXAMINATION :</h3>
                        <hr></hr>
                        <div className="col-sm-3">
                          <div className="card">
                            <div className="card-body">
                              <div className="col-12 col-sm-12">
                                <div className="form-group local-forms">
                                  <label>
                                    LOCAL EXAMINATION{" "}
                                    <span className="login-danger">*</span>
                                  </label>
                                  <textarea
                                    className="form-control"
                                    rows={3}
                                    cols={30}
                                    defaultValue={""}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={ShowBedPopup}
        onHide={() => setShowBedPopup(false)}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-md-6 col-xl-6">
              <div className="form-group local-forms">
                <label>
                  Room Type<span className="login-danger"></span>
                </label>
                <select className="form-select">
                  <option value="">Select</option>
                  <option value="">GENERAL WARD</option>
                  <option value="">SEMI-SPECIAL ROOM</option>
                  <option value="">SPECIAL NON-AC ROOM</option>
                  <option value="">DELUXE ROOM</option>
                  <option value="">ICU</option>
                  <option value="">HDU ROOM</option>
                  <option value="">SPECIAL ROOM WITH AC</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-6">
              <button type="button" className="btn btn-warning">
                Occupied
              </button>
              <button type="button" className="btn btn-success">
                Available
              </button>
            </div>
          </div>
          <div className="d-flex">
            <div className="form-group local-forms">
              <h2 className="">MALE</h2>
              <div className="bed-buttons">
                <button className="btn btn-success bed occupied me-2">
                  Bed - 1
                </button>
                <button className="btn btn-success bed available me-2">
                  Bed - 2
                </button>
                <button className="btn btn-warning bed available me-2">
                  Bed - 3
                </button>
                <button className="btn btn-success bed available">
                  Bed - 4
                </button>
              </div>
            </div>
          </div>
          <div className="bed-section">
            <div className="form-group local-forms">
              <h2 className="">FEMALE</h2>
              <div className="bed-buttons">
                <button className="btn btn-warning bed available me-2">
                  Bed - 1
                </button>
                <button className="btn btn-success bed occupied me-2">
                  Bed - 2
                </button>
                <button className="btn btn-success bed available me-2">
                  Bed - 3
                </button>
                <button className="btn btn-success bed available">
                  Bed - 4
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowBedPopup(false)}
          >
            Close
          </button>
          {/* <button type="button" className="btn btn-success">
            Save
          </button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddPatients;
