/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import Select from "react-select";
import { loadingService } from "../../helper/observer";

const DischargeRequestList = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [AppointmentList, setAppointmentList] = useState([]);
  const [LabShow, setLabShow] = useState(false);
  const [PharmaShow, setPharmaShow] = useState(false);
  const [XrayShow, setXrayShow] = useState(false);
  const [SonographyShow, setSonographyShow] = useState(false);
  const [userData, setUserData] = useState(null);
  const [LabOutstandingAmt, setLabOutstandingAmt] = useState("0.00");
  const [PharmaOutstandingAmt, setPharmaOutstandingAmt] = useState("0.00");
  const [XrayOutstandingAmt, setXrayOutstandingAmt] = useState("0.00");
  const [SonographyOutstandingAmt, setSonographyOutstandingAmt] = useState("0.00");

  const outsidePuneAppointments = AppointmentList.filter(
    (appointment) => appointment.outside_pune === "Yes"
  );

  const withinPuneAppointments = AppointmentList.filter(
    (appointment) => appointment.outside_pune === "No"
  );

  // outsidePuneAppointments.sort((a, b) => {
  //   return new Date(b.appointment_date) - new Date(a.appointment_date);
  // });

  // withinPuneAppointments.sort((a, b) => {
  //   return new Date(a.appointment_date) - new Date(b.appointment_date);
  // });

  const sortedAppointments = [
    ...outsidePuneAppointments,
    ...withinPuneAppointments,
  ];

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Reg Date",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      render: (text, record) => {
        // Convert the date of birth to a Date object
        const dob = new Date(record.created_at);

        // Define an array to map month numbers to their respective names
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // Format the date in the desired format
        const formattedDate = `${dob.getDate()}-${
          monthNames[dob.getMonth()]
        }-${dob.getFullYear()}`;

        // Return the formatted date
        return formattedDate;
      },
    },
    {
      title: "IPD Id",
      dataIndex: "ipd_id",
      sorter: (a, b) => a.ipd_id.length - b.ipd_id.length,
    },
    {
      title: "Patient",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.length - b.patient_name.length,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      sorter: (a, b) => a.mobile_number.length - b.mobile_number.length,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => a.gender.length - b.gender.length,
    },

    {
      title: "Outside Pune",
      dataIndex: "outside_pune",
      sorter: (a, b) => a.outside_pune.length - b.outside_pune.length,
    },
    {
      title: "Ward",
      dataIndex: "ward",
      sorter: (a, b) => a.ward.length - b.ward.length,
    },
    {
      title: "Bed No",
      dataIndex: "bed_number",
      sorter: (a, b) => a.bed_number.length - b.bed_number.length,
    },
    {
      title: "Actions",
      dataIndex: null, // You can keep this null if it's just for the action
      render: (text, record) => {
        return (
          <>
            {record.discharge_approved_by_lab == 0 ? (
              <a
                className="badge bg-danger-light me-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setLabShow(record.id); // Only trigger when amount is empty or null
                }}
              >
                Lab Approval
              </a>
            ) : (
              <a className="badge bg-success-light me-2">Lab Approved</a>
            )}
            {/* ------------------ */}
            {record.discharge_approved_by_pharma == 0 ? (
              <a
                className="badge bg-danger-light me-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setPharmaShow(record.id); // Only trigger when amount is empty or null
                }}
              >
                Pharma Approval
              </a>
            ) : (
              <a className="badge bg-success-light me-2">Pharma Approved</a>
            )}
            {/* ------------------ */}
            {record.discharge_approved_by_xray == 0 ? (
              <a
                className="badge bg-danger-light me-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setXrayShow(record.id); // Only trigger when amount is empty or null
                }}
              >
                Xray Approval
              </a>
            ) : (
              <a className="badge bg-success-light me-2">Xray Approved</a>
            )}
            {/* ------------------ */}

            {record.discharge_approved_by_sonography == 0 ? (
              <a
                className="badge bg-danger-light"
                onClick={(e) => {
                  e.stopPropagation();
                  setSonographyShow(record.id); // Only trigger when amount is empty or null
                }}
              >
                Sonography Approval
              </a>
            ) : (
              <a className="badge bg-success-light">Sonography Approved</a>
            )}
          </>
        );
      },
    },
  ];

  const getAppointmentList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.ipddischargereqlist;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchipddischargereqlist}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchipddischargereqlist}/${user.branch_id}`;
    } else {
      url = `${Api.branchipddischargereqlist}/${user.branch_id}`;
    }
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setAppointmentList(data);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    setUserData(data.data);
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getAppointmentList(userData);
    }
  }, [userData]);

  const handleDischargeLab = async () => {
    // Implement your delete logic here using deleteId
    let payload = {
      outsatnding_amt_lab: LabOutstandingAmt,
    };

    loadingService.next(true);
    const { data, status } = await AuthApi.post(
      `${Api.approvedischargeLab}/${LabShow}`,
      payload
    );
    loadingService.next(false);

    if (status != 200) {
      toast.success("Approval Request Submitted");
      setLabShow(false);
      getAppointmentList(userData);
      if (status == 401) navigate("/login");
      return;
    }
  };

  const handleDischargePharma = async () => {
    let payload = {
      outsatnding_amt_pharma: PharmaOutstandingAmt,
    };
    // Implement your delete logic here using deleteId
    loadingService.next(true);
    const { data, status } = await AuthApi.post(
      `${Api.approvedischargePharma}/${PharmaShow}`,
      payload
    );
    loadingService.next(false);

    if (status != 200) {
      toast.success("Approval Request Submitted");
      setPharmaShow(false);
      getAppointmentList(userData);
      if (status == 401) navigate("/login");
      return;
    }
  };

  const handleDischargeXray = async () => {
    let payload = {
      outsatnding_amt_xray: XrayOutstandingAmt,
    };
    // Implement your delete logic here using deleteId
    loadingService.next(true);
    const { data, status } = await AuthApi.post(
      `${Api.approvedischargeXray}/${XrayShow}`,
      payload
    );
    loadingService.next(false);

    if (status != 200) {
      toast.success("Approval Request Submitted");
      setXrayShow(false);
      getAppointmentList(userData);
      if (status == 401) navigate("/login");
      return;
    }
  };

  const handleDischargeSonography = async () => {
    let payload = {
      outsatnding_amt_sonography: SonographyOutstandingAmt,
    };
    // Implement your delete logic here using deleteId
    loadingService.next(true);
    const { data, status } = await AuthApi.post(
      `${Api.approvedischargeSonography}/${SonographyShow}`,
      payload
    );
    loadingService.next(false);

    if (status != 200) {
      toast.success("Approval Request Submitted");
      setSonographyShow(false);
      getAppointmentList(userData);
      if (status == 401) navigate("/login");
      return;
    }
  };

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Discharge Request </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">
                    Discharge Request List
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Discharge Request List</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                            {/* <div className="add-group">
                              <Link
                                to="/addappoinments"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2"></Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: sortedAppointments.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={sortedAppointments}
                      rowKey={(record) => record.id}
                      rowClassName={(record) =>
                        record.outside_pune == "Yes"
                          ? "green-row clickable-row"
                          : "clickable-row"
                      }
                      // onRow={(record, rowIndex) => {
                      //   return {
                      //     onClick: () => {
                      //       // Redirect to the info page when the row is clicked
                      //       navigate(`/addipdformsecond/${record.id}`);
                      //     },
                      //   };
                      // }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={LabShow} onHide={() => setLabShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure want to Approved Request to Dicharge this Patient?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div className="col-md-3 col-xl-3">
              <img src={imagesend} alt="#" width={50} height={46} />
            </div>
            <div className="col-md-9 col-xl-9">
              <div className="form-group local-forms">
                <label>
                  Outstanding Amount <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={LabOutstandingAmt}
                  onChange={(e) => setLabOutstandingAmt(e.target.value)}
                  
                />
                <p> if any oustanding Enter new value</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white me-2"
            variant="secondary"
            onClick={() => setLabShow(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="btn btn-danger"
            onClick={handleDischargeLab}
          >
            Approved Discharge Request
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={PharmaShow} onHide={() => setPharmaShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure want to Approved Request to Dicharge this Patient?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div className="col-md-3 col-xl-3">
              <img src={imagesend} alt="#" width={50} height={46} />
            </div>
            <div className="col-md-9 col-xl-9">
              <div className="form-group local-forms">
                <label>
                  Outstanding Amount <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={PharmaOutstandingAmt}
                  onChange={(e) => setPharmaOutstandingAmt(e.target.value)}
                  
                />
                <p>if any oustanding Enter new value</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white me-2"
            variant="secondary"
            onClick={() => setPharmaShow(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="btn btn-danger"
            onClick={handleDischargePharma}
          >
            Approved Discharge Request
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={XrayShow} onHide={() => setXrayShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure want to Approved Request to Dicharge this Patient?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div className="col-md-3 col-xl-3">
              <img src={imagesend} alt="#" width={50} height={46} />
            </div>
            <div className="col-md-9 col-xl-9">
              <div className="form-group local-forms">
                <label>
                  Outstanding Amount <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={XrayOutstandingAmt}
                  onChange={(e) => setXrayOutstandingAmt(e.target.value)}
                  
                />
                <p> if any oustanding Enter new value</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white me-2"
            variant="secondary"
            onClick={() => setXrayShow(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="btn btn-danger"
            onClick={handleDischargeXray}
          >
            Approved Discharge Request
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={SonographyShow} onHide={() => setSonographyShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure want to Approved Request to Dicharge this Patient?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between">
            <div className="col-md-3 col-xl-3">
              <img src={imagesend} alt="#" width={50} height={46} />
            </div>
            <div className="col-md-9 col-xl-9">
              <div className="form-group local-forms">
                <label>
                  Outstanding Amount <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={SonographyOutstandingAmt}
                  onChange={(e) => setSonographyOutstandingAmt(e.target.value)}
                  
                />
                <p> if any oustanding Enter new value</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white me-2"
            variant="secondary"
            onClick={() => setSonographyShow(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="btn btn-danger"
            onClick={handleDischargeSonography}
          >
            Approved Discharge Request
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DischargeRequestList;
