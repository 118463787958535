/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import Select from "react-select";
import { loadingService } from "../../helper/observer";
import { Dropdown } from "react-bootstrap";
import { DatePicker } from "antd";

const IpdList = () => {
  const navigate = useNavigate();
  const [AppointmentList, setAppointmentList] = useState([]);
  const [show, setShow] = useState(false);
  const [AdmitPatientShow, setAdmitPatientShow] = useState(false);
  const [userData, setUserData] = useState(null);
  const [dropdownValue, setDropdownValue] = useState("");

  const handleDischarge = async () => {
    // Implement your delete logic here using deleteId
    loadingService.next(true);
    const { data, status } = await AuthApi.get(
      `${Api.requestfordischarge}/${show}`
    );
    loadingService.next(false);

    if (status != 200) {
      toast.success("Discharge Request Submitted");
      setShow(false);
      getAppointmentList(userData);
      if (status == 401) navigate("/login");
      return;
    }
  };

  const outsidePuneAppointments = AppointmentList.filter(
    (appointment) => appointment.outside_pune === "Yes"
  );

  const withinPuneAppointments = AppointmentList.filter(
    (appointment) => appointment.outside_pune === "No"
  );

  // outsidePuneAppointments.sort((a, b) => {
  //   return new Date(b.appointment_date) - new Date(a.appointment_date);
  // });

  // withinPuneAppointments.sort((a, b) => {
  //   return new Date(a.appointment_date) - new Date(b.appointment_date);
  // });

  const sortedAppointments = [
    ...outsidePuneAppointments,
    ...withinPuneAppointments,
  ];

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      render: (text, record) => {
        // Convert the date of birth to a Date object
        const dob = new Date(record.created_at);

        // Define an array to map month numbers to their respective names
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // Format the date in the desired format
        const formattedDate = `${dob.getDate()}-${
          monthNames[dob.getMonth()]
        }-${dob.getFullYear()}`;

        // Return the formatted date
        return formattedDate;
      },
    },
    {
      title: "IPD No",
      dataIndex: "ipd_id",
      sorter: (a, b) => a.ipd_id.length - b.ipd_id.length,
    },
    {
      title: "Patient Registration No",
      dataIndex: "patientId",
      sorter: (a, b) => a.patientId.length - b.patientId.length,
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.length - b.patient_name.length,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      sorter: (a, b) => a.mobile_number.length - b.mobile_number.length,
    },
    {
      title: "Age",
      dataIndex: "age",
      sorter: (a, b) => a.age.length - b.age.length,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => a.gender.length - b.gender.length,
    },

    {
      title: "Outside Pune",
      dataIndex: "outside_pune",
      sorter: (a, b) => a.outside_pune.length - b.outside_pune.length,
    },
    {
      title: "Ward",
      dataIndex: "ward",
      sorter: (a, b) => a.ward.length - b.ward.length,
    },
    {
      title: "Bed No",
      dataIndex: "bed_number",
      sorter: (a, b) => a.bed_number.length - b.bed_number.length,
    },
    {
      title: "Incharge",
      dataIndex: "doctor_name",
      sorter: (a, b) => a.doctor_name.length - b.doctor_name.length,
    },
    {
      title: "Actions",
      dataIndex: null, // You can keep this null if it's just for the action
      render: (text, record) => {
        const statusOptions = {
          1: "Complete",
          2: "Pending",
        };
        const badgeClass =
          status === "1"
            ? "custom-badge status-green"
            : status === "2"
            ? "custom-badge status-orange"
            : "custom-badge status-orange";

        return (
          <>
            <a
              className="badge bg-primary-light me-2"
              onClick={(e) => {
                e.stopPropagation();
                navigate('/addipdadmission');
              }}
            >
              Admission
            </a>
            <a
              className="badge bg-danger-light me-2"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/dichargeinfo`);
                // navigate(`/dichargeinfo/${record.id}`);
              }}
            >
              Discharge
            </a>
            {/* {record.req_for_discharge == 0 ? (
              <a
                className="badge bg-primary-light me-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setShow(record.id); // Only trigger when amount is empty or null
                }}
              >
                Discharge
              </a>
            ) : (
              <a className="badge bg-danger-light me-2" onClick={(e) => {
                e.stopPropagation();
              }}>Discharge Request In Pending</a>
            )} */}
            <a
              className="badge bg-primary-light me-2"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/billingview");
              }}
            >
              Ipd Billing
            </a>
            <br></br>
            <br></br>
            <div className="d-flex">
              <Dropdown
                onSelect={(e) => handleDropdownChange(record.id, e)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className={`${badgeClass} me-2`}>
                  {statusOptions[status]}
                  <Dropdown.Toggle variant="">OT Workup</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="1">Pre-Operative</Dropdown.Item>
                    <Dropdown.Item eventKey="2">OT Record</Dropdown.Item>
                  </Dropdown.Menu>
                </span>
              </Dropdown>
              <a
                className="badge bg-success-light me-2"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/ipdviewfile/${record.id}`);
                }}
              >
                View FIle
              </a>
              <Dropdown
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="custom-badge status-green">
                  <Dropdown.Toggle variant="">🖨Print</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="1">Admission Form</Dropdown.Item>
                    <Dropdown.Item eventKey="2">
                      Discharge Summary
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3">IPD Bill</Dropdown.Item>
                    <Dropdown.Item eventKey="4">IPD Running Bill</Dropdown.Item>
                    <Dropdown.Item eventKey="5">Patient File</Dropdown.Item>
                    <Dropdown.Item eventKey="6">Nurse Checkup</Dropdown.Item>
                    <Dropdown.Item eventKey="7">OT Record</Dropdown.Item>
                    <Dropdown.Item eventKey="8">Patient Sticker</Dropdown.Item>
                    <Dropdown.Item eventKey="9">
                      Add Medicine Indent
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </span>
              </Dropdown>
            </div>
          </>
        );
      },
    },
  ];
  const admitpatientcolumns = [
    {
      title: "#",
      dataIndex: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "IPD No",
      dataIndex: "ipd_id",
      sorter: (a, b) => a.ipd_id.length - b.ipd_id.length,
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.length - b.patient_name.length,
    },
    {
      title: "Doctor",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.length - b.patient_name.length,
    },
    {
      title: "Admission Date",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      render: (text, record) => {
        // Convert the date of birth to a Date object
        const dob = new Date(record.created_at);

        // Define an array to map month numbers to their respective names
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // Format the date in the desired format
        const formattedDate = `${dob.getDate()}-${
          monthNames[dob.getMonth()]
        }-${dob.getFullYear()}`;

        // Return the formatted date
        return formattedDate;
      },
    },

    {
      title: "Bed Details",
      dataIndex: "bed_number",
      sorter: (a, b) => a.bed_number.length - b.bed_number.length,
    },
    {
      title: "Bill Amount",
      dataIndex: "ipd_final_amount",
      sorter: (a, b) => a.ipd_final_amount.length - b.ipd_final_amount.length,
    },
    {
      title: "Advance",
      dataIndex: "ipd_final_amount",
      sorter: (a, b) => a.ipd_final_amount.length - b.ipd_final_amount.length,
    },
    {
      title: "Balance",
      dataIndex: "ipd_final_amount",
      sorter: (a, b) => a.ipd_final_amount.length - b.ipd_final_amount.length,
    },
  ];

  const getAppointmentList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.ipdmainlist;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchmainipdlist}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchmainipdlist}/${user.branch_id}`;
    } else {
      url = `${Api.branchmainipdlist}/${user.branch_id}`;
    }
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setAppointmentList(data);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    setUserData(data.data);
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getAppointmentList(userData);
    }
  }, [userData]);

  const handleDropdownChange = (value, selectedValue) => {
    setDropdownValue(value);
    if (selectedValue == 1) {
      navigate(`/preoperativepage/${value}`);
    } else {
      navigate(`/otrecordpage/${value}`);
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const handleActionClick = () => {
    setShowDropdown(!showDropdown); // Toggle dropdown visibility
  };

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">IPD </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">IPD List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-2">
              <div
                className="col-6 col-6 col-md-6 col-sm-6 col-lg-6 col-xl-12 text-center text-center"
                onClick={() => {
                  navigate("/addipdadmission");
                }}
              >
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    {/* <img src={searchnormal} alt="#" className="icon-size" /> */}
                    🛏
                  </div>
                  <div className="dash-content dash-count text-center">
                    <h4>
                      <i style={{ color: "red" }} className="fas fa-plus" />
                      ADMISSION FORM
                    </h4>
                  </div>
                </div>
              </div>
              <div
                className="col-6 col-6 col-md-6 col-sm-6 col-lg-6 col-xl-12 text-center text-center"
                onClick={() => {
                  navigate("/ipdsearcholdrecordpage");
                }}
              >
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={searchnormal} alt="#" className="icon-size" />
                  </div>
                  <div className="dash-content dash-count text-center">
                    <h4>
                      <i style={{ color: "red" }} className="fas fa-plus" />
                      SEARCH OLD RECORD
                    </h4>
                  </div>
                </div>
              </div>
              <div
                className="col-6 col-6 col-md-6 col-sm-6 col-lg-6 col-xl-12 text-center text-center"
                onClick={() => {
                  setAdmitPatientShow(true); // Only trigger when amount is empty or null
                }}
              >
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    {/* <img src={searchnormal} alt="#" className="icon-size" /> */}
                    👤
                  </div>
                  <div className="dash-content dash-count text-center">
                    <h4>
                      <i style={{ color: "red" }} className="fas fa-plus" />
                      ADMIT PATIENT REPORT
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      {/* <h6 className="card-title">Bottom line justified</h6> */}
                      <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                        <li className="nav-item">
                          <Link
                            className="nav-link active"
                            to="#bottom-justified-tab1"
                            data-bs-toggle="tab"
                          >
                            Admit Patient
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="#bottom-justified-tab2"
                            data-bs-toggle="tab"
                          >
                            Daycare Patient
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="#bottom-justified-tab3"
                            data-bs-toggle="tab"
                          >
                            Discharge Patient
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="#bottom-justified-tab4"
                            data-bs-toggle="tab"
                          >
                            Nurse Task
                          </Link>
                        </li>

                        <li className="nav-item dropdown">
                          <a
                            className="nav-link"
                            href="#!"
                            onClick={handleActionClick}
                          >
                            Action
                          </a>
                          {/* Dropdown Menu */}
                          {showDropdown && (
                            <ul className="dropdown-menu show">
                              <li>
                                <a className="dropdown-item" href="#!">
                                  Room Transfer
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#!">
                                  Advance Payment
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#!">
                                  Refund Payment
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#!">
                                  Search IPD Record
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#!">
                                  Delivery & MTP Register
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#!">
                                  Medicine Indent
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#!">
                                  IPD / OT Booking
                                </a>
                              </li>
                            </ul>
                          )}
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className="tab-pane show active"
                          id="bottom-justified-tab1"
                        >
                          <div className="card card-table show-entire">
                            <div className="card-body">
                              {/* Table Header */}
                              <div className="page-table-header mb-2">
                                <div className="row align-items-center">
                                  <div className="col">
                                    <div className="doctor-table-blk">
                                      <h3>IPD List</h3>
                                      <div className="doctor-search-blk">
                                        <div className="top-nav-search table-search-blk">
                                          <form>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Search here"
                                            />
                                            <Link className="btn">
                                              <img src={searchnormal} alt="#" />
                                            </Link>
                                          </form>
                                        </div>
                                        {/* <div className="add-group">
                              <Link
                                to="/addipdadmission"
                                className="btn btn-primary add-pluss ms-2"
                              >
                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div> */}
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="col-auto text-end float-end ms-auto download-grp">
                                    <Link to="#" className=" me-2">
                                      <img src={pdficon} alt="#" />
                                    </Link>
                                    <Link to="#" className=" me-2"></Link>
                                    <Link to="#" className=" me-2">
                                      <img src={pdficon3} alt="#" />
                                    </Link>
                                    <Link to="#">
                                      <img src={pdficon4} alt="#" />
                                    </Link>
                                  </div> */}
                                </div>
                                <div className="row align-items-center my-3">
                                  <div className="col-md-3">
                                    <label>Filter by Date</label>
                                    <div className="input-group align-items-baseline">
                                      <DatePicker
                                        className="form-control datetimepicker" // Keeping it like input
                                        // selected={fromdate}
                                        // onChange={(date) => setFromdate(date)}
                                        placeholderText="From"
                                        dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                      />
                                      <span className="input-group-text">
                                        to
                                      </span>
                                      <DatePicker
                                        className="form-control datetimepicker" // Keeping it like input
                                        // selected={todate}
                                        // onChange={(date) => setTodate(date)}
                                        placeholderText="To"
                                        dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                      />
                                    </div>
                                  </div>
                                  {/* <!-- Filter and Reset Buttons --> */}
                                  <div className="col-md-3 d-flex justify-content-end pt-3 doctor-submit">
                                    <button
                                      className="btn btn-primary me-2"
                                      id="filter-btn"
                                    >
                                      Filter
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary cancel-form"
                                    >
                                      Reset
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {/* /Table Header */}
                              <div className="table-responsive doctor-list">
                                <Table
                                  pagination={{
                                    total: sortedAppointments.length,
                                    showTotal: (total, range) =>
                                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                    // showSizeChanger: true,
                                    onShowSizeChange: onShowSizeChange,
                                    itemRender: itemRender,
                                  }}
                                  columns={columns}
                                  dataSource={sortedAppointments}
                                  rowKey={(record) => record.id}
                                  rowClassName={(record) =>
                                    record.outside_pune == "Yes"
                                      ? "green-row clickable-row"
                                      : "clickable-row"
                                  }
                                  onRow={(record, rowIndex) => {
                                    return {
                                      onClick: () => {
                                        // Redirect to the info page when the row is clicked
                                        navigate(
                                          `/addipdformsecond/${record.id}`
                                        );
                                      },
                                    };
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="bottom-justified-tab2">
                          <div className="card-body">
                            {/* Table Header */}
                            <div className="page-table-header mb-2">
                              <div className="row align-items-center">
                                <div className="col">
                                  <div className="doctor-table-blk">
                                    <h3>IPD List</h3>
                                    <div className="doctor-search-blk">
                                      <div className="top-nav-search table-search-blk">
                                        <form>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search here"
                                          />
                                          <Link className="btn">
                                            <img src={searchnormal} alt="#" />
                                          </Link>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-auto text-end float-end ms-auto download-grp">
                                  <h3 className="text-danger">
                                    <b>Active Admission : 0</b>
                                  </h3>
                                </div>
                              </div>
                              <div className="row align-items-center my-3">
                                <div className="col-md-3">
                                  <label>Filter by Date</label>
                                  <div className="input-group align-items-baseline">
                                    <DatePicker
                                      className="form-control datetimepicker" // Keeping it like input
                                      // selected={fromdate}
                                      // onChange={(date) => setFromdate(date)}
                                      placeholderText="From"
                                      dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                    />
                                    <span className="input-group-text">to</span>
                                    <DatePicker
                                      className="form-control datetimepicker" // Keeping it like input
                                      // selected={todate}
                                      // onChange={(date) => setTodate(date)}
                                      placeholderText="To"
                                      dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                    />
                                  </div>
                                </div>
                                {/* <!-- Filter and Reset Buttons --> */}
                                <div className="col-md-3 d-flex justify-content-end pt-3 doctor-submit">
                                  <button
                                    className="btn btn-primary me-2"
                                    id="filter-btn"
                                  >
                                    Filter
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary cancel-form"
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* /Table Header */}
                            <div className="table-responsive doctor-list">
                              <Table />
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="bottom-justified-tab3">
                          <div className="card-body">
                            {/* Table Header */}
                            <div className="page-table-header mb-2">
                              <div className="row align-items-center">
                                <div className="col">
                                  <div className="doctor-table-blk">
                                    <h3>IPD List</h3>
                                    <div className="doctor-search-blk">
                                      <div className="top-nav-search table-search-blk">
                                        <form>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search here"
                                          />
                                          <Link className="btn">
                                            <img src={searchnormal} alt="#" />
                                          </Link>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-auto text-end float-end ms-auto download-grp">
                                  <h3 className="text-danger">
                                    <b>Pending Bill : 135</b>
                                  </h3>
                                </div>
                              </div>
                              <div className="row align-items-center my-3">
                                <div className="col-md-3">
                                  <label>Filter by Date</label>
                                  <div className="input-group align-items-baseline">
                                    <DatePicker
                                      className="form-control datetimepicker" // Keeping it like input
                                      // selected={fromdate}
                                      // onChange={(date) => setFromdate(date)}
                                      placeholderText="From"
                                      dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                    />
                                    <span className="input-group-text">to</span>
                                    <DatePicker
                                      className="form-control datetimepicker" // Keeping it like input
                                      // selected={todate}
                                      // onChange={(date) => setTodate(date)}
                                      placeholderText="To"
                                      dateFormat="dd/MM/yyyy" // Change format as per your requirement
                                    />
                                  </div>
                                </div>
                                {/* <!-- Filter and Reset Buttons --> */}
                                <div className="col-md-3 d-flex justify-content-end pt-3 doctor-submit">
                                  <button
                                    className="btn btn-primary me-2"
                                    id="filter-btn"
                                  >
                                    Filter
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary cancel-form"
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* /Table Header */}
                            <div className="table-responsive doctor-list">
                              <Table
                                pagination={{
                                  total: sortedAppointments.length,
                                  showTotal: (total, range) =>
                                    `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                  // showSizeChanger: true,
                                  onShowSizeChange: onShowSizeChange,
                                  itemRender: itemRender,
                                }}
                                columns={columns}
                                dataSource={sortedAppointments}
                                rowKey={(record) => record.id}
                                rowClassName={(record) =>
                                  record.outside_pune == "Yes"
                                    ? "green-row clickable-row"
                                    : "clickable-row"
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="bottom-justified-tab4">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="card card-table show-entire">
                                <div className="card-body">
                                  {/* Table Header */}
                                  <div className="page-table-header mb-2">
                                    <div className="row align-items-center">
                                      <div className="col">
                                        <div className="doctor-table-blk">
                                          <div className="doctor-search-blk">
                                            <div className="top-nav-search table-search-blk">
                                            <h3>Task Status</h3>
                                              <div className="">
                                                <select className="form-select">
                                                  <option value="">
                                                    Select
                                                  </option>
                                                  <option value="">
                                                    Pending Task
                                                  </option>
                                                  <option value="">
                                                    Complete Task
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <div className="top-nav-search table-search-blk">
                                            <h3>Search</h3>
                                              <form>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Patient Name,Medicine,Investigation"
                                                />
                                                <Link className="btn">
                                                  <img
                                                    src={searchnormal}
                                                    alt="#"
                                                  />
                                                </Link>
                                              </form>
                                            </div>
                                            <div className="top-nav-search table-search-blk">
                                            <h3>Task Type</h3>
                                              <div className="">
                                                <select className="form-select">
                                                  <option value="">
                                                    Select
                                                  </option>
                                                  <option value="">
                                                    IPD No
                                                  </option>
                                                  <option value="">UHID</option>
                                                  <option value="">
                                                    First Name
                                                  </option>
                                                  <option value="">
                                                    Last Name
                                                  </option>
                                                  <option value="">
                                                    Mobile No
                                                  </option>
                                                  <option value="">
                                                    Admission Date (dd/mm/yyyy)
                                                  </option>
                                                  <option value="">
                                                    Discharge Date (dd/mm/yyyy)
                                                  </option>
                                                  <option value="">
                                                    IPD BIll No
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* /Table Header */}
                                  <div className="table-responsive doctor-list">
                                    <Table
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure want to Submit Request to Dicharge this Patient?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imagesend} alt="#" width={50} height={46} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white me-2"
            variant="secondary"
            onClick={() => setShow(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="btn btn-danger"
            onClick={handleDischarge}
          >
            Submit Discharge Request
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={AdmitPatientShow}
        onHide={() => setAdmitPatientShow(false)}
        size="xl"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="doctor-search-blk d-flex justify-content-between">
            <div className="d-flex justify-content-between">
              <div className="top-nav-search table-search-blk">
                <form>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <Link className="btn">
                    <img src={searchnormal} alt="#" />
                  </Link>
                </form>
              </div>
              &nbsp;&nbsp;
              <div className="add-group">
                <label>
                  <input type="checkbox" className="form-check-input" />
                  &nbsp;&nbsp;<b>Only Admit Patient</b>
                </label>
                &nbsp;&nbsp;
                <label>
                  <input type="checkbox" className="form-check-input" />
                  &nbsp;&nbsp;<b>Only TPA Patient</b>
                </label>
              </div>
            </div>
            &nbsp;&nbsp;
            <div>
              <div className="">
                <button className="btn btn-light me-2">🖨Print</button>
                <button className="btn btn-primary">Excel</button>
              </div>
            </div>
          </div>
          <div className="table-responsive doctor-list">
            <Table
              pagination={{
                total: sortedAppointments.length,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                // showSizeChanger: true,
                onShowSizeChange: onShowSizeChange,
                itemRender: itemRender,
              }}
              columns={admitpatientcolumns}
              dataSource={sortedAppointments}
              rowKey={(record) => record.id}
              rowClassName={(record) =>
                record.outside_pune == "Yes"
                  ? "green-row clickable-row"
                  : "clickable-row"
              }
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    // Redirect to the info page when the row is clicked
                    navigate(`/addipdformsecond/${record.id}`);
                  },
                };
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white me-2"
            variant="secondary"
            onClick={() => setAdmitPatientShow(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IpdList;
