import React, { useState, useEffect } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
// import DonutChart from "./DonutChart";
import Sidebar from "../../Sidebar";
import Header from "../../Header";
// import PatientChart from "./PaitentChart";
import { useNavigate } from "react-router-dom";

// import Select from "react-select";
import {
  Inventery,
  Radiology,
  Pharmacy,
  Nurse,
  IPD_Bill,
  Discharge,
  ot,
  ipd,
  certificate,
  ehr,
  invoice,
  morning_img_01,
  patient,
  opd,
  calendar,
  Account,
  Report,
  Blood_Bank,
  SMS,
  Rate_List,
  Laboratory,
  Insurance,
  User,
  Setting,
} from "../../imagepath";
import { Link } from "react-router-dom";

const Admin_Dashboard = () => {
  const navigate = useNavigate();
  // const [selectedOption, setSelectedOption] = useState(null);
  const [UserName, setUserName] = useState(null);

  // eslint-disable-next-line no-unused-vars
  // const [year, setyear] = useState([
  //   { value: 1, label: "2022" },
  //   { value: 2, label: "2021" },
  //   { value: 3, label: "2020" },
  //   { value: 4, label: "2019" },
  // ]);

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    console.log(udata);
    if (!udata) {
      navigate("/login");
      return;
    }
  }, []);

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    setUserName(data.data.username);
  }, []);

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item"
        id1="menu-items"
        activeClassName="admin-dashboard"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Admin Dashboard</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                      Good Morning, <span>{UserName}</span>
                    </h2>
                    <p>Have a nice day at work</p>
                  </div>
                </div>
                <div className="col-md-6 position-blk">
                  <div className="morning-img">
                    <img src={morning_img_01} alt="#" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center text-center" onClick={() => navigate("/patientslist")}>
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={patient} alt="#" className="icon-size" />
                  </div>
                  <div className="dash-content dash-count text-center">
                    <h4>Patient</h4>
                    {/* <h2>
                      <CountUp delay={0.4} end={250} duration={0.6} />
                    </h2> */}
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center" onClick={() => navigate("/opdlist")}>
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={opd} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>OPD</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center" onClick={() => navigate("/billinglist")}>
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={invoice} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Billing</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center">
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={ehr} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>EHR</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center" onClick={() => navigate("/appoinmentlist")}>
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={calendar} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Calender</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center">
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={certificate} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Certificate</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center" onClick={() => navigate("/ipdlist")}>
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={ipd} alt="#" />
                  </div>
                  <div className="dash-content dash-count flex-grow-1">
                    <h4>IPD</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center">
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={ot} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>OT</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center" onClick={() => navigate("/dichargeinfo")}>
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={Discharge} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Discharge</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center" onClick={() => navigate("/billingview")}>
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={IPD_Bill} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>IPD Bill</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center" onClick={() => navigate("/receptionistlist")}>
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={Nurse} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Nurse</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center">
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={ipd} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>IPD File</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center">
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={Pharmacy} alt="#" />
                  </div>
                  <div className="dash-content dash-count flex-grow-1">
                    <h4>Pharamacy</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center">
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={Inventery} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Inventary</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center" onClick={() => navigate("/accountsdashboard")}>
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={Account} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Account</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center">
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={Report} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Report</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center">
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={Laboratory} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Laboratory</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center" onClick={() => navigate("/red-list")}>
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={Radiology} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Radiology</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center">
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center ">
                    <img src={Insurance} alt="#" />
                  </div>
                  <div className="dash-content dash-count flex-grow-1">
                    <h4>Insureance</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center">
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={Blood_Bank} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Blood Bank</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center" onClick={() => navigate("/sms-list")}>
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={SMS} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>SMS</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center">
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={Rate_List} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Rate List</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center">
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={User} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>User</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-2 text-center" onClick={() => navigate("/settingpage")}>
                <div className="dash-widget d-flex flex-column justify-content-center align-items-center">
                  <div className="dash-boxs comman-flex-center">
                    <img src={Setting} alt="#" />
                  </div>
                  <div className="dash-content dash-count">
                    <h4>Setting</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Admin_Dashboard;
