import React, { useState,useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import toast from 'react-hot-toast';
import Select from "react-select";


const AddHospital = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [branchName, setBranchName] = useState("");
  const [status, setStatus] = useState("1");
  const [address, setAddress] = useState("");
  const [userData, setUserData] = useState(null);

  const [city, setCity] = useState('');
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);

  const SubmitForm = async (e) => {
    e.preventDefault();

    if (
      !password ||
      !email ||
      !branchName ||
      !address ||
      !city ||
      !country ||
      !status ||
      !state
    ) {
      toast.error("Please fill out all required fields.");
      return;
    }

    let payload = {
      email: email,
      password: password,
      branch_name: branchName,
      address: address,
      city: city,
      state: state.value,
      country: country,
      is_main_branch:userData.role == 9 ? 1 : 0,
        main_branch_id:userData.hospitalid,
      status: status,
    };

    try {
      const { data } = await AuthApi.post(Api.hospitalAdd, payload);
      if (data) {
        toast.success("Success");
        userData.role == 9 ? navigate("/hospitallist") : navigate(`/allsubbranches/${data.hospital}`);
      }
    } catch (error) {
      console.error("Error occurred while submitting form:", error);
      // Handle error - display error message or perform appropriate actions
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem('userdata');
    let data = JSON.parse(udata);

    setUserData(data.data);
    
  }, []);
  console.log(userData)

  const stateOptions = [
    { value: "", label: "Select State" },
    { value: "Andaman and Nicobar Islands", label: "Andaman and Nicobar Islands" },
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Dadra and Nagar Haveli and Daman and Diu", label: "Dadra and Nagar Haveli and Daman and Diu" },
    { value: "Delhi", label: "Delhi (National Capital Territory of Delhi)" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Ladakh", label: "Ladakh" },
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" }
  ];

  const stateoptions = stateOptions.map((e) => ({
    value: e.value,
    label: e.label,
  }));

  const statehandleChange = (selectedOption) => {
    setState(selectedOption);
  };


  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Hospital </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Add Hospital</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={SubmitForm}>
                    <div className="row">
                      {/* Form fields */}
                      {/* First Name */}
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>
                            Email Id <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>
                            Password <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>
                            Branch Name <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={branchName}
                            onChange={(e) => setBranchName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group select-gender">
                          <label className="gen-label">
                            Status?<span className="login-danger">*</span>
                          </label>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name=""
                                className="form-check-input"
                                value="1"
                                checked={status === "1"}
                                onChange={(e) => setStatus(e.target.value)}
                              />
                              Active
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name=""
                                className="form-check-input"
                                value="0"
                                checked={status === "0"}
                                onChange={(e) => setStatus(e.target.value)}
                              />
                              InActive
                            </label>
                          </div>
                        </div>
                      </div>
                      
                      {/* Address */}
                      <div className="col-12 col-sm-12">
                        <div className="form-group local-forms">
                          <label>
                            Address <span className="login-danger">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            rows={3}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* Country */}
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Country <span className="login-danger">*</span>
                          </label>
                          <select
                            className="form-select"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            placeholder="Select Country"
                          >
                            <option value="">Select Country</option>
                            <option value="India">India</option>
                          </select>
                        </div>
                      </div>
                      {/* State */}
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            State/Province{" "}
                            <span className="login-danger">*</span>
                          </label>
                          <Select
                            className="form-control-alternative"
                            options={stateoptions}
                            value={state}
                            onChange={statehandleChange}
                            placeholder="Search And Select"
                            isSearchable
                            isClearable
                          />
                        </div>
                      </div>
                       {/* City */}
                       <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            City <span className="login-danger">*</span>
                          </label>
                          {/* <select
                            className="form-select"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            placeholder="Select City"
                          >
                            <option value="">Select City</option>
                            <option value="1">test</option>
                          </select> */}
                          <input
                            className="form-control"
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </div>
                      </div>

                      {/* Form submission buttons */}
                      <div className="col-12">
                        <div className="doctor-submit text-end">
                          <button
                            type="submit"
                            className="btn btn-primary submit-form me-2"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              navigate("/hospitallist");
                            }}
                            className="btn btn-primary cancel-form"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHospital;
