// import React, { useState ,useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
// import Api from "../../helper/api";
// import AuthApi from "../../helper/authApi";
// import { useNavigate, useParams } from "react-router-dom";
// import toast from "react-hot-toast";
import { DatePicker } from "antd";
// import Select from "react-select";
// import Modal from "react-bootstrap/Modal";
// import { loadingService } from "../../helper/observer";
import { imguser } from "../imagepath";

const DischargeInfo = () => {
  // const { id } = useParams();

  // const navigate = useNavigate();
  // const [userData, setUserData] = useState(null);
  // const [PatientData, setPatientData] = useState([]);

  // useEffect(() => {
  //   // set default styling
  //   let udata = localStorage.getItem("userdata");
  //   let data = JSON.parse(udata);

  //   setUserData(data.data);
  // }, []);

  // const getIpdInfo = async () => {
  //   loadingService.next(true);

  //   const { data, message, status } = await AuthApi.get(
  //     `${Api.ipdformfirstinfo}/${id}`
  //   );
  //   loadingService.next(false);

  //   if (!data) {
  //     toast.error(message);
  //     if (status === 401) navigate("/login");
  //     return;
  //   }
  //   setPatientData(data.data[0]);
  // };

  // useEffect(() => {
  //   getIpdInfo();
  // }, []);

  return (
    <>
      <div>
        <Header />
        <Sidebar
          id="menu-item2"
          id1="menu-items2"
          activeClassName="add-patient"
        />
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dicharge Form </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Discharge</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-box profile-header">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img className="avatar" src={imguser} alt="#" />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {/* {PatientData.patient_name} */}
                              Satyam Fuse
                            </h3>
                            {/* <small className="text-muted">Gynecologist</small> */}
                            <div className="staff-id">
                              Ipd ID : 
                              {/* Ipd ID : {PatientData.ipd_id} */}
                            </div>
                            <div className="staff-id">
                              Patient ID : 
                              {/* Patient ID : {PatientData.patientId} */}
                            </div>
                            <div className="staff-msg">
                              <Link to="/chat" className="btn"></Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <span className="title">Age:</span>
                              <span className="text"></span>
                              {/* <span className="text">{PatientData.age}</span> */}
                            </li>
                            <li>
                              <span className="title">Gender:</span>
                              <span className="text"></span>
                              {/* <span className="text">{PatientData.gender}</span> */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br></br>

            <div className="row">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div className="col-12">
                      <div className="form-heading">
                        <h6>Discharge Date & Time</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            {" "}
                            <span className="login-danger"></span>
                          </label>
                          <DatePicker
                            className="form-control datetimepicker"
                            format="DD-MMM-YYYY"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>
                            <span className="login-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              className="form-control"
                              id="outlined-controlled"
                              type="time"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          IPD NO <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          // value={PatientData.ipd_id}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Discharge Summary Title{" "}
                          <span className="login-danger">*</span>
                        </label>
                        <select className="form-select">
                          <option value="">Select</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Incharge Doctor 1{" "}
                          <span className="login-danger">*</span>
                        </label>
                        <select className="form-select">
                          <option value="">Select</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-12">
                      <div className="form-group local-forms">
                        <label>
                          Incharge Doctor 2{" "}
                          <span className="login-danger"></span>
                        </label>
                        <select className="form-select">
                          <option value="">Select</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="">
                        <div className="">
                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Medical History{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="">
                        <div className="">
                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Case History/Complaints(Sync From Doc Note){" "}
                                <span className="login-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="">
                        <div className="">
                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Provisional Diagnosis{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="">
                        <div className="">
                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Final Diagnosis{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="">
                        <div className="">
                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Investigations/(Sync Lab Orders,Sync From Doc
                                Note) <span className="login-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="">
                        <div className="">
                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Course in Hospital{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="">
                        <div className="">
                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Treatment Given/(Sync From Doc Note){" "}
                                <span className="login-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="">
                        <div className="">
                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Lab Report/Test Result(Sync From Laboratory){" "}
                                <span className="login-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="">
                        <div className="">
                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Condition On Discharge{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-heading">
                        <h6>Follow-Up Date & Time</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group local-forms">
                          <label>
                            {" "}
                            <span className="login-danger"></span>
                          </label>
                          <DatePicker
                            className="form-control datetimepicker"
                            format="DD-MMM-YYYY"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>
                            <span className="login-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              className="form-control"
                              id="outlined-controlled"
                              type="time"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="">
                        <div className="">
                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Follow-up Remark{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="">
                        <div className="">
                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Note/Remark{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div className="col-12">
                      <div className="form-heading">
                        <h4>On Discharge Medicine</h4>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="row d-flex">
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>Medicine Name </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-2">
                        <div className="form-group local-forms">
                          <label>Dosage </label>
                          <select className="form-select">
                            <option value="">Select</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-2">
                        <div className="form-group local-forms">
                          <label>Days</label>
                          <select className="form-select">
                            <option value="">Select</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>Remarks</label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-2">
                        <div className="form-group local-forms">
                          <label>Strength</label>
                          <select className="form-select">
                            <option value="">Select</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3 d-inline-flex">
                        <div className="form-group local-forms me-2">
                          <label>QT</label>
                          <input className="form-control" type="text" />
                        </div>
                        <div>
                          <button type="button" className="btn btn-primary ">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="col-md-12 pb-2">
                      <details open>
                        <summary className="summary-background">
                          OPD RECORD 1
                        </summary>
                        <div className="card">
                          <div className="card-body">
                            <div className="col-12">
                              <div className="form-heading">
                                <h4>OPD RECORD 1</h4>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>
                                  Operation Date{" "}
                                  <span className="login-danger"></span>
                                </label>
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format="DD-MMM-YYYY"
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>Surgeon Name</label>
                                <select className="form-select">
                                  <option value="">Select</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>Anesthesia Doctor</label>
                                <select className="form-select">
                                  <option value="">Select</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>Anesthesia Type</label>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>Operation Name</label>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="card">
                                <div className="card-body">
                                  <div className="col-12 col-sm-12">
                                    <div className="form-group local-forms">
                                      <label>
                                        Operative Notes{" "}
                                        <span className="login-danger">*</span>
                                      </label>
                                      <textarea
                                        className="form-control"
                                        rows={3}
                                        cols={30}
                                        defaultValue={""}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </details>
                    </div>
                    <hr></hr>
                    <div className="col-md-12 pb-2">
                      <details>
                        <summary className="summary-background">
                          OPD RECORD 2
                        </summary>
                        <div className="card">
                          <div className="card-body">
                            <div className="col-12">
                              <div className="form-heading">
                                <h4>OPD RECORD 2</h4>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>
                                  Operation Date{" "}
                                  <span className="login-danger"></span>
                                </label>
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format="DD-MMM-YYYY"
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>Surgeon Name</label>
                                <select className="form-select">
                                  <option value="">Select</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>Anesthesia Doctor</label>
                                <select className="form-select">
                                  <option value="">Select</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>Anesthesia Type</label>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>Operation Name</label>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="card">
                                <div className="card-body">
                                  <div className="col-12 col-sm-12">
                                    <div className="form-group local-forms">
                                      <label>
                                        Operative Notes{" "}
                                        <span className="login-danger">*</span>
                                      </label>
                                      <textarea
                                        className="form-control"
                                        rows={3}
                                        cols={30}
                                        defaultValue={""}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </details>
                    </div>
                    <hr></hr>
                    <div className="col-md-12 pb-2">
                      <details>
                        <summary className="summary-background">
                          OPD RECORD 3
                        </summary>
                        <div className="card">
                          <div className="card-body">
                            <div className="col-12">
                              <div className="form-heading">
                                <h4>OPD RECORD 3</h4>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>
                                  Operation Date{" "}
                                  <span className="login-danger"></span>
                                </label>
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format="DD-MMM-YYYY"
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>Surgeon Name</label>
                                <select className="form-select">
                                  <option value="">Select</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>Anesthesia Doctor</label>
                                <select className="form-select">
                                  <option value="">Select</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>Anesthesia Type</label>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group local-forms">
                                <label>Operation Name</label>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="card">
                                <div className="card-body">
                                  <div className="col-12 col-sm-12">
                                    <div className="form-group local-forms">
                                      <label>
                                        Operative Notes{" "}
                                        <span className="login-danger">*</span>
                                      </label>
                                      <textarea
                                        className="form-control"
                                        rows={3}
                                        cols={30}
                                        defaultValue={""}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </details>
                    </div>
                    <br></br>
                    <div className="card-body">
                      <div className="row d-flex">
                        <div className="col-12 col-md-6 col-xl-10">
                          <div className="form-group local-forms">
                            <label>Advice / Precautions </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-2 d-inline-flex">
                          <div>
                            <button type="button" className="btn btn-primary ">
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <h3 className="text-success">ASSISTANT SURGEON</h3>
                      <hr></hr>
                      <div className="col-sm-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="col-12 col-sm-12">
                              <div className="form-group local-forms">
                                <label>
                                Assistant Surgeon{" "}
                                  <span className="login-danger">*</span>
                                </label>
                                <textarea
                                  className="form-control"
                                  rows={3}
                                  cols={30}
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {/* <h6 className="card-title">Bottom line tabs</h6> */}

                    <h3 className="text-success">EX-FINDING :</h3>
                    <hr></hr>
                    <div className="form-group row">
                      <label className="col-form-label col-lg-2">Temp</label>
                      <div className="col-md-3">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span className="input-group-text" id="basic-addon2">
                            F
                          </span>
                        </div>
                      </div>

                      <label className="col-form-label col-lg-2">Pulse</label>
                      <div className="col-md-3">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span className="input-group-text" id="basic-addon2">
                            Min
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-lg-2">Bp</label>
                      <div className="col-md-3">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span className="input-group-text" id="basic-addon2">
                            mmOfHg
                          </span>
                        </div>
                      </div>

                      <label className="col-form-label col-lg-2">Weight</label>
                      <div className="col-md-3">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span className="input-group-text" id="basic-addon2">
                            Kg
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-form-label col-lg-2">Height</label>
                      <div className="col-md-3">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span className="input-group-text" id="basic-addon2">
                            Cm
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Tongue <span className="login-danger"></span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Throat <span className="login-danger"></span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Cyanosis <span className="login-danger"></span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Built <span className="login-danger"></span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Skin <span className="login-danger"></span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Nails <span className="login-danger"></span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Anaemla <span className="login-danger"></span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Stool <span className="login-danger"></span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            Urine <span className="login-danger"></span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                    </div>
                    <h3 className="text-success">SYS-FINDING :</h3>
                    <hr></hr>
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            CNS <span className="login-danger"></span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            RS <span className="login-danger"></span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            PA <span className="login-danger"></span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group local-forms">
                          <label>
                            CVS <span className="login-danger"></span>
                          </label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                    </div>
                    <h3 className="text-success">LOCAL EXAMINATION :</h3>
                    <hr></hr>
                    <div className="col-sm-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                LOCAL EXAMINATION{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DischargeInfo;
