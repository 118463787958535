/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import { loadingService } from "../../helper/observer";

const PrescriptionList = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [prescriptionList, setPrescriptionList] = useState([]);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);

  const handleDelete = async () => {
    // Implement your delete logic here using deleteId

    loadingService.next(true);

    const { data, status } = await AuthApi.delete(`${Api.deleteTest}/${show}`);
    loadingService.next(false);

    if (status != 200) {
      toast.success("Deleted Successfully");
      setShow(false);
      getPrescriptionList(userData);
      if (status == 401) navigate("/login");
      return;
    }
  };

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      render: (text, record) => {
        // Convert the date of birth to a Date object
        const dob = new Date(record.created_at);

        // Define an array to map month numbers to their respective names
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // Format the date in the desired format
        const formattedDate = `${dob.getDate()}-${
          monthNames[dob.getMonth()]
        }-${dob.getFullYear()}`;

        // Return the formatted date
        return formattedDate;
      },
    },
    // {
    //   title: "Appointment Id",
    //   dataIndex: "appointment_id",
    //   sorter: (a, b) => a.appointment_id.length - b.appointment_id.length,
    // },
    {
      title: "Bp",
      dataIndex: "bp",
      sorter: (a, b) => a.bp.length - b.bp.length,
    },
    {
      title: "Sugar",
      dataIndex: "sugar",
      sorter: (a, b) => a.sugar.length - b.sugar.length,
    },
    {
      title: "Weight",
      dataIndex: "weight",
      sorter: (a, b) => a.weight.length - b.weight.length,
    },
    {
      title: "Oxygen",
      dataIndex: "oxygen",
      sorter: (a, b) => a.oxygen.length - b.oxygen.length,
    },
    {
      title: "",
      dataIndex: null, // You can keep this null if it's just for the action
      render: (text, record) => {
        return (
          <>
            <a
              className="badge bg-primary-light"
              onClick={(e) => {
                sendPrescription(record.appointment_id); // Only trigger when amount is empty or null
              }}
            >
              Send Prescription
            </a>
          </>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "id",
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <Link className="dropdown-item" to={`/infoappointment/${record.id}`}>
                      <i className="far fa-file me-2" />
                      Info
                    </Link> */}
                <Link
                  className="dropdown-item"
                  to={`/editprescription/${record.appointment_id}`}
                >
                  <i className="far fa-edit me-2" />
                  Edit Prescription
                </Link>
                {/* <Link
                  className="dropdown-item"
                  onClick={() => setShow(record.id)}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link> */}
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  const getPrescriptionList = async (user) => {
    let url;

    url = `${Api.patientprescriptionList}/${id}`;

    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setPrescriptionList(data);
  };

  const sendPrescription = async (id) => {
    let url;

    url = `${Api.sendprescriptionlink}/${id}`;

    loadingService.next(true);
    const response = await AuthApi.get(url);
    loadingService.next(false);

    getPrescriptionList();
    if (response.data.status_code == 404) {
      toast.error(response.data.detail);
    } else if (response.data.response.status == "success") {
      toast.success("Send Successfully");
    }
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    setUserData(data.data);
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getPrescriptionList(userData);
    }
  }, [userData]);

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Prescription </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">Prescription List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Prescription List</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2"></Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: prescriptionList.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={prescriptionList}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure want to delete this ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imagesend} alt="#" width={50} height={46} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white me-2"
            variant="secondary"
            onClick={() => setShow(false)}
          >
            Close
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="btn btn-danger"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PrescriptionList;
