/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { onShowSizeChange, itemRender } from "../Pagination";
import {
  blogimg10,
  imagesend,
  pdficon,
  pdficon3,
  pdficon4,
  plusicon,
  refreshicon,
  searchnormal,
  blogimg12,
  blogimg2,
  blogimg4,
  blogimg6,
  blogimg8,
} from "../imagepath";
import { Link } from "react-router-dom";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import Select from "react-select";
import { loadingService } from "../../helper/observer";
import html2pdf from "html2pdf.js";

const BillingList = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [AppointmentList, setAppointmentList] = useState([]);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [paymentmethod, setPaymentmethod] = useState(false);
  const [paymentoption, setPaymentoption] = useState([
    { value: "", label: "No Charge" },
    { value: "1", label: "Cash" },
    { value: "2", label: "Credit Card/Debit Card" },
    { value: "3", label: "Net Banking" },
    { value: "4", label: "UPI(Online)" },
  ]);
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("NA");
  const [patientName, setPatientName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const handleActionClick = () => {
    setShowDropdown(!showDropdown); // Toggle dropdown visibility
  };

  const outsidePuneAppointments = AppointmentList.filter(
    (appointment) => appointment.outside_pune === "Yes"
  );

  const withinPuneAppointments = AppointmentList.filter(
    (appointment) => appointment.outside_pune === "No"
  );

  const sortedAppointments = [
    ...outsidePuneAppointments,
    ...withinPuneAppointments,
  ];

  const columns = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Reg Date",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
      render: (text, record) => {
        // Convert the date of birth to a Date object
        const dob = new Date(record.created_at);

        // Define an array to map month numbers to their respective names
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        // Format the date in the desired format
        const formattedDate = `${dob.getDate()}-${
          monthNames[dob.getMonth()]
        }-${dob.getFullYear()}`;

        // Return the formatted date
        return formattedDate;
      },
    },
    {
      title: "IPD Id",
      dataIndex: "ipd_id",
      sorter: (a, b) => a.ipd_id.length - b.ipd_id.length,
    },
    {
      title: "Patient",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.length - b.patient_name.length,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      sorter: (a, b) => a.mobile_number.length - b.mobile_number.length,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => a.gender.length - b.gender.length,
    },

    {
      title: "Outside Pune",
      dataIndex: "outside_pune",
      sorter: (a, b) => a.outside_pune.length - b.outside_pune.length,
    },
    {
      title: "Ward",
      dataIndex: "ward",
      sorter: (a, b) => a.ward.length - b.ward.length,
    },
    {
      title: "Bed No",
      dataIndex: "bed_number",
      sorter: (a, b) => a.bed_number.length - b.bed_number.length,
    },
    {
      title: "Payment Status",
      dataIndex: "null",
      render: (text, record) => {
        return (
          <>
            {record.is_discharge == 0 ? (
              <a
                className="badge bg-danger-light"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the row click event
                  setShowPayment(record.id); // Only trigger when amount is empty or null
                }}
              >
                Take Payment
              </a>
            ) : (
              <a
                className="badge bg-success-light"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the row click event, but don't open payment modal
                }}
              >
                Payment Done
              </a>
            )}
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: null, // You can keep this null if it's just for the action
      render: (text, record) => {
        return (
          <>
            <a
              className="badge bg-danger-light me-2"
              onClick={(e) => {
                e.stopPropagation(); // Prevent the row click event
                navigate("/billingview");
              }}
            >
              View
            </a>
            <br></br>
            <br></br>
            <a className="badge bg-primary-light me-2">Print</a>
            <br></br>
            <br></br>
            <a className="badge bg-success-light">Send</a>
          </>
        );
      },
    },
  ];

  const handleSavePayment = async () => {
    // Generate the PDF
    const pdfBlob = await generatePDF();
    const pdfFile = new Blob([pdfBlob], { type: "application/pdf" });
    // Create a FormData object
    let formData = new FormData();

    // Append fields to form data
    formData.append("paymentmethod", paymentmethod.label);
    formData.append("amount", amount);
    formData.append("transactionId", transactionId);
    formData.append("file", pdfFile, "Payment Receipt.pdf");

    loadingService.next(true);

    try {
      const { data, message } = await AuthApi.post(
        `${Api.finalipdpayment}/${showPayment}`,
        formData, // Pass form data instead of JSON payload
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the correct content type for form data
          },
        }
      );
      loadingService.next(false);

      if (message === "Payment updated successfully") {
        toast.success("Payment updated successfully");
        setShowPayment(false);
        getAppointmentList(userData);
        if (status === 401) navigate("/login");
        return;
      }
    } catch (error) {
      loadingService.next(false);
      console.error("Error updating payment:", error);
    }
  };

  const generatePDF = async () => {
    try {
      const printButtons = document.querySelectorAll(".receipthide");
      printButtons.forEach((button) => {
        button.style.display = "block";
      });

      const content = document.getElementById("pdf-content");
      const pdfOptions = {
        margin: [5, 5, 5, 5],
        filename: "Payment Receipt.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1.5 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { mode: ["avoid-all", "css"] },
      };

      const pdfBlob = await html2pdf()
        .set(pdfOptions)
        .from(content)
        .outputPdf("blob", "Payment Receipt.pdf");

      printButtons.forEach((button) => {
        button.style.display = "none";
      });

      return pdfBlob;
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Error generating PDF!");
    }
  };

  const getAppointmentList = async (user) => {
    let url;
    if (user.role == 9) {
      url = Api.ipdbillinglist;
    } else if (user.role == 8 || user.role == 7) {
      url = `${Api.branchipdbillinglist}/${user.hospitalid}`;
    } else if (user.role == 6) {
      url = `${Api.branchipdbillinglist}/${user.branch_id}`;
    } else {
      url = `${Api.branchipdbillinglist}/${user.branch_id}`;
    }
    loadingService.next(true);

    const { data, message, status } = await AuthApi.get(url);
    loadingService.next(false);

    if (!data) {
      toast.error(message);
      if (status === 401) navigate("/login");
      return;
    }
    setAppointmentList(data);
  };

  useEffect(() => {
    // set default styling
    let udata = localStorage.getItem("userdata");
    let data = JSON.parse(udata);
    setUserData(data.data);
  }, []);

  useEffect(() => {
    // call getPatientList after userData is set
    if (userData) {
      getAppointmentList(userData);
    }
  }, [userData]);


  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Billing Records </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">
                    Billing Records List
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <ul className="nav nav-tabs nav-tabs-bottom">
                    <li className="nav-item">
                      <Link className="nav-link active" to="/billinglist">
                        Billing
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/billingexpenses">
                        Expenses
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link"
                        href="#!"
                        onClick={handleActionClick}
                      >
                        Action
                      </a>
                      {/* Dropdown Menu */}
                      {showDropdown && (
                        <ul className="dropdown-menu show">
                          <li>
                            <a className="dropdown-item" href="#!">
                              Advance Payment
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#!">
                              Estimate
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#!">
                              Doctor Charges
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#!">
                              Patient Outstanding
                            </a>
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Billing Records List</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search here"
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <Link to="#" className=" me-2">
                          <img src={pdficon} alt="#" />
                        </Link>
                        <Link to="#" className=" me-2"></Link>
                        <Link to="#" className=" me-2">
                          <img src={pdficon3} alt="#" />
                        </Link>
                        <Link to="#">
                          <img src={pdficon4} alt="#" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: sortedAppointments.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={sortedAppointments}
                      rowKey={(record) => record.id}
                      rowClassName={(record) =>
                        record.outside_pune == "Yes"
                          ? "green-row clickable-row"
                          : "clickable-row"
                      }
                      // onRow={(record, rowIndex) => {
                      //   return {
                      //     onClick: () => {
                      //       // Redirect to the info page when the row is clicked
                      //       navigate(`/addipdformsecond/${record.id}`);
                      //     },
                      //   };
                      // }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showPayment} onHide={() => setShowPayment(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Take A Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 col-md-12 col-xl-12">
            <div className="form-group local-forms">
              <label>
                Payment Method
                <span className="login-danger">*</span>
              </label>
              <Select
                defaultValue={paymentmethod}
                onChange={setPaymentmethod}
                options={paymentoption}
                menuPortalTarget={document.body}
                id="search-commodity"
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "none"
                      : "2px solid rgba(46, 55, 164, 0.1);",
                    boxShadow: state.isFocused ? "0 0 0 1px #2e37a4" : "none",
                    "&:hover": {
                      borderColor: state.isFocused
                        ? "none"
                        : "2px solid rgba(46, 55, 164, 0.1)",
                    },
                    borderRadius: "10px",
                    fontSize: "14px",
                    minHeight: "45px",
                  }),
                  dropdownIndicator: (base, state) => ({
                    ...base,
                    transform: state.selectProps.menuIsOpen
                      ? "rotate(-180deg)"
                      : "rotate(0)",
                    transition: "250ms",
                    width: "35px",
                    height: "35px",
                  }),
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-xl-12">
            <div className="form-group local-forms">
              <label>
                Amount
                <span className="login-danger">*</span>
              </label>
              <input
                className="form-control"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-xl-12">
            <div className="form-group local-forms">
              <label>
                Transaction Id
                <span className="login-danger"></span>
              </label>
              <input
                className="form-control"
                type="text"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                placeholder="Put Your Transaction Id Here"
              />
            </div>
          </div>
          <div className="receipthide" id="pdf-content">
            {/* Header Section */}
            <div className="d-flex justify-content-center mb-4">
              <div className="text-center">
                <h2 className="mb-0">Invoice Receipt</h2>
              </div>
            </div>
            <hr></hr>
            <div className="d-flex justify-content-center mb-4">
              <div className="text-center">
                <h2 className="mb-0">
                  SABALE&apos;S SUPER SPECIALITY HOSPITAL
                </h2>
                <p className="mb-0">
                  Sai Venkata Tread Center, Pune-Nashik Highway, Bhosari, Pune
                  411039
                </p>
                <p className="mb-0">
                  Mobile: 9011053954/9881255159 | Email:
                  sabalehospital2020@gmail.com
                </p>
              </div>
            </div>
            <hr></hr>
            {/* Payment Receipt Details */}
            <div className="col-12">
              <div className="form-group">
                <label>
                  <strong>Patient Name:</strong>
                </label>
                <p>{patientName}</p> {/* Display payment method value */}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>
                  <strong>Payment Method:</strong>
                </label>
                <p>{paymentmethod.label ? paymentmethod.label : ""}</p>{" "}
                {/* Display payment method value */}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>
                  <strong>Amount:</strong>
                </label>
                <p>₹ {amount}</p> {/* Display amount value */}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>
                  <strong>Transaction ID:</strong>
                </label>
                <p>{transactionId}</p> {/* Display transaction ID value */}
              </div>
            </div>
            <hr></hr>
            {/* Footer Section */}
            <div className="d-flex justify-content-center print-hide mt-4">
              <div className="text-center">
                <p className="mb-0">
                  Mobile: 9011053954/9881255159 | Email:
                  sabalehospital2020@gmail.com
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowPayment(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={handleSavePayment}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BillingList;
